export const homePageData = 'homePageData';
export const riskControlPageData = 'riskControlPageData';
export const limit = 10;
export const downloadLink = 'http://localhost:8080/static/test_data_table/template.xlsx';
export const RiskControlDropdown = 'riskControlDropdown';
export const Replace = 'Replace';
export const Reject = 'Reject';
export const RiskControlHeaderPage = 'RiskControlHeaderPage';
export const PleaseSelect = 'Select';

export const breadcrumbData = [
    { isEnabled: false, name: 'Dashboard', path: '/dashboard' },
    { isEnabled: false, name: 'Create New Session' },
];

export const active = 'active';
export const defaultStepper = 'default';
export const complete = 'complete';

export enum viewResultsHeader {
    initialized = 'example=test_results_initialized',
    processed = 'example=test_results_processed',
    historical = 'example=test_results_historical',
}

export const notifications = {
    Approved: 'Reviewer approved successfully',
    Reject: 'Reviewer rejected successfully',
    Submitted: 'Analyst submitted successfully',
    controlAdded: 'The new control has been successfully added',
    controlEdited: 'The control has been successfully edited',
};

export const triggerStatusByRole = {
    Analyst: {
        'Action Required': 'Action Required',
        'Submitted': 'Submitted',
    },
    Reviewer: {
        'Action Required': 'Action Required',
        'Approved': 'Approved',
        'Reject': 'Reject',
    },
};
