import { NexusBreadcrumb, NexusBreadcrumbItem } from '@nexus/react';

import React from 'react';

interface Props {
    separator: string;
    data: {
        isEnabled: boolean;
        name: string;
        path?: string;
    }[];
}

const BreadcrumbComponent: React.FC<Props> = ({ separator, data }) => {
    return (
        <div className='nexus-row nexus-pb-2'>
            <NexusBreadcrumb separator={separator}>
                {data.map((breadcrumbItem: { name: string; path?: string; isEnabled: boolean }, idx) => {
                    return (
                        <NexusBreadcrumbItem
                            key={idx}
                            data-testid={breadcrumbItem.name}
                            disabled={breadcrumbItem.isEnabled}
                        >
                            <a href={breadcrumbItem.path}>{breadcrumbItem.name}</a>
                        </NexusBreadcrumbItem>
                    );
                })}
            </NexusBreadcrumb>
        </div>
    );
};

export default BreadcrumbComponent;
