import { NexusLabel, NexusModal, NexusModalBody } from '@nexus/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { truncate } from 'utils';
import ButtonComponent from '../nds/button/button';
import './reasonModal.scss';

interface props {
    actionButton1: string;
    actionButton2: string;
    aditionalBodyContent?: React.ReactNode;
    date?: string;
    description: string;
    handleReasonModalAction: (submit: boolean, reason?: string) => void;
    maxCharacters?: number;
    openReasonModal: boolean;
    reasonName: string;
    textareaRows?: number;
    title: string;
}

const ReasonModal: React.FC<props> = ({
    actionButton1,
    actionButton2,
    aditionalBodyContent,
    date,
    description,
    handleReasonModalAction,
    maxCharacters = 300,
    openReasonModal,
    reasonName,
    textareaRows = 5,
    title,
}) => {
    const [t] = useTranslation('lang');
    const modalLabels = JSON.parse(JSON.stringify(t('modal.labels')));
    const maxCharactersLegend = JSON.parse(JSON.stringify(t('warnings.maxCharactersLegend')));

    const [reason, setReason] = useState('');

    return (
        <NexusModal show={openReasonModal} size={'md'} data-testid='reason-modal'>
            <NexusModalBody>
                <div className='reason-modal-container' data-testid='reason-modal-container'>
                    <div className='reason-modal-header'>
                        <div className='reason-modal-title'>{title}</div>
                        {date && (
                            <div className='data' data-testid='aditional-data-date'>
                                <strong>{modalLabels.due}</strong> {date}
                            </div>
                        )}
                    </div>
                    <br />
                    <div className='reason-modal-description-container'>
                        {aditionalBodyContent && <div className='nexus-h5'>{modalLabels.description}</div>}
                        <div className='reason-moda-description'>{truncate(description, 400)}</div>
                    </div>
                    {aditionalBodyContent && (
                        <>
                            <br />
                            {aditionalBodyContent}
                        </>
                    )}
                    <br />
                    <div className='reason-modal-textarea-container'>
                        <NexusLabel className='reason-name-label'>{reasonName}</NexusLabel>
                        <textarea
                            data-testid='reason-modal-textarea'
                            onChange={(e) => setReason(e.target.value)}
                            maxLength={maxCharacters}
                            rows={textareaRows}
                            contentEditable={false}
                            required
                        />
                    </div>
                    <div className='textarea-max-characters-container'>
                        <div>{`${maxCharacters} ${maxCharactersLegend}`}</div>
                        <div>{`${reason.length}/${maxCharacters}`}</div>
                    </div>
                    <div className='reason-modal-actions'>
                        <ButtonComponent
                            label={actionButton1}
                            type={['nexus-btn']}
                            click={() => handleReasonModalAction(false)}
                        />
                        <ButtonComponent
                            label={actionButton2}
                            type={['nexus-btn nexus-btn-primary']}
                            click={() => handleReasonModalAction(true, reason)}
                            disabled={!reason}
                        />
                    </div>
                </div>
            </NexusModalBody>
        </NexusModal>
    );
};

export default ReasonModal;
