import React from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { NexusIcon } from '@nexus/react';
import Ic_keyboard_backspace_24px from '@nexus/core/dist/assets/icons/hardware/ic_keyboard_backspace_24px.svg';
import './backNavigation.scss';

interface props {
    goToLink?: string;
    goToStep?: string;
    handlePreviousStep?: (arg: string) => void;
}

const BackNavigation: React.FC<props> = ({ goToLink, goToStep, handlePreviousStep }) => {
    const [t] = useTranslation('lang');
    const backLabel = JSON.parse(JSON.stringify(t('navigation.labels.back')));
    const navigate = useNavigate();

    const handleBackNavigation = () => {
        if (goToLink) navigate(goToLink);
        if (goToStep && handlePreviousStep) handlePreviousStep(goToStep);
    };

    return (
        <div data-testid='back-navigation-container' className='back-navigation-container'>
            <span data-testid='back-navigation-span' className='back-navigation-span' onClick={handleBackNavigation}>
                <NexusIcon className='back-arrow' src={Ic_keyboard_backspace_24px} />
                <p className='nexus-body back-legend'>{backLabel}</p>
            </span>
        </div>
    );
};

export default BackNavigation;
