import { useNavigate } from 'react-router';

import { useAppDispatch } from 'store';
import { resetTestData } from '../redux/assessmentUnits/assessmentUnitActions';
import ButtonComponent from 'app/components/nds/button/button';
import './resetTestData.scss';

const ResetTestData = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const reset = async () => {
        const resp = await dispatch(resetTestData());
        console.log('Reset response:', resp);
        navigate('/dashboard');
    };

    return (
        <div className='reset-test-data'>
            <ButtonComponent label='Please confirm reset' type={['nexus-btn-primary']} click={reset} />
        </div>
    );
};

export default ResetTestData;
