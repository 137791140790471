import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import ButtonComponent from 'app/components/nds/button/button';
import './triggerRowDetails.scss';
import ReasonModal from 'app/components/reasonModal/reasonModal';
import { useAppDispatch } from 'store';
import { dismissTrigger } from '../../redux/triggers/triggersActions';

const TriggerRowDetails: React.FC<{ row: any }> = ({ row: trigger }) => {
    const [t] = useTranslation('lang');
    const buttons = JSON.parse(JSON.stringify(t('buttons')));
    const triggerLabels = JSON.parse(JSON.stringify(t('trigger.labels')));
    const triggerStatus = JSON.parse(JSON.stringify(t('trigger.status')));
    const [openDismissModal, setOpenDismissModal] = useState(false);

    const navigate = useNavigate();
    const handleNav = () => {
        navigate(`${trigger.triggerId}/address-trigger`);
    };

    const dispatch = useAppDispatch();
    const handleDismissModalAction = (submit: boolean, reason?: string) => {
        let rationale = '';
        if (reason) rationale = reason;
        submit &&
            dispatch(
                dismissTrigger({
                    triggerId: trigger.triggerId,
                    triggerRationale: rationale,
                    triggerStatus: triggerStatus.dismissed,
                }),
            );
        setOpenDismissModal(false);
    };

    const fieldHandler = (text: string, label: string) => {
        return text || `No ${label} found.`;
    };

    const aditionalContentForModal = (
        <>
            <div className='source'>
                <div className='nexus-h5'>{triggerLabels.source}</div>
                <div>{fieldHandler(trigger.triggerSource, triggerLabels.source)}</div>
            </div>
            <br />
            <div className='nexus-h5'>{triggerLabels.dismissTrigger}</div>
        </>
    );

    return (
        <div className='nexus-flex-row trigger-row-details' data-testid='Trigger-Row-Details'>
            <div className='content'>
                <div className='obligation'>
                    <p className='header'>{triggerLabels.triggerName}</p>
                    <p>{trigger.triggerName}</p>
                </div>
                <div className='description-preview'>
                    <p className='header'>{triggerLabels.triggerDescription}</p>
                    <p className='description'>{trigger.triggerDescriptionComplete}</p>
                </div>
            </div>
            <div className='actions'>
                <ButtonComponent
                    label={triggerLabels.dismissTrigger}
                    type={['nexus-btn']}
                    click={() => setOpenDismissModal(true)}
                />
                <ButtonComponent label={triggerLabels.addressTrigger} type={['nexus-btn']} click={handleNav} />
            </div>
            {openDismissModal && (
                <ReasonModal
                    actionButton1={buttons.cancel}
                    actionButton2={buttons.dismiss}
                    aditionalBodyContent={aditionalContentForModal}
                    date={trigger.triggerDueDate}
                    description={trigger.triggerSubjects[0].subjectDescription}
                    handleReasonModalAction={handleDismissModalAction}
                    openReasonModal={openDismissModal}
                    reasonName={triggerLabels.triggerReason}
                    title={trigger.triggerName}
                />
            )}
        </div>
    );
};

export default TriggerRowDetails;
