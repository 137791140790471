import { post } from '../utils';
import { REACT_APP_CLIENT_ID, REACT_APP_CLIENT_SECRET, REACT_APP_OAUTH_TOKEN_CONTEXT } from '../config/settings';

export const getAccessToken = () => {
    /* eslint-disable camelcase */
    const data: { [id: string]: string } = {
        client_id: `${REACT_APP_CLIENT_ID}`,
        client_secret: `${REACT_APP_CLIENT_SECRET}`,
        grant_type: 'client_credentials',
        scope: 'https://outlook.office.com',
    };

    return post({
        data: Object.keys(data)
            .map((key: string) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
            .join('&'),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        url: REACT_APP_OAUTH_TOKEN_CONTEXT,
    }).then((response: any) => response.data.access_token);
};
