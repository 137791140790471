import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchToken, selectAuthToken, setInitialState } from '../authSlice';
import { RootState } from '../../store/index';

interface InputProps {
    children: React.ReactNode;
    store?: RootState;
    doLogout?: boolean;
}

const TokenAuth = ({ children, doLogout }: InputProps) => {
    const [showChildren, setShowChildren] = useState(false);
    const dispatch = useDispatch(); //store ? store.dispatch : useDispatch();
    // Check store (redux) for the token, if not available the auth request will occur.
    // Could persist the token so refreshes don't force a refetch but that introduces potential
    // security risks
    const token = useSelector(selectAuthToken);
    const accessToken = useRef(token);
    // set token when it's available
    accessToken.current = token;

    useEffect(() => {
        // check for expired token
        if (accessToken.current) {
            setShowChildren(true);
        } else {
            dispatch(fetchToken());
        }
    }, [accessToken.current]);

    useEffect(() => {
        if (doLogout) {
            accessToken.current = null;
            dispatch(setInitialState());
        }
    }, [doLogout]);

    return <>{showChildren && children}</>;
};

export default TokenAuth;
