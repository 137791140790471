import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { NexusIcon } from '@nexus/react';
import ConentIcFileDownload24px from '@nexus/core/dist/assets/icons/file/ic_file_download_24px.svg';

import action_required from '../../../../action_required.svg';
import list_alt from '../../../../list_alt.svg';
import approved from '../../../../approved.svg';
import { IAssessmentUnitTrigger } from 'constants/commonExportedInterfaces';
import TriggerRowDetails from './triggerRowDetails/triggerRowDetails';
import './triggersAndControls.scss';
import ExpandableTable from '../../../components/expandableTable/expandableTable';
import { useAppDispatch, useAppSelector } from 'store';
import { getTriggersData } from '../redux/triggers/triggersActions';
import LoaderComponent from 'app/components/nds/loader/loader';
import { getAssessmentUnitById } from '../redux/assessmentUnits/assessmentUnitActions';
import AssessmentUnitsBreadcrumb from '../assessmentUnitsBreadcrumb/assessmentUnitsBreadcrumb';
import EmptyTable from 'app/components/emptyTable/emptyTable';
import DescriptionTooltip from 'app/components/descriptionTooltip/descriptionTooltip';
import Filter from 'app/components/filter/filter';
import StatusSummaryCard from 'app/components/statusSummaryCard/statusSummaryCard';

const columnTitles = ['Trigger Name', 'Trigger Description', 'Source', 'Status', 'Creation Date', 'Download'];
const fields = ['triggerName', 'triggerDescription', 'triggerSource', 'triggerStatus', 'triggerDueDate', 'download'];

const chipPalette = {
    'Action Required': '#ED6500',
    'Approved': '#59D159',
    'In Progress': '#FFD240',
    'In Review': '#88CDFA',
    'Reject': '#BDBFC1',
    // Add other valid status values . . .
};

const AssessmentUnitTriggers: React.FC = () => {
    const [t] = useTranslation('lang');
    const triggerStatus = JSON.parse(JSON.stringify(t('trigger.status')));
    const statusColor = JSON.parse(JSON.stringify(t('trigger.statusColor')));

    const params = useParams();
    const dispatch = useAppDispatch();

    const triggers: IAssessmentUnitTrigger[] = useAppSelector((state) => state.triggersData.triggersData);
    const refreshTrigger = useAppSelector((state) => state.triggersData.refreshTrigger);

    const loadingTriggers = useAppSelector((state) => state.triggersData.loading);

    const { currentAssessmentUnit, loading: loadingAssessmentUnit } = useAppSelector(
        (state) => state.assessmentUnitData,
    );

    useEffect(() => {
        dispatch(getTriggersData(params.id as string));
        dispatch(getAssessmentUnitById(params.id as string));
    }, [refreshTrigger]);

    const rows =
        triggers &&
        triggers.length > 0 &&
        triggers.map((t) => {
            return {
                ...t,
                download: (
                    <NexusIcon
                        src={ConentIcFileDownload24px}
                        className='download-icon'
                        onClick={() => console.log('Download:', t)}
                    />
                ),
                triggerDescription: <DescriptionTooltip description={t.triggerDescription} placement='top' size={30} />,
                triggerDescriptionComplete: t.triggerDescription,
                triggerDueDate: new Date(t.triggerDate).toLocaleDateString(),
            };
        });

    let columnData = rows
        ? fields.map((field: string, idx) => {
              return {
                  field: field,
                  headerClass: ['download'].includes(field) ? 'cls-col-width' : '',
                  isSortable: !['triggerDueDate', 'download'].includes(field),
                  label: columnTitles[idx],
              };
          })
        : [];

    const mappedTriggerStatus = [
        {
            color: statusColor.actionRequired,
            icon: action_required,
            status: triggerStatus.actionRequired,
            statusCount:
                triggers && triggers.filter((trigger) => trigger.triggerStatus === triggerStatus.actionRequired).length,
        },
        {
            color: statusColor.inProgress,
            icon: list_alt,
            status: triggerStatus.inProgress,
            statusCount:
                triggers && triggers.filter((trigger) => trigger.triggerStatus === triggerStatus.inProgress).length,
        },
        {
            color: statusColor.approved,
            icon: approved,
            status: triggerStatus.approved,
            statusCount:
                triggers && triggers.filter((trigger) => trigger.triggerStatus === triggerStatus.approved).length,
        },
    ];

    if (!currentAssessmentUnit) return <LoaderComponent show={!currentAssessmentUnit} />;

    return (
        <>
            <div data-testid='assessment-Unit-Triggers' className='assessment-unit-triggers'>
                <section className='nexus-container assessment-unit-triggers-content'>
                    <AssessmentUnitsBreadcrumb auName={currentAssessmentUnit.auName} />
                    <div className='title-section'>
                        {!loadingAssessmentUnit && (
                            <h1 className='title' aria-label='Assessment Unit Triggers'>
                                {`${currentAssessmentUnit.auName} Triggers`}
                            </h1>
                        )}
                    </div>
                    <div className='trigger-status-summary-filter-section'>
                        <div className='trigger-status-summary-section'>
                            {mappedTriggerStatus.map((status) => (
                                <StatusSummaryCard {...status} />
                            ))}
                        </div>
                        <div className='filter-section'>
                            <Filter />
                        </div>
                    </div>
                    {loadingTriggers && <LoaderComponent show={loadingTriggers} />}
                    <div className='triggers-table-container' data-testid='Triggers-Table-Container'>
                        {rows && (
                            <ExpandableTable
                                data-testid='Custom-Expandable-Table'
                                data={rows}
                                fields={fields}
                                columnData={columnData}
                                pagination={true}
                                pageSize={10}
                                pageSizeLabel={'Show'}
                                TableCustomDetailsRow={TriggerRowDetails}
                                chipFields={['triggerStatus']}
                                chipPalette={chipPalette}
                                customBodyStyles={{ overflowY: 'scroll', maxHeight: '400px' }}
                            />
                        )}
                        {!loadingTriggers && triggers && !triggers.length && (
                            <EmptyTable columnTitles={columnTitles} message='No information found for this trigger.' />
                        )}
                    </div>
                </section>
            </div>
        </>
    );
};

export default AssessmentUnitTriggers;
