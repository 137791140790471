import { createSlice } from '@reduxjs/toolkit';

import { ITriggerRisk } from 'constants/commonExportedInterfaces';
import * as ACTIONS from './risksActions';

interface IRisksSliceReducer {
    risksData: Array<ITriggerRisk>;
    loading: boolean;
}

export const INITIAL_STATE_RISKS: IRisksSliceReducer = {
    risksData: [],
    loading: false,
};

type RisksState = Readonly<typeof INITIAL_STATE_RISKS>;

export const risksSliceReducer = createSlice({
    extraReducers: (builder) =>
        builder
            .addCase(ACTIONS.getRisksDataByAU.fulfilled, (state, action) => {
                state.risksData = action.payload?.data;
                state.loading = false;
            })
            .addCase(ACTIONS.getRisksDataByAU.pending, (state) => {
                state.loading = true;
            }),
    initialState: INITIAL_STATE_RISKS as RisksState,
    name: 'risksData',
    reducers: {},
});

export default risksSliceReducer.reducer;
