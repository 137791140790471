import { useTranslation } from 'react-i18next';

import ButtonComponent from 'app/components/nds/button/button';
import { ITriggerRisk } from 'constants/commonExportedInterfaces';
import { truncate } from 'utils';
import './riskSummaryCard.scss';
import CustomChip from 'app/components/customChip/customChip';

interface props {
    activeRisk: ITriggerRisk;
    showRiskStatus?: boolean;
    setOpenModal: (arg: boolean) => void;
}

const chipPalette = {
    Modified: '#E6B000',
};

const RiskSummaryCard: React.FC<props> = ({ activeRisk, showRiskStatus, setOpenModal }) => {
    const [t] = useTranslation('lang');
    const buttons = JSON.parse(JSON.stringify(t('buttons')));
    const riskLabels = JSON.parse(JSON.stringify(t('risk.labels')));

    function formatString(text: string): string {
        return text[0].toLocaleUpperCase() + text.slice(1, text.length).toLocaleLowerCase();
    }

    return (
        <div className='nexus-row risk-summary-card'>
            <div className='nexus-col-lg-6'>
                <div className='risk-prop-name'>
                    <span>{activeRisk.riskCode}</span>
                    {showRiskStatus && activeRisk.riskStatus && (
                        <CustomChip
                            chipPalette={chipPalette}
                            defaultColor={'#E6B000'}
                            field={formatString(activeRisk.riskStatus)}
                        />
                    )}
                </div>
                <div className='risk-prop-value'>{truncate(activeRisk.riskStatement, 120)}</div>
            </div>
            <div className='nexus-col-lg-3'>
                <div className='risk-prop-name'>{riskLabels.riskProcessName}</div>
                <div className='risk-prop-value'>{activeRisk.riskProcess.processName}</div>
            </div>
            <div className='nexus-col-lg-3 nexus-end-lg'>
                <ButtonComponent
                    label={buttons.viewFullRisk}
                    type={['nexus-btn nexus-btn-large']}
                    click={() => setOpenModal(true)}
                />
            </div>
        </div>
    );
};

export default RiskSummaryCard;

RiskSummaryCard.defaultProps = {
    showRiskStatus: false,
};
