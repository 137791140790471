import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NexusToast } from '@nexus/react';

import BackNavigation from 'app/components/backNavigation/backNavigation';
import ButtonComponent from 'app/components/nds/button/button';
import { IAssessmentUnit, IRiskControl, ITriggerRisk } from 'constants/commonExportedInterfaces';
import { useAppDispatch, useAppSelector } from 'store';
import './mapControl.scss';
import { columnDefs, fields, formatControlsSelected } from '../controlTableConsts';
import ControlForm from '../controlForm/controlForm';
import MapControlsTable from './mapControlsTable';
import { clearNotification, mapControlsToRisk } from '../../redux/addressTriggerActions';
import { truncate } from 'utils';

interface props {
    setShowEdit: () => void;
    risk: ITriggerRisk;
    className?: string;
}

const MapControl: React.FC<props> = ({ setShowEdit, risk }) => {
    const { riskId, riskCode } = risk;
    const [t] = useTranslation('lang');
    const buttons = JSON.parse(JSON.stringify(t('buttons')));
    const controlLabels = JSON.parse(JSON.stringify(t('control.labels')));

    const dispatch = useAppDispatch();

    const currentAssessmentUnit: IAssessmentUnit = useAppSelector(
        (state) => state.assessmentUnitData.currentAssessmentUnit,
    );
    const controls: IRiskControl[] = useAppSelector((state) => state.addressTriggerData.assessmentUnitControls);
    const notification: string = useAppSelector((state) => state.addressTriggerData.notification);

    const [controlsSelectedCount, setControlsSelectedCount] = useState(0); // Count of controls selected
    const [showForm, setShowForm] = useState(false); // Open modal to add/edit new risk
    const [isNewControl, setIsNewControl] = useState(false); // Is a new control or edit control
    const [selectedControls, setSelectedControls] = useState<any[]>([]); // Current selected controls

    // Format controls for table
    let rows = controls.map((c) => {
        return {
            ...c,
            controlDescription: truncate(c.controlDescription, 150),
            controlTaxonomyLevel1: c.controlTaxonomyLevel1.name,
            controlTaxonomyLevel2: c.controlTaxonomyLevel2.name,
            controlTaxonomyLevel3: c.controlTaxonomyLevel3.name,
            riskId: truncate(riskId, 6),
        };
    });

    useEffect(() => {
        if (!!notification.length) {
            setTimeout(() => {
                dispatch(clearNotification());
            }, 3000);
        }
    }, [notification]);

    // Handle Add or Edit form modal component
    const handleControlForm = (isNewControl: boolean) => {
        setShowForm(true);
        setIsNewControl(isNewControl);
    };

    const handleRowSelection = (rowIds: string[]) => {
        const selection = controls.filter((c) => rowIds.includes(c.controlId));
        setControlsSelectedCount(rowIds.length);
        setSelectedControls(selection);
    };

    const handleMapping = () => {
        // Validate control is not already mapped to the risk
        const mappedControlsIds = risk.controls.map((c) => c.controlId);
        const controlsToMap: IRiskControl[] = selectedControls.filter((c) => {
            return !mappedControlsIds.includes(c.controlId);
        });

        dispatch(mapControlsToRisk({ ...risk, controls: [...risk.controls, ...controlsToMap] }));
        setShowEdit();
    };

    return (
        <div className='edit-controls'>
            {!!notification.length && (
                <NexusToast variant='success' autoClose={3000} position='top' class='toast-notification'>
                    {notification}
                </NexusToast>
            )}
            {showForm &&
                (isNewControl ? (
                    <ControlForm
                        isNewControl={isNewControl}
                        handleCloseControlForm={() => setShowForm(false)}
                        showForm={showForm}
                    />
                ) : (
                    <ControlForm
                        isNewControl={isNewControl}
                        selectedControl={{ ...selectedControls[0], riskCode }}
                        handleCloseControlForm={() => setShowForm(false)}
                        showForm={showForm}
                    />
                ))}
            <div className='header'>
                <div onClick={setShowEdit} style={{ margin: 0, paddingTop: 0 }}>
                    <BackNavigation goToLink='' />
                </div>
                <div className='nexus-row'>
                    <div className='nexus-col-lg-6'>
                        <div className='nexus-h4'>
                            {currentAssessmentUnit.auName} - {controlLabels.controlsInventory}
                        </div>
                    </div>
                    <div className='nexus-col-lg-6 nexus-end-lg'>
                        <ButtonComponent
                            label={buttons.editControl}
                            type={['nexus-btn-primary nexus-btn-large']}
                            extraClass='add-new-control-btn'
                            disabled={controlsSelectedCount !== 1}
                            click={() => handleControlForm(false)}
                        />
                        <ButtonComponent
                            label={buttons.addNewControl}
                            type={['nexus-btn nexus-btn-large']}
                            extraClass='add-new-control-btn'
                            click={() => handleControlForm(true)}
                        />
                    </div>
                </div>
            </div>
            <MapControlsTable
                columnDefs={columnDefs.filter((c) => c.field !== 'controlStatus')}
                fields={fields.filter((f) => f !== 'controlStatus')}
                handleRowSelection={handleRowSelection}
                rows={rows}
            />
            <div className='map-to-risk'>
                <span>
                    {formatControlsSelected(controlsSelectedCount)} {controlLabels.controlsSelectedCount}
                    {controlLabels.controlsSelected}
                </span>
                <ButtonComponent
                    label={controlLabels.mapToRisk}
                    type={['nexus-btn-primary']}
                    disabled={!controlsSelectedCount}
                    click={handleMapping}
                />
            </div>
        </div>
    );
};

export default MapControl;
