import { NexusFormField, NexusIcon, NexusInput, NexusLabel, NexusSearch, NexusSelect } from '@nexus/react';
import ActionIcSearch24px from '@nexus/core/dist/assets/icons/action/ic_search_24px.svg';
import { useTranslation } from 'react-i18next';
import './filter.scss';

const Filter: React.FC = () => {
    const [t] = useTranslation('lang');
    const filterLabels = JSON.parse(JSON.stringify(t('filter.labels')));

    const options = [filterLabels.showAll, filterLabels.optionA, filterLabels.optionB];

    return (
        <div className='filter-actions'>
            <div className='filter'>
                <NexusFormField className='filter-form-field'>
                    <NexusLabel>{filterLabels.filter}</NexusLabel>
                    <NexusSelect value={filterLabels.showAll}>
                        {options.map((option) => (
                            <option key={option}>{option}</option>
                        ))}
                    </NexusSelect>
                </NexusFormField>
            </div>
            <div className='search-box'>
                <NexusSearch>
                    <NexusInput placeholder={filterLabels.search}>
                        <NexusIcon src={ActionIcSearch24px} />
                    </NexusInput>
                </NexusSearch>
            </div>
        </div>
    );
};

export default Filter;
