export const emptyControlFormData: any = {
    // controlId: '53da25ca-5295-491b-aa72-a17b87ba2118',
    controlCode: '',
    controlDescription: '',
    controlId: '',
    controlOwner: '',
    controlOwnerAu: '',
    controlPrePostTrigger: '',
    controlTaxonomyLevel1: {
        id: '',
        level: '',
        name: '',
    },
    controlTaxonomyLevel2: {
        id: '',
        level: '',
        name: '',
    },
    controlTaxonomyLevel3: {
        id: '',
        level: '',
        name: '',
    },
    controlType: {
        id: '',
        name: '',
    },
    controlWhat: false,
    controlWhen: false,
    controlWhere: false,
    controlWho: false,
    controlWhy: false,
    riskCode: '',
    overrideReason: '',
};

export const formName = 'control';

export const shortInputSize = '4';

// Modify structure before sending the data to the server
export const controlTypes = [
    { id: '1', name: 'Detective' },
    // { id: '2', name: 'Preventive' },
    { id: '2', name: 'Preventative' },
];

export const controlPrePostTriggers = [
    { id: '1', name: 'Pre-Trigger' },
    { id: '2', name: 'Post-Trigger' },
];

export const controlFlags = ['controlWhat', 'controlWhen', 'controlWho', 'controlWhere', 'controlWhy'];

export const getControlObjectProp = (controlArray: any, nameProp?: string, idProp?: string) => {
    if (nameProp) return controlArray.find((object: any) => object.name === nameProp)?.id;
    if (idProp) return controlArray.find((object: any) => object.id === idProp)?.name;
};

export const validateForControlAssessment = (formData: any) => {
    return !(
        formData.controlType &&
        (formData.controlType.length > 0 || formData.controlType.id.length > 0) &&
        formData.controlType &&
        formData.controlOwner.length > 0 &&
        formData.controlOwnerAu &&
        formData.controlOwnerAu.length > 0 &&
        formData.controlPrePostTrigger &&
        (formData.controlPrePostTrigger.length > 0 || formData.controlPrePostTrigger.id.length > 0) &&
        formData.controlTaxonomyLevel1 &&
        formData.controlTaxonomyLevel1.id.length > 0 &&
        formData.controlTaxonomyLevel2 &&
        formData.controlTaxonomyLevel2.id.length > 0 &&
        formData.controlTaxonomyLevel3 &&
        formData.controlTaxonomyLevel3.id.length > 0 &&
        formData.controlDescription &&
        formData.controlDescription.length > 0
    );
};
