import { Dispatch, SetStateAction } from 'react';

import VerticalMenu from 'app/components/verticalMenu/verticalMenu';
import ButtonComponent from 'app/components/nds/button/button';
import ConfirmationModal from 'app/components/confirmationModal/confirmationModal';
import { IItems, ITriggerRisk } from 'constants/commonExportedInterfaces';
import RiskSummaryCard from './riskSummaryCard/riskSummaryCard';
import './addressTriggerStepProcess.scss';

interface props {
    confirmationModal?: {
        actionMessage: string;
        cancelActionButton: string;
        confirmActionButton: string;
        handleConfirmation: (confirm: boolean) => void;
        setShowConfirmationModal: Dispatch<SetStateAction<boolean>>;
        showConfirmationModal: boolean;
        title: string;
    };
    currentStep?: string;
    stepActions: {
        actionButton1Disabled?: boolean;
        actionButton2Disabled?: boolean;
        actionButton1Label?: string;
        actionButton2Label: string;
        actionButton1Action?: () => void;
        actionButton2Action?: () => void;
        nextStep: string;
    };
    stepDetail: React.ReactNode;
    stepSummary?: {
        activeRisk: ITriggerRisk;
        setOpenModal: (arg: boolean) => void;
        showRiskStatus?: boolean;
    };
    verticalMenu: {
        activeSubItem: string;
        button?: string;
        chipPalette?: {};
        handleMenuAction?: () => void;
        items: IItems[];
        modified?: boolean;
        setIsNewSubItem: Dispatch<SetStateAction<boolean>>;
        setSelectedSubItem: Dispatch<SetStateAction<string>>;
        title: string;
        titleIcon: string;
    };
}

const AddressTriggerStepProcess: React.FC<props> = ({
    confirmationModal,
    currentStep,
    stepActions,
    stepDetail,
    stepSummary,
    verticalMenu,
}) => {
    return (
        <section className='address-trigger-step-section'>
            <div className='address-trigger-step-container'>
                <div className='vertical-menu-panel'>
                    <>
                        <VerticalMenu
                            activeSubItem={verticalMenu.activeSubItem}
                            chipPalette={verticalMenu.chipPalette}
                            button={verticalMenu.button}
                            handleMenuAction={verticalMenu.handleMenuAction}
                            items={verticalMenu.items}
                            setIsNewSubItem={verticalMenu.setIsNewSubItem}
                            setSelectedSubItem={verticalMenu.setSelectedSubItem}
                            title={verticalMenu.title}
                            titleIcon={verticalMenu.titleIcon}
                            modified={verticalMenu.modified}
                        />
                        {confirmationModal && (
                            <ConfirmationModal
                                actionMessage={confirmationModal.actionMessage}
                                cancelActionButton={confirmationModal.cancelActionButton}
                                confirmActionButton={confirmationModal.confirmActionButton}
                                handleConfirmation={confirmationModal.handleConfirmation}
                                setShowConfirmationModal={confirmationModal.setShowConfirmationModal}
                                showConfirmationModal={confirmationModal.showConfirmationModal}
                                title={confirmationModal.title}
                            />
                        )}
                    </>
                </div>
                <div className='address-trigger-step-panel'>
                    {stepSummary && (
                        <div className='address-trigger-step-summary'>
                            <RiskSummaryCard
                                activeRisk={stepSummary.activeRisk}
                                setOpenModal={stepSummary.setOpenModal}
                                showRiskStatus={stepSummary.showRiskStatus}
                            />
                        </div>
                    )}
                    <div className={`address-trigger-step-detail ${currentStep}`}>{stepDetail}</div>
                    <div className='address-trigger-step-actions'>
                        {stepActions.actionButton1Label && (
                            <ButtonComponent
                                label={stepActions.actionButton1Label}
                                type={['nexus-btn']}
                                click={stepActions.actionButton1Action}
                                disabled={stepActions.actionButton1Disabled}
                            />
                        )}
                        <ButtonComponent
                            label={stepActions.actionButton2Label}
                            type={['nexus-btn-primary']}
                            click={stepActions.actionButton2Action}
                            disabled={stepActions.actionButton2Disabled}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AddressTriggerStepProcess;
