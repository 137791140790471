import { REACT_APP_AUTH_TYPE } from '../config/settings';
export const providerTypes: { [id: string]: string } = {
    AD: 'AD',
    ADB2C: 'ADB2C',
    TOKEN: 'TOKEN',
};

/**
 * Returns the configured provider type, defaulting to AD if nothing is configured.
 */
export const getConfiguredProviderType = () => {
    const configured: string = REACT_APP_AUTH_TYPE || '';

    return providerTypes[configured.toUpperCase()] || providerTypes.AD;
};

/**
 * Returns true if the provided type is the configured provider type.
 */
export const isConfiguredProviderType = (type: string) => {
    const configured = getConfiguredProviderType();
    if (!type) {
        return false;
    }

    return type.toUpperCase() === configured;
};
