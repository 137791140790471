import { NexusFormField, NexusInput, NexusLabel } from '@nexus/react';

interface props {
    colLgSize: string;
    disabled?: boolean;
    formName: string;
    handleInputChange?: (args: any) => void;
    inputName: string;
    inputValue: any;
    label: string;
    required?: boolean;
    testid: string;
}

const FormInput: React.FC<props> = ({
    colLgSize,
    disabled,
    formName,
    handleInputChange,
    inputName,
    inputValue,
    label,
    required = !disabled,
    testid,
}) => {
    return (
        <div className={`nexus-col-lg-${colLgSize} nexus-col-md-8 nexus-col-xs-4`} data-testid='form-input-container'>
            <NexusFormField>
                <NexusLabel data-testid={`${formName}-${testid}-label`}>{label}</NexusLabel>
                <NexusInput
                    data-testid={`${formName}-${testid}-input`}
                    disabled={disabled}
                    value={inputValue}
                    required={required}
                    onInput={(event: any) =>
                        handleInputChange && handleInputChange({ name: inputName, value: event.target.value })
                    }
                />
            </NexusFormField>
        </div>
    );
};

FormInput.defaultProps = {
    disabled: false,
    handleInputChange: () => {},
};

export default FormInput;
