import { useEffect, useState } from 'react';

interface IUseTableFeatures {
    expandedRow: any;
    onRowClick: (event: string) => void;
    rowListData: any[];
    setCustomChangeEvent: (event: string) => void;
}

function useTableFeatures(rowData: any[], pageSize?: number, isExpandedRow?: any): IUseTableFeatures {
    const [expandedRow, setExpandedRow] = useState(isExpandedRow);
    const [rowListData, setRowListData] = useState<any[]>([]);
    const [currentSortColumn, setCurrentSortColumn] = useState('');
    const [currentSortDirection, setCurrentSortDirection] = useState('');

    useEffect(() => {
        setRowListData(rowData.slice(0, pageSize));
    }, [rowData]);

    const onRowClick = (rowIndex: any) => {
        const temp = [...expandedRow];
        temp[rowIndex] = !temp[rowIndex];
        setExpandedRow(temp);
    };

    const sortRows = (rows: any[], columnName: string | null, sortDirection: string | null): { sortedRows: any[] } => {
        // If sort is empty, return the same data
        if (!columnName || !sortDirection) {
            // Clear only when not empty
            if (currentSortColumn) {
                setCurrentSortColumn('');
                setCurrentSortDirection('');
            }

            return { sortedRows: rows };
        }

        // Update only when either column or direction were updated
        if (columnName !== currentSortColumn || sortDirection !== currentSortDirection) {
            setCurrentSortColumn(columnName);
            setCurrentSortDirection(sortDirection);
        }

        const isDesc = sortDirection === 'dec';
        const sortDesc = (a: any, b: any) => (a.toString().toUpperCase() >= b.toString().toUpperCase() ? -1 : 1);
        const sortAsc = (a: any, b: any) => (a.toString().toUpperCase() >= b.toString().toUpperCase() ? 1 : -1);

        const data = rows.sort((a, b) => {
            return isDesc ? sortDesc(a[columnName], b[columnName]) : sortAsc(a[columnName], b[columnName]);
        });
        return { sortedRows: data };
    };

    const setCustomChangeEvent = (_eventData: any) => {
        setRowListData([]);

        const currentPageSize = _eventData.detail.pageSize;
        const from = _eventData.detail.currentPage * currentPageSize - currentPageSize;
        const end = _eventData.detail.currentPage * currentPageSize;

        const { sortedRows } = sortRows(
            rowData.slice(from, end),
            _eventData.detail.sortColumn || null,
            _eventData.detail.sortDirection || null,
        ) as any;

        setRowListData(sortedRows);

        const tempRow = [];
        for (let i = 0; i < currentPageSize; i++) {
            tempRow.push(false);
        }
        setExpandedRow(tempRow);
    };

    return { expandedRow, onRowClick, rowListData, setCustomChangeEvent };
}

export default useTableFeatures;
