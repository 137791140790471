import './customContainer.scss';
import { NexusIcon } from '@nexus/react';
import ButtonIcon from '@nexus/core/dist/assets/icons/navigation/ic_more_horiz_24px.svg';

interface props {
    children: React.ReactNode;
    title: string;
}

const CustomContainer: React.FC<props> = ({ children, title }) => {
    return (
        <div className='custom-container'>
            <div data-testid='customContainerHeader' className='nexus-flex-row header'>
                <div className='nexus-xs-12 nexus-col-12'>
                    <h3>{title}</h3>
                </div>
                <div className='actions'>
                    <button
                        data-testid='customContainerActionsButton'
                        className='nexus-btn-icon actions-btn'
                        id='btn'
                        title='btn'
                    >
                        <NexusIcon src={ButtonIcon} />
                    </button>
                </div>
            </div>
            <div data-testid='customContainerBody' className='body'>
                {children}
            </div>
        </div>
    );
};

export default CustomContainer;
