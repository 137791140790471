import { NexusCheckbox } from '@nexus/react';

import { truncate } from 'utils';

interface props {
    rows: any[];
    fields: string[];
    selectedIds: string[];
    handleRowSelect: (rowId: string) => void;
}

const MapControlsBody: React.FC<props> = ({ rows, fields, handleRowSelect, selectedIds }) => {
    return (
        <tbody style={{ overflowY: 'scroll', maxHeight: '380px' }}>
            {rows &&
                rows.map((row: any, rowidx: number) => (
                    <tr key={`custom-table-row-${rowidx}`} style={{ height: '38px' }}>
                        <td>
                            <NexusCheckbox
                                checked={selectedIds.includes(row.controlId)}
                                onInput={() => handleRowSelect(row.controlId)}
                            />
                        </td>
                        {fields.map((column: string, colidx) => (
                            <td key={`custom-table-cell-${rowidx}-${colidx}`}>
                                {column === 'controlId' ? truncate(row[column], 6) : row[column]}
                            </td>
                        ))}
                    </tr>
                ))}
            <tr />
        </tbody>
    );
};

export default MapControlsBody;
