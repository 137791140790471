import React, { useEffect, useState } from 'react';
import { NexusSelect } from '@nexus/react';

import TableHeaders from '../../flagRisks/tableHeaders';
import MapControlsBody from './mapControlsBody';
import './mapControlsTable.scss';
import PaginationComponent from 'app/components/nds/pagination/pagination';
import EmptyTable from 'app/components/emptyTable/emptyTable';

interface props {
    columnDefs: {
        label: string;
        isSortable: boolean;
        field: string;
    }[];
    rows: any[];
    fields: any[];
    currentPageSize?: number;
    pageSizeOptions?: number[];
    handleRowSelection: (rowIds: string[]) => void;
}

const MapControlsTable: React.FC<props> = ({
    rows,
    columnDefs,
    fields,
    currentPageSize,
    pageSizeOptions,
    handleRowSelection,
}) => {
    const totalItems = rows.length;
    const [pageSize, setPageSize] = useState(currentPageSize || 10);
    const [sortColumn, setSortColumn] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState(rows.slice(0, pageSize));
    const [selectedIds, setSelectedIds] = useState<string[]>([]);

    useEffect(() => {
        let updatedRows = rows.slice(pageSize * (currentPage - 1), pageSize * currentPage);

        if (sortColumn) {
            const isDesc = sortDirection === 'des';
            const sortDesc = (a: any, b: any) => (a.toUpperCase() >= b.toUpperCase() ? -1 : 1);
            const sortAsc = (a: any, b: any) => (a.toUpperCase() >= b.toUpperCase() ? 1 : -1);
            setData(
                updatedRows.sort((a, b) => {
                    return isDesc ? sortDesc(a[sortColumn], b[sortColumn]) : sortAsc(a[sortColumn], b[sortColumn]);
                }),
            );
        } else {
            setData(updatedRows);
        }
    }, [sortColumn, sortDirection, pageSize, currentPage, rows]);

    // Invoke row selection handler passed from flagRisks
    useEffect(() => {
        handleRowSelection(selectedIds);
    }, [selectedIds]);

    // If row ID is already selected, remove from state, otherwise push it
    const handleRowSelect = (rowId: string) => {
        if (selectedIds.includes(rowId)) {
            setSelectedIds(selectedIds.filter((id) => id !== rowId));
        } else {
            setSelectedIds([...selectedIds, rowId]);
        }
    };

    // Always return to first page when page size changes
    const handlePageSizeChange = (event: any) => {
        setPageSize(event.target.value);
        setCurrentPage(1);
    };

    // If no rows selected, select all
    // Otherwise, clear selections
    const handleHeaderCheck = () =>
        !selectedIds.length ? setSelectedIds(rows.map((r) => r.controlId)) : setSelectedIds([]);

    if (!rows.length) return <EmptyTable columnTitles={fields} message='No controls to display for current risk.' />;

    return (
        <>
            <div style={{ overflowX: 'scroll' }}>
                <div style={{ width: '150%' }}>
                    <table className='nexus-table risks-table'>
                        <TableHeaders
                            cols={columnDefs}
                            sortColumn={sortColumn}
                            sortDirection={sortDirection}
                            setSortColumn={(col: string) => setSortColumn(col)}
                            setSortDirection={(dir: string) => setSortDirection(dir)}
                            handleHeaderCheck={handleHeaderCheck}
                            selectedRowsLength={selectedIds.length}
                            rowsLength={rows.length}
                        />
                        <MapControlsBody
                            fields={fields}
                            handleRowSelect={handleRowSelect}
                            rows={data}
                            selectedIds={selectedIds}
                        />
                    </table>
                </div>
            </div>
            <div className='pagination-container'>
                <div className='page-select'>
                    <span>Page:</span>
                    <NexusSelect value={pageSize.toString()} onInput={handlePageSizeChange}>
                        {pageSizeOptions?.map((o: number) => (
                            <option value={o} key={`page-select-option-${o}`}>
                                {o}
                            </option>
                        ))}
                    </NexusSelect>
                </div>
                <PaginationComponent
                    limit={pageSize}
                    size={totalItems}
                    offset={pageSize * (currentPage - 1)}
                    selectedPage={(page: any) => setCurrentPage(page)}
                />
            </div>
        </>
    );
};

export default MapControlsTable;

MapControlsTable.defaultProps = {
    currentPageSize: 5,
    pageSizeOptions: [5, 10, 15],
};
