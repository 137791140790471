import Flag from '@nexus/core/dist/assets/icons/content/ic_flag_24px.svg';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'store';
import { IModalData, ITriggerRisk } from 'constants/commonExportedInterfaces';
import ButtonComponent from 'app/components/nds/button/button';
import LoaderComponent from 'app/components/nds/loader/loader';
import ControlMappingTable from './controlMappingTable';
import { stepperLabels } from '../stepperConstants';
import MapControl from './mapControl/mapControl';
import CustomModal from 'app/components/customModal/customModal';
import AddressTriggerStepProcess from '../addressTriggerStepProcess/addressTriggerStepProcess';
import { updateActiveStep } from '../redux/addressTriggerActions';
import './controlMapping.scss';

const ControlMapping: React.FC<{ handleStepClick: (step: string) => void }> = () => {
    const [t] = useTranslation('lang');
    const buttons = JSON.parse(JSON.stringify(t('buttons')));
    const riskLabels = JSON.parse(JSON.stringify(t('risk.labels')));
    const menuLabels = JSON.parse(JSON.stringify(t('verticalMenu.labels')));
    const dispatch = useAppDispatch();

    const { outstandingRisks } = useAppSelector((state) => state.addressTriggerData);

    const [risks, setRisks] = useState(outstandingRisks);
    const [activeRisk, setActiveRisk] = useState<ITriggerRisk>({ ...risks[0] });
    const [showEdit, setShowEdit] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        setRisks([...outstandingRisks]);
    }, [outstandingRisks]);
    useEffect(() => {
        const newActive = risks.find((r: ITriggerRisk) => r.riskId === activeRisk.riskId);
        setActiveRisk(newActive);
    }, [risks]);

    const updateActiveRisk = (riskId: string) => {
        const newActive = risks.find((r: ITriggerRisk) => r.riskId === riskId);
        setActiveRisk(newActive);
    };

    if (!risks) return <LoaderComponent show={!risks} />;

    const getFormattedRiskForModal = (risk: ITriggerRisk) => {
        let formattedRisk: IModalData = {
            doneButton: buttons.done,
            fields: [
                { name: riskLabels.riskOwner, value: risk.riskOwner },
                { name: riskLabels.riskOwnerDivision, value: risk.riskOwnerDivision },
                { name: riskLabels.riskRegion, value: risk.riskRegion },
                { name: riskLabels.riskLevel1, value: risk.riskLevel1.name },
                { name: riskLabels.riskLevel2, value: risk.riskLevel2.name },
                { name: riskLabels.riskLevel3, value: risk.riskLevel3.name },
                { name: riskLabels.riskProcessName, value: risk.riskProcess.processName },
                { name: riskLabels.riskStatement, value: risk.riskStatement },
            ],
            title: risk.riskName,
        };

        return formattedRisk;
    };

    const formatMenuItems = (risks: ITriggerRisk[]) => {
        return risks.map((risk: ITriggerRisk) => {
            return {
                id: risk.riskId,
                name: risk.riskName,
            };
        });
    };
    const verticalMenuItems = [{ isOpen: true, item: menuLabels.outstandingRisks, subItems: formatMenuItems(risks) }];

    const stepActionsProps = {
        actionButton1Label: buttons.save,
        actionButton2Action: () => dispatch(updateActiveStep(stepperLabels.Confirmation)),
        actionButton2Label: buttons.next,
        nextStep: stepperLabels.Confirmation,
    };

    const stepDetail = (
        <>
            <ControlMappingTable
                controls={activeRisk.controls}
                riskId={activeRisk.riskId}
                riskCode={activeRisk.riskCode}
            />
            <div className='step-detail-actions'>
                <ButtonComponent
                    label={buttons.editControls}
                    type={['nexus-btn-primary nexus-btn-large']}
                    extraClass='edit'
                    click={() => setShowEdit(true)}
                />
            </div>
        </>
    );

    const stepSummary = {
        activeRisk: activeRisk,
        setOpenModal: setOpenModal,
    };

    const verticalMenuProps = {
        activeSubItem: activeRisk.riskId,
        items: verticalMenuItems,
        setIsNewSubItem: () => {},
        setSelectedSubItem: updateActiveRisk as any,
        title: menuLabels.flaggedRisks,
        titleIcon: Flag,
    };

    if (showEdit)
        return (
            <section className='nexus-container' style={{ padding: '0 35px' }}>
                <MapControl setShowEdit={() => setShowEdit(false)} risk={activeRisk} />
            </section>
        );

    return (
        <div className='control-mapping-step' data-testid='control-mapping-step' style={{ margin: '0' }}>
            {openModal && (
                <CustomModal
                    data={getFormattedRiskForModal(activeRisk)}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                />
            )}
            <AddressTriggerStepProcess
                currentStep={'control-mapping-step'}
                stepActions={stepActionsProps}
                stepDetail={stepDetail}
                stepSummary={stepSummary}
                verticalMenu={verticalMenuProps}
            />
        </div>
    );
};

export default ControlMapping;
