import CustomChip from 'app/components/customChip/customChip';

interface props {
    rows: any[];
    fields: string[];
    handleRowSelect: (row: any) => void;
}

const chipPalette = {
    Added: '#44AB46',
    Modified: '#E6B000',
};

const ControlMappingBody: React.FC<props> = ({ rows, fields, handleRowSelect }) => {
    function formatString(text: string): string {
        return text[0].toLocaleUpperCase() + text.slice(1, text.length).toLocaleLowerCase();
    }
    return (
        <tbody>
            {rows &&
                rows.map((row: any, rowidx: number) => (
                    <tr
                        data-testid={`custom-table-row-${rowidx}`}
                        key={`custom-table-row-${rowidx}`}
                        style={{ height: '38px' }}
                        onClick={() => handleRowSelect(row)}
                    >
                        {fields.map((column: string, colidx) => (
                            <td key={`custom-table-cell-${rowidx}-${colidx}`}>
                                {column === 'controlStatus' && row[column] ? (
                                    <CustomChip
                                        chipPalette={chipPalette}
                                        defaultColor={'#E6B000'}
                                        field={formatString(row[column])}
                                    />
                                ) : (
                                    row[column]
                                )}
                            </td>
                        ))}
                    </tr>
                ))}
            <tr />
        </tbody>
    );
};

export default ControlMappingBody;
