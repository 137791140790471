import { useState, useEffect } from 'react';

interface IViewportSize {
    width?: string;
}

function useViewport(): IViewportSize {
    const [viewport, setViewport] = useState<IViewportSize>({ width: '' });

    const handleResize = () => {
        if (window.innerWidth > 1024) {
            setViewport({ width: 'desktop' });
        } else {
            setViewport({ width: 'mobile' });
        }
    };

    useEffect(() => {
        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return viewport;
}

export default useViewport;
