import { NexusTable } from '@nexus/react';

interface props {
    columnTitles: string[];
    message?: string;
}

const EmptyTable: React.FC<props> = ({ columnTitles, message }) => {
    const columnData = columnTitles.map((c: any) => {
        return {
            field: c,
            isSortable: false,
            label: c,
        };
    });

    return (
        <NexusTable columns={columnData} data-testid='empty-table-container' rows={[]} type='custom'>
            <div
                slot='tbody'
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-around',
                    maxHeight: '400px',
                    minHeight: '200px',
                }}
            >
                <div className='nexus-h4'>{message}</div>
            </div>
        </NexusTable>
    );
};

EmptyTable.defaultProps = {
    message: 'No data to display',
};

export default EmptyTable;
