import { IChartData } from '../constants/commonExportedInterfaces';
const FormatChartDate = (data: any) => {
    let formatedchartData: IChartData[] = [];
    data &&
        data.forEach((element: any) => {
            let triggerDate = new Date(element.triggerDate).toLocaleDateString('en-us', {
                year: 'numeric',
                month: 'short',
            });
            formatedchartData.push({
                baseline: element.triggerBaseCount,
                name: triggerDate,
                triggers: element.triggerCount,
            });
        });
    return formatedchartData;
};
export default FormatChartDate;
