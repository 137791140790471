import { NexusIcon, NexusTable } from '@nexus/react';
import { CSSProperties } from 'react';
import ContentIcAdd24px from '@nexus/core/dist/assets/icons/content/ic_add_24px.svg';
import ContentIcRemove24px from '@nexus/core/dist/assets/icons/content/ic_remove_24px.svg';
import ContentSortAsc24px from '@nexus/core/dist/assets/icons/navigation/ic_arrow_upward_24px.svg';
import Default24px from '@nexus/core/dist/assets/icons/content/ic_sort_24px.svg';
import ContentSortDes24px from '@nexus/core/dist/assets/icons/navigation/ic_arrow_downward_24px.svg';

import './expandableTable.scss';
import useTableFeatures from 'app/hooks/useTableFeatures';
import CustomChip from '../customChip/customChip';

interface props {
    data: any[];
    fields: string[];
    columnData: {
        field: string;
        label: string;
        isSortable: boolean;
        headerClass: string;
    }[];
    pagination?: boolean;
    pageSize?: number;
    pageSizeLabel?: string;
    pageSizeOptions?: number[];
    TableCustomDetailsRow: React.FC<{
        row: any;
    }>;
    chipFields?: string[];
    chipPalette?: any;
    sortAscIcon?: string;
    sortDefaultIcon?: string;
    sortDescIcon?: string;
    expandRowIcon?: string;
    collapseRowIcon?: string;
    customBodyStyles?: CSSProperties;
    customClickableRowStyles?: CSSProperties;
    customRowDetailsStyles?: CSSProperties;
}

const ExpandableTable: React.FC<props> = ({
    data,
    fields,
    columnData,
    pagination,
    pageSize,
    pageSizeLabel,
    pageSizeOptions,
    TableCustomDetailsRow, // Create default basic component
    chipFields,
    chipPalette,
    sortAscIcon,
    sortDefaultIcon,
    sortDescIcon,
    expandRowIcon,
    collapseRowIcon,
    customBodyStyles,
    customClickableRowStyles,
    customRowDetailsStyles,
}) => {
    const totalItems = data.length;
    const isExpandedRow: any = [...Array(columnData.length)].map(() => false);

    const { expandedRow, onRowClick, rowListData, setCustomChangeEvent } = useTableFeatures(
        data,
        pageSize,
        isExpandedRow,
    );

    const getIconTemplate = (cidx: any, rowidx: any) => {
        if (cidx !== 0) return '';

        if (expandedRow[rowidx])
            return <NexusIcon src={collapseRowIcon} size='sm' style={{ color: '#ED6500', marginRight: 5 }}></NexusIcon>;

        return <NexusIcon src={expandRowIcon} size='sm' style={{ color: '#ED6500', marginRight: 5 }}></NexusIcon>;
    };

    return (
        <NexusTable
            type='custom'
            columns={columnData}
            pagination={pagination}
            pageSize={pageSize}
            pageSizeOpt={pageSizeOptions}
            totalItems={totalItems}
            onTableChangeEvent={(eventData: any) => setCustomChangeEvent(eventData)}
            sortAscIcon={sortAscIcon}
            sortDefaultIcon={sortDefaultIcon}
            sortDescIcon={sortDescIcon}
            pageSizeLabel={pageSizeLabel}
            data-testid='Nexus-Table-Custom'
        >
            <div slot='tbody' className='custom-table-rows' style={customBodyStyles}>
                {rowListData.length > 0 &&
                    rowListData.map((row: any, rowidx: any) => (
                        <>
                            <tr
                                data-testid={`expandable-row-${rowidx}`}
                                className='tr-expandable'
                                onClick={() => onRowClick(rowidx)}
                                key={`row-${rowidx}`}
                                style={customClickableRowStyles}
                            >
                                {fields.map((field: string, colidx: number) => (
                                    <td key={`cell-${rowidx}-${colidx}`}>
                                        {getIconTemplate(colidx, rowidx)}
                                        {chipFields?.includes(field) ? (
                                            chipPalette && (
                                                <CustomChip
                                                    chipPalette={chipPalette}
                                                    defaultColor={'#19191A'}
                                                    field={row[field]}
                                                />
                                            )
                                        ) : (
                                            <span
                                                className={
                                                    columnData[colidx].headerClass === 'cls-col-width' ? 'centered' : ''
                                                }
                                            >
                                                {row[field]}
                                            </span>
                                        )}
                                    </td>
                                ))}
                            </tr>

                            {expandedRow[rowidx] ? (
                                <div
                                    key={`rowdetails-${rowidx}`}
                                    className='expanded-row-details'
                                    style={customRowDetailsStyles}
                                >
                                    <TableCustomDetailsRow row={row} />
                                </div>
                            ) : null}
                        </>
                    ))}
            </div>
        </NexusTable>
    );
};

ExpandableTable.defaultProps = {
    collapseRowIcon: ContentIcRemove24px,
    expandRowIcon: ContentIcAdd24px,
    pageSize: 5,
    pageSizeOptions: [10, 25, 50],
    sortAscIcon: ContentSortAsc24px,
    sortDefaultIcon: Default24px,
    sortDescIcon: ContentSortDes24px,
};

export default ExpandableTable;
