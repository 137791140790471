import { IChartData, IViewportSize } from 'constants/commonExportedInterfaces';
import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './LineChartComponent.scss';
import ChartLegends from './ChartLegends';
import useViewport from 'app/hooks/useViewport';

interface props {
    ChartData: IChartData[];
}

const LineChartComponent: React.FC<props> = ({ ChartData }) => {
    const size: IViewportSize = useViewport();

    return (
        <div data-testid='line-chart-data' style={{ backgroundColor: '#ffffff', height: '350px', width: '100%' }}>
            {size && size.width === 'desktop' ? <ChartLegends /> : null}
            <ResponsiveContainer>
                <LineChart data={ChartData} margin={{ bottom: 15, left: 0, right: 20, top: 0 }}>
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis tickMargin={10} dataKey='name' style={{ padding: 10 }} />
                    <YAxis />
                    <Tooltip />
                    {size && size.width === 'desktop' ? (
                        <Legend layout='vertical' iconType='rect' verticalAlign='top' align='right' />
                    ) : (
                        <Legend iconType='rect' />
                    )}
                    <Line type='monotone' dataKey='baseline' stroke='#7A7F85' />
                    <Line type='monotone' dataKey='triggers' stroke='#E66D19' />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default LineChartComponent;
