import React from 'react';
import { NexusLoader } from '@nexus/react';
import ReactPortal from '../../portals/portals';

interface Props {
    show: boolean;
    fullscreen?: boolean;
}

const LoaderComponent: React.FC<Props> = ({ show, fullscreen }) => {
    return (
        <ReactPortal wrapperId='portal-root'>
            <NexusLoader data-testid='nexus-loader' show={show} fullscreen={fullscreen}></NexusLoader>
        </ReactPortal>
    );
};

export default LoaderComponent;
