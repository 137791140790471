export enum stepperLabels {
    FlagRisks = 'Flag risks',
    RiskReview = 'Risk review',
    ControlMapping = 'Control mapping',
    Confirmation = 'Confirmation',
}

export const stepperData = [
    { label: stepperLabels.FlagRisks },
    { label: stepperLabels.RiskReview },
    { label: stepperLabels.ControlMapping },
    { label: stepperLabels.Confirmation },
];
