import { NexusAvatar, NexusCard, NexusCardBody } from '@nexus/react';
import './recentActivityCard.scss';

interface props {
    activity: string;
    avatar: string;
    time: string;
    user: string;
}

const RecentActivityCard: React.FC<props> = (props) => {
    const { activity, avatar, time, user } = { ...props };

    return (
        <NexusCard data-testid='recentActivityCard' className='recent-activity-card'>
            <div className='nexus-row'>
                <div className='nexus-col-xs-4 nexus-col-md-5'>
                    <NexusAvatar data-testid='recentActivityAvatar' avatarImageSrc={avatar} userName={user} />
                </div>
                <div className='nexus-col-xs-4 nexus-col-md-3'>
                    <p className='time-ago'>{time}</p>
                </div>
            </div>
            <div className='nexus-row activity'>
                <NexusCardBody data-testid='recentActivityBody'>
                    <div className='activity-text'>{activity}</div>
                </NexusCardBody>
            </div>
        </NexusCard>
    );
};

export default RecentActivityCard;
