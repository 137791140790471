import { IItems, IVerticalMenuComponentProps } from 'constants/commonExportedInterfaces';
import MenuItem from './menuItem/menuItem';
import './verticalMenu.scss';
import ButtonComponent from '../nds/button/button';

const VerticalMenu: React.FC<IVerticalMenuComponentProps> = ({
    activeSubItem,
    button = undefined,
    chipPalette,
    handleMenuAction,
    items,
    setIsNewSubItem,
    setSelectedSubItem,
    title,
    titleIcon,
}) => {
    const subItemsCount = getSubItemsCount(items);

    function getSubItemsCount(items: IItems[]): number {
        let itemsCount = 0;
        for (let i = 0; i < items.length; i++) {
            itemsCount = itemsCount + items[i].subItems.length;
        }

        return itemsCount;
    }

    return (
        <div data-testid='vertical-menu-container' className='vertical-menu-container'>
            <div className='menu-header'>
                <img src={titleIcon} alt={titleIcon} className='title-icon' />
                <h3 data-testid='menu-title'>{`${title} (${subItemsCount})`}</h3>
            </div>
            <div className='menu-body'>
                {items.map((item, itemidx) => (
                    <MenuItem
                        chipPalette={chipPalette}
                        key={`menu-item-${item}-${itemidx}`}
                        item={item.item}
                        subItems={item.subItems}
                        isOpen={item.isOpen}
                        setIsNewSubItem={setIsNewSubItem}
                        setSelectedSubItem={setSelectedSubItem}
                        activeSubItem={activeSubItem}
                    />
                ))}
            </div>
            {button && (
                <div className='menu-footer'>
                    <ButtonComponent label={button} type={['nexus-btn-primary menu-button']} click={handleMenuAction} />
                </div>
            )}
        </div>
    );
};

export default VerticalMenu;
