import * as React from 'react';
import ActionIcHelpOutline24px from '@nexus/core/dist/assets/icons/action/ic_help_24px.svg';
import './descriptionTooltip.scss';
import { NexusIcon, NexusTooltip, NexusTooltipContent, NexusTooltipTrigger } from '@nexus/react';
import { truncate } from 'utils';

interface IDescriptionTooltip {
    description: string;
    placement: 'top' | 'bottom' | 'left' | 'right';
    size: number;
}

const DescriptionTooltip: React.FC<IDescriptionTooltip> = ({ description, placement, size }) => {
    return (
        <div className='tooltip-container' data-testid='tooltip-container'>
            {truncate(description, size)}
            <NexusTooltip placement={placement}>
                <NexusTooltipTrigger>
                    <NexusIcon data-testid='tooltip-icon' src={ActionIcHelpOutline24px}></NexusIcon>
                    <span data-testid='tooltip-content-main' className='nexus-visually-hidden'>
                        Help
                    </span>
                </NexusTooltipTrigger>
                <NexusTooltipContent data-testid='tooltip-content'>{description}</NexusTooltipContent>
            </NexusTooltip>
        </div>
    );
};

export default DescriptionTooltip;
