import { NexusFormField, NexusLabel, NexusTextarea } from '@nexus/react';
import { useTranslation } from 'react-i18next';
import './formTextarea.scss';

interface props {
    cols?: number;
    fieldName: string;
    fieldValue: any;
    formName: string;
    handleInputChange: (args: any) => void;
    label: string;
    maxLength?: number;
    rows: number;
}

const FormTextarea: React.FC<props> = ({
    cols,
    fieldName,
    fieldValue,
    formName,
    handleInputChange,
    label,
    maxLength = 600,
    rows,
}) => {
    const [t] = useTranslation('lang');
    const warnings = JSON.parse(JSON.stringify(t('warnings')));
    const testid = fieldName.includes(formName) ? fieldName.slice(formName.length).toLowerCase() : fieldName;

    return (
        <NexusFormField className='form-field-textarea'>
            <NexusLabel data-testid={`${formName}-${testid}-label`}>{label}</NexusLabel>
            <NexusTextarea
                cols={cols}
                disabled={false}
                maxLength={maxLength}
                minLength={0}
                required={true}
                rows={rows}
                data-testid={`${formName}-${testid}-textarea`}
                value={fieldValue}
                onInput={(event: any) => handleInputChange({ name: fieldName, value: event.target.value })}
            />
            <div className='max-characters-indicator-container'>
                <div>
                    <span>{`${maxLength} ${warnings.maxCharactersLegend}`}</span>
                </div>
                <div>
                    <span data-testid='max-characters-indicator'>
                        {`${fieldValue && fieldValue.length}/${maxLength}`}
                    </span>
                </div>
            </div>
        </NexusFormField>
    );
};

export default FormTextarea;
