import { NexusIcon } from '@nexus/react';

import './statusSummaryCard.scss';

interface props {
    color: string;
    icon: string;
    status: string;
    statusCount: number;
}

const StatusSummaryCard: React.FC<props> = ({ color, icon, status, statusCount }) => {
    return (
        <div className={`status-card-summary ${color}`}>
            <div className='status-icon-container'>
                <NexusIcon className='status-icon' src={icon} size='lg' />
            </div>
            <div className='status-summary-container'>
                <div className='status'>{status}</div>
                <div className='status-count'>{statusCount}</div>
            </div>
        </div>
    );
};

export default StatusSummaryCard;
