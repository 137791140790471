import { post } from './fetch';

/**
 * Default log handler that formats the supplied logData and calls an API to log.
 * @param {} logLevel
 * @param {*} logData Expected properties:
   {
        "msg": "Uncaught Error: Syntax error, unrecognized expression: #/profile",
        "url": "http://localhost:4000/static/core.core.js",
        "lineNumber": 1458,
        "columnNumber": 9,
        "type": "error",
        "detail": "Error: Request failed with status code 401 at e.exports (VM12 77.8231de9a.chunk.js:2) at e.exports (VM12 77.8231de9a.chunk.js:2) at XMLHttpRequest.d.onreadystatechange (VM12 77.8231de9a.chunk.js:2)"
   }
 * @param logUri
 */
const logHandler = (logLevel: any, logData: any, logUri: any) => {
    const { detail, ...rest } = logData;

    const data = {
        ...rest,
        type: logLevel,
    };

    data[logLevel] = logLevel === 'error' ? { stacktrace: detail } : { detail };

    // Example expected data format
    // {
    //     "msg": "Uncaught Error: Syntax error, unrecognized expression: #/profile",
    //     "url": "http://localhost:4000/static/core.core.js",
    //     "lineNumber": 1458,
    //     "columnNumber": 9,
    //     "type": "error",
    //     "info": {
    //     },
    //     "debug": {
    //     },
    //     "warn": {
    //     },
    //     "error": {
    //       "stacktrace": "Error: Request failed with status code 401 at e.exports (VM12 77.8231de9a.chunk.js:2) at e.exports (VM12 77.8231de9a.chunk.js:2) at XMLHttpRequest.d.onreadystatechange (VM12 77.8231de9a.chunk.js:2)"
    //     }
    //   }
    post({
        data,
        headers: {
            Accept: '',
        },
        url: logUri,
    });
};

export default logHandler;
