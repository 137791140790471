/* eslint-disable no-process-env */

export const {
    NODE_ENV,
    NODE_TLS_REJECT_UNAUTHORIZED,
    PUBLIC_URL,
    REACT_APP_BASE_URL,
    REACT_APP_CLIENT_ID,
    REACT_APP_CLIENT_SECRET,
    REACT_APP_TENANT_ID,
    REACT_APP_TENANT_NAME,
    REACT_APP_OAUTH_BASE_URL,
    REACT_APP_OAUTH_TOKEN_CONTEXT,
    REACT_APP_OCP_APIM_SUB_KEY,
    REACT_APP_AUTH_TYPE,
    REACT_APP_ADB2C_LOGOUT_REDIRECT_URI,
    REACT_APP_ADB2C_USER_FLOW,
    REACT_APP_OAUTH_AUTH_CONTEXT,
    REACT_APP_OAUTH_LOGOUT_CONTEXT,
    REACT_APP_REQUEST_TIMEOUT,
    REACT_APP_API_ACCOUNT_CONTEXT,
    REACT_APP_API_ACCOUNT_SEARCH_CONTEXT,
    REACT_APP_API_INDIVIDUAL_CONTEXT,
    REACT_APP_API_INDIVIDUAL_SEARCH_CONTEXT,
    REACT_APP_API_LOGGING_CONTEXT,
    REACT_APP_API_DEBUG_LOGGING,
    REACT_APP_API_ERROR_LOGGING,
} = process.env;
