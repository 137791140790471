import React from 'react';
import AuthProvider from '../../../auth/authProvider';

// abstracts the use of authprovider, may render content eventually
const Logout = () => (
    <AuthProvider doLogout={true}>
        <></>
    </AuthProvider>
);

export default Logout;
