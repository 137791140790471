const NAME = 'addressTrigger';

const actionConst = {
    activeStep: `${NAME}/activeStep`,
    addNewControl: `${NAME}/addNewControl`,
    addNewRisk: `${NAME}/addNewRisk`,
    addressRisk: `${NAME}/addressRisk`,
    assessmentUnitControls: `${NAME}/assessmentUnitControls`,
    clearAddressedRisks: `${NAME}/clearAddressedRisks`,
    clearControlAssessmentData: `${NAME}/clearControlAssessmentData`,
    clearNotification: `${NAME}/clearNotification`,
    clearRisksData: `${NAME}/clearRisksData`,
    controlAssessment: `${NAME}/controlAssessment`,
    controlTaxonomies: `${NAME}/controlTaxonomies`,
    editControl: `${NAME}/editControl`,
    editRisk: `${NAME}/editRisk`,
    flaggedRiskSelected: `${NAME}/flaggedRiskSelected`,
    flaggedRisks: `${NAME}/flaggedRisks`,
    mapControlsToRisk: `${NAME}/mapControlsToRisk`,
    riskCalRating: `${NAME}/riskCalRating`,
    riskImpacts: `${NAME}/riskImpacts`,
    riskLikelihoods: `${NAME}/riskLikelihoods`,
    riskProcesses: `${NAME}/processes`,
    riskTaxonomies: `${NAME}/riskTaxonomies`,
    risksData: `${NAME}/risksData`,
    submitTrigger: `${NAME}/submitTrigger`,
    updateOutstandingRisks: `${NAME}/updateOutstandingRisks`,
    updateTriggerStatus: `${NAME}/updateTriggerStatus`,
    updateUserRole: `${NAME}/updateUserRole`,
};

export default actionConst;
