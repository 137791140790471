import React from 'react';
import { NexusAvatar } from '@nexus/react';
import './avatar.scss';

const AvatarComponent: React.FC<{ name: string; showName: boolean }> = ({ name, showName }) => {
    return (
        <NexusAvatar
            data-testid='avatar'
            avatarSize='lg'
            userName={name}
            avatarClassName={''}
            avatarNameDisplay={showName}
        />
    );
};

export default AvatarComponent;
