import { useEffect, useState } from 'react';
import { NexusSelect } from '@nexus/react';

import TableHeaders from './tableHeaders';
import TableBody from './tableBody';
import './risksTable.scss';
import PaginationComponent from 'app/components/nds/pagination/pagination';

interface props {
    columnDefs: {
        label: string;
        isSortable: boolean;
        field: string;
    }[];
    rows: any[];
    fields: any[];
    currentPageSize?: number;
    pageSizeOptions?: number[];
    rowSelection: any[];
    handleRowSelection: (rows: any[]) => void;
}

const RisksTable: React.FC<props> = ({
    columnDefs,
    rows,
    fields,
    currentPageSize,
    pageSizeOptions,
    rowSelection,
    handleRowSelection,
}) => {
    const totalItems = rows.length;
    const [pageSize, setPageSize] = useState(currentPageSize || 10);
    const [sortColumn, setSortColumn] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState(rows.slice(0, pageSize));
    const [selectedRows, setSelectedRows] = useState<any[]>(rowSelection);

    useEffect(() => {
        let updatedRows = data.slice(pageSize * (currentPage - 1), pageSize * currentPage);

        // If page size is bigger than current data, slice from original data (rows)
        if (updatedRows.length < pageSize) {
            updatedRows = rows.slice(pageSize * (currentPage - 1), pageSize * currentPage);
        }

        if (sortColumn) {
            const isDesc = sortDirection === 'des';
            const sortDesc = (a: any, b: any) => (a.toUpperCase() >= b.toUpperCase() ? -1 : 1);
            const sortAsc = (a: any, b: any) => (a.toUpperCase() >= b.toUpperCase() ? 1 : -1);
            setData(
                updatedRows.sort((a, b) => {
                    return isDesc ? sortDesc(a[sortColumn], b[sortColumn]) : sortAsc(a[sortColumn], b[sortColumn]);
                }),
            );
        } else {
            setData(updatedRows);
        }
    }, [sortColumn, sortDirection, pageSize, currentPage]);

    // Always return to first page when page size changes
    const handlePageSizeChange = (event: any) => {
        setPageSize(event.target.value);
        setCurrentPage(1);
    };

    // Invoke row selection handler passed from flagRisks
    useEffect(() => {
        handleRowSelection(selectedRows);
    }, [selectedRows]);

    // If selected risk is already in flagged, remove, otherwise push it
    const handleRowSelect = (row: any, selectedRowsIds: any[]) => {
        const cleanRow = rows.find((r) => r.riskId === row.riskId);
        if (selectedRowsIds.includes(row.riskId)) {
            setSelectedRows(selectedRows.filter((r: any) => r.riskId !== row.riskId));
        } else {
            setSelectedRows([...selectedRows, cleanRow]);
        }
    };

    // If no rows selected, select all
    // Otherwise, clear selections
    const handleHeaderCheck = () => (!selectedRows.length ? setSelectedRows(rows) : setSelectedRows([]));

    return (
        <>
            <table className='nexus-table risks-table' data-testid='risk-table'>
                <TableHeaders
                    cols={columnDefs}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    setSortColumn={(col: string) => setSortColumn(col)}
                    setSortDirection={(dir: string) => setSortDirection(dir)}
                    handleHeaderCheck={handleHeaderCheck}
                    selectedRowsLength={selectedRows.length}
                    rowsLength={rows.length}
                />
                <TableBody rows={data} fields={fields} handleRowSelect={handleRowSelect} selectedRows={selectedRows} />
            </table>
            <div className='pagination-container'>
                <div className='page-select'>
                    <span>Page:</span>
                    <NexusSelect
                        value={pageSize.toString()}
                        onInput={handlePageSizeChange}
                        data-testid='risk-pagination'
                    >
                        {pageSizeOptions?.map((o: number) => (
                            <option value={o} key={`page-select-option-${o}`} data-testid='risk-pagination-options'>
                                {o}
                            </option>
                        ))}
                    </NexusSelect>
                </div>
                <PaginationComponent
                    limit={pageSize}
                    size={totalItems}
                    offset={pageSize * (currentPage - 1)}
                    selectedPage={(page: any) => setCurrentPage(page)}
                />
            </div>
        </>
    );
};

RisksTable.defaultProps = {
    currentPageSize: 5,
    pageSizeOptions: [5, 10, 15],
};

export default RisksTable;
