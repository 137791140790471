import * as ACTIONS from './triggersActions';

import { IAssessmentUnitTrigger } from 'constants/commonExportedInterfaces';
import { createSlice } from '@reduxjs/toolkit';

interface ITriggersSliceReducer {
    triggersData: Array<IAssessmentUnitTrigger>;
    loading: boolean;
    currentTrigger: object;
    refreshTrigger: boolean;
}

export const INITIAL_STATE_TRIGGERS: ITriggersSliceReducer = {
    currentTrigger: {},
    loading: false,
    refreshTrigger: false,
    triggersData: [],
};

type TriggersState = Readonly<typeof INITIAL_STATE_TRIGGERS>;

export const triggersSliceReducer = createSlice({
    extraReducers: (builder) =>
        builder
            .addCase(ACTIONS.getTriggersData.fulfilled, (state, action) => {
                state.triggersData = action.payload?.data;
                state.loading = false;
            })
            .addCase(ACTIONS.getTriggersData.pending, (state) => {
                state.loading = true;
            })
            .addCase(ACTIONS.getTriggerById.fulfilled, (state, action) => {
                state.currentTrigger = action.payload?.data;
                state.loading = false;
            })
            .addCase(ACTIONS.getTriggerById.pending, (state) => {
                state.loading = true;
            })
            .addCase(ACTIONS.dismissTrigger.fulfilled, (state, action) => {
                const newTrigger = action.payload?.data;
                if (!!newTrigger.triggerId) {
                    state.triggersData = state.triggersData.map((t) => {
                        return t.triggerId === newTrigger.triggerId ? newTrigger : t;
                    });
                } else {
                    state.triggersData = [...state.triggersData];
                }
                state.refreshTrigger = !state.refreshTrigger;
                state.triggersData = [];
            })
            .addCase(ACTIONS.dismissTrigger.rejected, (state) => {
                state.triggersData = state.triggersData.map((t) => t);
            }),
    initialState: INITIAL_STATE_TRIGGERS as TriggersState,
    name: 'triggersData',
    reducers: {},
});

export default triggersSliceReducer.reducer;
