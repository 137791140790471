import { useTranslation } from 'react-i18next';

import './controlAssessment.scss';
import { controlFlags } from './controlFormConsts';
import ControlFlagIcon from './controlFlagIcon';
import { useState } from 'react';
import FormTextarea from 'app/components/formTextarea/formTextarea';

interface props {
    controlAssessment: any;
    controlAssessmentIsValid: boolean;
    overrideReason: string;
    handleInputChange: (args: any) => void;
}

const ControlAssessment: React.FC<props> = ({
    controlAssessment,
    controlAssessmentIsValid,
    overrideReason,
    handleInputChange,
}) => {
    const [t] = useTranslation('lang');
    const controlLabels = JSON.parse(JSON.stringify(t('control.labels')));
    const [showOverrideTextarea, setShowOverrideTextarea] = useState(false);

    return (
        <div className='nexus-row control-assessment-container'>
            <div className='nexus-col-lg-5 nexus-col-md-8 nexus-col-xs-4'>
                <div className='nexus-row'>
                    <div className='nexus-col-lg-5 texts'>
                        <div className='texts control-assessment'>{controlLabels.controlAssessment}</div>
                    </div>
                    <div className='nexus-col-lg-7' data-testid='control-assessment-result'>
                        {controlAssessmentIsValid ? (
                            <div className='result pass'>{controlLabels.pass}</div>
                        ) : (
                            <div className='fail-process texts'>
                                <div className='result fail'>{controlLabels.fail}</div>
                                {!showOverrideTextarea && (
                                    <div
                                        className='override-message'
                                        data-testid='override-process'
                                        onClick={() => setShowOverrideTextarea(true)}
                                    >
                                        {controlLabels.override}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className='vertical-divider'></div>
            <div className='nexus-col-lg-6 nexus-col-md-8 nexus-col-xs-4 control-assessment-flags'>
                <div className='nexus-row texts'>
                    {controlFlags.map((controlFlag, controlidx) => (
                        <ControlFlagIcon
                            key={`control-flag-icon-${controlFlag}-${controlidx}`}
                            controlFlag={controlAssessment[controlFlag]}
                            controlFlagLabel={controlLabels[controlFlag]}
                        />
                    ))}
                </div>
            </div>
            {showOverrideTextarea && (
                <FormTextarea
                    cols={121}
                    fieldName={'overrideReason'}
                    fieldValue={overrideReason}
                    formName={'control'}
                    handleInputChange={handleInputChange}
                    label={controlLabels.override}
                    maxLength={300}
                    rows={5}
                />
            )}
        </div>
    );
};

export default ControlAssessment;
