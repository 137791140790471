import React, { useState } from 'react';
import { NexusDropdown, NexusOption } from '@nexus/react';
import './dropdown.scss';
import AvatarComponent from '../avatar/avatar';
import { Link } from 'react-router-dom';

const DropdownComponent: React.FC<{ dropDownLabel: string }> = ({ dropDownLabel }) => {
    const [show, setShow] = useState(false);

    return (
        <NexusDropdown
            data-testid='drop-down'
            show={show}
            placement={'left'}
            dropdown-type={'basic'}
            height={400}
            width={400}
        >
            <span onClick={() => setShow(!show)} slot={'trigerer'}>
                <AvatarComponent name={dropDownLabel} showName={false} />
            </span>
            <NexusOption>
                <Link to='/profile'>Profile</Link>
            </NexusOption>
            <NexusOption>
                <Link to='/logout'>Logout</Link>
            </NexusOption>
        </NexusDropdown>
    );
};

export default DropdownComponent;
