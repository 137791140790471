import { NexusCheckbox } from '@nexus/react';
import CustomChip from 'app/components/customChip/customChip';

import { truncate } from 'utils';

interface props {
    rows: any[];
    fields: string[];
    selectedRows: any[];
    handleRowSelect: (row: any, selectedRowsIds: any[]) => void;
}

const chipPalette = {
    'Moderate-High': '#19191A',
    'Low': '#19191A',
    'Severe': '#19191A',
};

const TableBody: React.FC<props> = ({ rows, fields, handleRowSelect, selectedRows }) => {
    const selectedRowsIds = selectedRows.map((r) => r.riskId);

    const formattedRows = rows.map((r) => {
        return {
            ...r,
            riskStatement: truncate(r.riskStatement, 100),
        };
    });

    return (
        <tbody style={{ overflowY: 'scroll', maxHeight: '380px' }}>
            {formattedRows &&
                formattedRows.map((row: any, rowidx: number) => (
                    <tr
                        key={`custom-table-row-${rowidx}`}
                        style={{ height: '38px' }}
                        data-testid={`custom-table-body-row-${rowidx}`}
                    >
                        <td>
                            <NexusCheckbox
                                checked={selectedRowsIds.includes(row.riskId)}
                                onInput={() => handleRowSelect(row, selectedRowsIds)}
                                data-testid={`custom-table-selectedRowsIds-${rowidx}`}
                            />
                        </td>
                        {fields.map((column: string, colidx) => (
                            <td key={`custom-table-cell-${rowidx}-${colidx}`}>
                                {column !== 'publishedInherentRiskDescription' ? (
                                    column === 'riskId' ? (
                                        truncate(row[column], 6)
                                    ) : (
                                        row[column]
                                    )
                                ) : (
                                    <CustomChip
                                        chipPalette={chipPalette}
                                        defaultColor={'#19191A'}
                                        field={row[column]}
                                    />
                                )}
                            </td>
                        ))}
                    </tr>
                ))}
            <tr />
        </tbody>
    );
};

export default TableBody;
