export const apiCall = `${process.env.REACT_APP_BASE_URL}/api`;
export const tableApiUrl = `${apiCall}/sessions`;
export const riskControlTestDataUrl = `${apiCall}/test-data-tables`;
export const riskControlUrl = `${apiCall}/risk-control-testcase-combinations`;
export const sectorApiUrl = `${apiCall}/sectors`;
export const lobApiUrl = `${apiCall}/lobs?sector_id=`;
export const processApiUrl = `${apiCall}/processes?lob_id=`;
export const subProcessApiUrl = `${apiCall}/processes?parent_process_id=`;
export const viewResultsUrl = `${apiCall}/test-results`;
export const assessmentUnitsUrl = `${apiCall}/assessment-units`;
export const triggersUrl = `${apiCall}/triggers`;
export const risksUrl = `${apiCall}/risks`;
export const controlsUrl = `${apiCall}/controls`;
export const resetUrl = `${apiCall}/demo/reset-test-data`;
