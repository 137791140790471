/**
 * Active Directory Authentication component. Uses Redux store to manage token data and hidden iframe for
 * refreshes.
 */

/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react';
import { authenticate, authenticateSilently, logout } from './adAuthProvider';
import { useDispatch, useSelector } from 'react-redux';
import { parse } from 'query-string';
import {
    selectAuthToken,
    selectAuthEmail,
    setAuthToken,
    selectIsAuthenticated,
    setAuthUser,
    setInitialState,
    selectIsAuthTokenExpired,
    selectIsLogoutRequested,
} from '../authSlice';
import jwtDecode from 'jwt-decode';
import { getValueFromSearchString } from '../../utils/tokenUtils';
import { AppDispatch, RootState } from '../../store/index';
/**
 * Returns properly formatted token data object with access token and expiration
 * @param {*} accessToken
 * @param {*} expiresIn
 */
export const formatTokenData = (accessToken: any, expiresIn: number) => ({
    accessToken,
    tokenExpiration: Date.now() + expiresIn * 1000,
});

/**
 * Extracts relevant user data from the id token
 * @param {} token
 * @param {*} dispatch
 */
export const decodeIdToken = (token: any, dispatch: AppDispatch) => {
    if (!token) {
        return;
    }
    const { name, preferred_username, unique_name }: { [id: string]: string } = jwtDecode(token);
    dispatch(setAuthUser({ name, email: unique_name || preferred_username }));
};

/**
 * Extracts access and id token data from window.location, dispatching appropriate actions afterward to
 * setup the Redux store.
 * @param {*} dispatch
 */
export const getTokenDataFromUriLocation = (accessTokenRef: { [id: string]: any }, dispatch: AppDispatch) => {
    const { hash, pathname, search } = window.location;
    const response = parse(hash);
    if (Object.keys(response).length) {
        const { access_token, id_token, expires_in } = response;
        window.history.pushState('', document.title, `${pathname}${search}`);

        // If no token, something went wrong, update token state to prevent re-rendering
        accessTokenRef.current = access_token || 'INVALID_TOKEN';

        const tokenData = formatTokenData(access_token, Number(expires_in));
        dispatch(setAuthToken(tokenData));

        decodeIdToken(id_token, dispatch);
    }
};

/**
 * Responds to the iframe load checking for an access token in session storage
 * @param {*} dispatch
 */
export const handleIframeLoad = (dispatch: AppDispatch) => {
    // This is stored in the tokenRefresh.html file
    const tokenString = window.sessionStorage.getItem('tokenRenewalString');
    if (!tokenString) {
        return;
    }

    window.sessionStorage.removeItem('tokenRenewalString');
    const accessToken = getValueFromSearchString('access_token', tokenString);
    const expiresIn = Number(getValueFromSearchString('expires_in', tokenString));
    const tokenData = formatTokenData(accessToken, expiresIn);

    dispatch(setAuthToken(tokenData));
};

interface InputProps {
    children: React.ReactNode;
    store?: RootState;
    doLogout?: boolean;
}

const ADAuth = ({ children, doLogout, store }: InputProps) => {
    const [showChildren, setShowChildren] = useState(false);
    const [iframeRef, setIframeRef] = useState<HTMLDivElement | null>(null);
    const dispatch = useDispatch();
    // Check store (redux) for the token, if not available the auth request will occur.
    // Could persist the token so refreshes don't force a refetch but that introduces potential
    // security risks
    const accessToken = useRef(useSelector(selectAuthToken));
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const isTokenExpired = useSelector(selectIsAuthTokenExpired);
    const isLogoutRequested = useSelector(selectIsLogoutRequested);
    const loggingOut = isLogoutRequested || doLogout;
    const email = useSelector(selectAuthEmail);

    useEffect(() => {
        if (isTokenExpired) {
            authenticateSilently(email, iframeRef);
        }
    }, [isTokenExpired]);

    useEffect(() => {
        if (!loggingOut) {
            getTokenDataFromUriLocation(accessToken, dispatch);
        }
    }, [store]);

    useEffect(() => {
        if (!loggingOut) {
            accessToken.current ? setShowChildren(true) : authenticate();
        }
    }, [accessToken.current]);

    useEffect(() => {
        if (loggingOut) {
            dispatch(setInitialState(''));
            setShowChildren(false);
            accessToken.current = null;
            logout();
        }
    }, [loggingOut]);

    return (
        <>
            {showChildren && isAuthenticated && (
                <>
                    {children}
                    <iframe
                        title={setIframeRef.toString()}
                        ref={setIframeRef}
                        style={{ border: 0, display: 'block', height: 0, width: 0 }}
                        onLoad={() => handleIframeLoad(dispatch)}
                    />
                </>
            )}
            {showChildren && !isAuthenticated && <>Not Authenticated - Check env settings.</>}
        </>
    );
};

export default ADAuth;
