import * as ACTIONS from './assessmentUnitActions';

import { IAssessmentUnit } from 'constants/commonExportedInterfaces';
import { createSlice } from '@reduxjs/toolkit';

interface IAssessmentUnitSliceReducer {
    assessmentUnitData: Array<IAssessmentUnit>;
    currentAssessmentUnit: IAssessmentUnit | null;
    loading: boolean;
}

export const INITIAL_STATE_ASSESSMENT_UNIT: IAssessmentUnitSliceReducer = {
    assessmentUnitData: [],
    currentAssessmentUnit: null,
    loading: false,
};

type AssessmentUnitState = Readonly<typeof INITIAL_STATE_ASSESSMENT_UNIT>;

export const assessmentUnitSliceReducer = createSlice({
    extraReducers: (builder) =>
        builder
            .addCase(ACTIONS.getAssessmentUnitData.fulfilled, (state, action) => {
                state.assessmentUnitData = action.payload?.data;
                state.loading = false;
            })
            .addCase(ACTIONS.getAssessmentUnitData.pending, (state) => {
                state.loading = true;
            })
            .addCase(ACTIONS.getAssessmentUnitById.fulfilled, (state, action) => {
                state.currentAssessmentUnit = action.payload?.data;
                state.loading = false;
            })
            .addCase(ACTIONS.getAssessmentUnitById.pending, (state) => {
                state.loading = true;
            })
            .addCase(ACTIONS.updateCurrentAssessmentUnit, (state, action) => {
                state.currentAssessmentUnit = action.payload ? { ...action.payload } : null;
            }),
    initialState: INITIAL_STATE_ASSESSMENT_UNIT as AssessmentUnitState,
    name: 'assessmentUnitData',
    reducers: {},
});

export default assessmentUnitSliceReducer.reducer;
