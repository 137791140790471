import { NexusFormField, NexusSelect } from '@nexus/react';
import './assessmentUnitSelect.scss';
interface props {
    assessmentUnit: any;
    assessmentUnits: any[];
    handleSelectChange: (args: any) => void;
}

const AssessmentUnitSelect: React.FC<props> = ({ assessmentUnit, handleSelectChange, assessmentUnits }) => {
    return (
        <NexusFormField>
            <NexusSelect
                className='assessment-unit-select'
                data-testid='assessment-unit-select'
                value={assessmentUnit.auId}
                style={{ backgroundColor: '#FAFAFA' }}
                defaultValue={assessmentUnit.auId}
                onInput={(event: any) => handleSelectChange(event.target.value)}
            >
                {assessmentUnits.map((assessmentUnit: any) => (
                    <option key={assessmentUnit.auId} value={assessmentUnit.auId}>
                        {assessmentUnit.auName}
                    </option>
                ))}
            </NexusSelect>
        </NexusFormField>
    );
};

export default AssessmentUnitSelect;
