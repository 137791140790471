import { useParams } from 'react-router';
import React, { useEffect } from 'react';

import BackNavigation from 'app/components/backNavigation/backNavigation';
import BasicTable from 'app/components/basicTable/basicTable';
import TabBar from 'app/components/tabBar/tabBar';
import './risksAndControlsInventory.scss';
import { useAppDispatch, useAppSelector } from 'store';
import { getAssessmentUnitById } from '../redux/assessmentUnits/assessmentUnitActions';
import { getRisksDataByAU } from '../redux/risks/risksActions';
import { IRiskControl, ITriggerRisk } from 'constants/commonExportedInterfaces';
import { getControlsDataByAU } from '../redux/controls/controlsActions';
import { truncate } from '../../../../utils/dataHandler';
import EmptyTable from 'app/components/emptyTable/emptyTable';
import LoaderComponent from 'app/components/nds/loader/loader';
import DescriptionTooltip from 'app/components/descriptionTooltip/descriptionTooltip';

const risksColumnTitles = [
    'Process',
    'Risk Id',
    'Risk Statement',
    'Risk Level 1',
    'Risk Level 2',
    'Risk Level 3',
    'Risk Rating',
];

const risksFields = [
    'riskProcess',
    'riskCode',
    'riskStatement',
    'riskLevel1',
    'riskLevel2',
    'riskLevel3',
    'riskRating',
];

const controlsColumnTitles = [
    'Control Type',
    'Control Id',
    'Control Description',
    'Control Owner',
    'Control Owner Assessment Unit',
    'Control Taxonomy level 1',
    'Control Taxonomy level 2',
    'Control Taxonomy level 3',
    'Pre/Post Trigger',
];

const controlsFields = [
    'controlType',
    'controlCode',
    'controlDescription',
    'controlOwner',
    'controlOwnerAu',
    'controlTaxonomyLevel1',
    'controlTaxonomyLevel2',
    'controlTaxonomyLevel3',
    'controlPrePostTrigger',
];

const chipPalette = {
    Rating: '#19191A',
};

const AssessmentUnitRisksControlsInventory: React.FC = () => {
    const params = useParams();
    const dispatch = useAppDispatch();
    const link = `/dashboard`;

    const risks: ITriggerRisk[] = useAppSelector((state) => state.risksData.risksData);
    const loadingRisks = useAppSelector((state) => state.risksData.loading);

    const controls: IRiskControl[] = useAppSelector((state) => state.controlsData.controlsData);
    const loadingControls = useAppSelector((state) => state.controlsData.loading);

    const { currentAssessmentUnit, loading: loadingAssessmentUnit } = useAppSelector(
        (state) => state.assessmentUnitData,
    );

    const risksRows =
        risks &&
        risks.length > 0 &&
        risks.map((risk) => {
            return {
                riskCode: risk.riskCode,
                riskLevel1: truncate(risk.riskLevel1.name, 10),
                riskLevel2: truncate(risk.riskLevel2.name, 10),
                riskLevel3: truncate(risk.riskLevel3.name, 10),
                riskProcess: truncate(risk.riskProcess.processCode, 10),
                riskRating: risk.publishedInherentRiskDescription,
                riskStatement: <DescriptionTooltip description={risk.riskStatement} placement='right' size={30} />,
            };
        });

    let risksColumnDefs = risksRows
        ? risksFields.map((risksField: string, idx) => {
              return {
                  field: risksField,
                  isSortable: ['riskProcess'].includes(risksField),
                  label: risksColumnTitles[idx],
              };
          })
        : [];

    const controlsRows =
        controls &&
        controls.length > 0 &&
        controls.map((control) => {
            return {
                controlCode: control.controlCode,
                controlDescription: (
                    <DescriptionTooltip description={control.controlDescription} placement='right' size={25} />
                ),
                controlOwner: truncate(control.controlOwner, 10),
                controlOwnerAu: truncate(control.controlOwnerAu, 10),
                controlPrePostTrigger: truncate(control.controlPrePostTrigger, 10),
                controlTaxonomyLevel1: truncate(control.controlTaxonomyLevel1.name, 10),
                controlTaxonomyLevel2: truncate(control.controlTaxonomyLevel2.name, 10),
                controlTaxonomyLevel3: truncate(control.controlTaxonomyLevel3.name, 10),
                controlType: truncate(control.controlType, 10),
            };
        });

    let controlsColumnDefs = controlsRows
        ? controlsFields.map((controlsField: string, idx) => {
              return {
                  field: controlsField,
                  isSortable: controlsFields.includes(controlsField),
                  label: controlsColumnTitles[idx],
              };
          })
        : [];

    const risksTable = risksRows && (
        <BasicTable
            chipFields={['riskRating']}
            chipPalette={chipPalette}
            columnDefs={risksColumnDefs}
            currentPage={1}
            fields={risksFields}
            maxHeight={'10 Rows'}
            pageSize={10}
            pageSizeLabel={'Show:'}
            pagination={true}
            rowData={risksRows}
            rowSelection={false}
        />
    );

    const controlsTable = controlsRows && (
        <BasicTable
            columnDefs={controlsColumnDefs}
            currentPage={1}
            fields={controlsFields}
            maxHeight={'10 Rows'}
            pageSize={10}
            pageSizeLabel={'Show:'}
            pagination={true}
            rowData={controlsRows}
            rowSelection={false}
        />
    );

    function getEmptyTable(columnTitles: string[], dataType: string): JSX.Element {
        return (
            <EmptyTable
                columnTitles={columnTitles}
                message={`No ${dataType} information found for this Assessment Unit.`}
            />
        );
    }

    const tabOptions = [
        {
            isActive: true,
            title: 'Risks',
            value: !loadingRisks && risks.length === 0 ? getEmptyTable(risksColumnTitles, 'risks') : risksTable,
        },
        {
            isActive: false,
            title: 'Controls',
            value:
                !loadingControls && controls.length === 0
                    ? getEmptyTable(controlsColumnTitles, 'controls')
                    : controlsTable,
        },
    ];

    useEffect(() => {
        dispatch(getAssessmentUnitById(`${params.id}`));
        dispatch(getRisksDataByAU(`${params.id}`));
        dispatch(getControlsDataByAU(`${params.id}`));
    }, []);

    if (loadingAssessmentUnit || loadingRisks || loadingControls)
        return <LoaderComponent show={!currentAssessmentUnit} />;

    return (
        <div
            data-testid='assessment-unit-risks-controls-inventory'
            className='assessment-unit-risks-controls-inventory'
        >
            <section className='nexus-container'>
                <BackNavigation goToLink={link} />
                {!loadingAssessmentUnit && !!currentAssessmentUnit && (
                    <h1 className='page-title' aria-label='Assessment Unit Triggers'>
                        {`${currentAssessmentUnit.auName} Risks and Controls Inventory`}
                    </h1>
                )}
                <TabBar tabOptions={tabOptions} />
            </section>
        </div>
    );
};

export default AssessmentUnitRisksControlsInventory;
