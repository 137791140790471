import React, { useState } from 'react';
import { NexusTabBar } from '@nexus/react';

import './tabBar.scss';
import { ITabOption } from 'constants/commonExportedInterfaces';

interface props {
    tabOptions: ITabOption[];
}

const TabBar: React.FC<props> = ({ tabOptions }) => {
    const [newtabOption, setnewtabOption] = useState(tabOptions);

    const ChangeActive = (index: number) => {
        const selectedTab = [...tabOptions];

        selectedTab.forEach((option) => {
            option.isActive = false;
        });
        selectedTab[index].isActive = true;
        setnewtabOption(selectedTab);
    };

    return (
        <div className='tab-bar-container'>
            <NexusTabBar data-testid='nexus-tab-bar'>
                <span className='tab-bar-span'>
                    {newtabOption.map((option, index) =>
                        option.isActive ? (
                            <button
                                data-testid='tab-bar-active'
                                key={index}
                                className='nexus-link nexus-active tab-bar-button active-tab'
                                onClick={() => ChangeActive(index)}
                            >
                                {option.title}
                            </button>
                        ) : (
                            <button
                                data-testid='tab-bar'
                                key={index}
                                className='nexus-link tab-bar-button inactive-tab'
                                onClick={() => ChangeActive(index)}
                            >
                                {option.title}
                            </button>
                        ),
                    )}
                </span>
            </NexusTabBar>
            <div>
                {newtabOption.map(
                    (option, index) =>
                        option.isActive && (
                            <div data-testid='tab-bar-selected-label' className='tab-bar-content' key={index}>
                                {option.value}
                            </div>
                        ),
                )}
            </div>
        </div>
    );
};

export default TabBar;
