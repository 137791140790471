import { IField } from 'constants/commonExportedInterfaces';
import ModalFieldContainer from './modalFieldContainer';
import './customModal.scss';

interface props {
    modalRow: IField[];
    isLongField?: boolean;
}

const ModalRowContainer: React.FC<props> = ({ modalRow, isLongField = false }) => {
    return (
        <div
            className='modal-row-container'
            data-testid='modal-row-container'
            style={{ flexDirection: `${isLongField ? 'column' : 'row'}` }}
        >
            {modalRow.map((field, fieldidx) => (
                <ModalFieldContainer key={`field-${fieldidx}`} field={field} />
            ))}
        </div>
    );
};

export default ModalRowContainer;
