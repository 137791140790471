import { NexusCard, NexusStepper, NexusStepperItem } from '@nexus/react';

import './stepper.scss';

export interface IStepperItem {
    label: string;
    onClickAction: (args: any) => void;
}

interface IStepperProps {
    steps: IStepperItem[];
    activeStep: string;
}

const Stepper: React.FC<IStepperProps> = ({ activeStep, steps }) => {
    let activeStepIndex = steps.findIndex(function (step) {
        return step.label === activeStep;
    });

    const getStatus = (index: number) => {
        if (index === activeStepIndex) return 'active';
        if (index < activeStepIndex) return 'complete';
        return 'default';
    };

    const stepperItems = steps.map((s, idx: number) => {
        return {
            ...s,
            status: getStatus(idx),
        };
    });

    // Only navigate back (only if the status is 'complete')
    const handleClick = (item: typeof stepperItems[0]) => {
        if (item.status === 'complete') item.onClickAction(item.label);
    };

    return (
        <NexusCard className='stepper-container'>
            <NexusStepper className='nexus-subtitle-sm' data-testid='stepper'>
                {stepperItems.map((step, idx) => {
                    return (
                        <NexusStepperItem
                            key={idx}
                            data-testid={`step-${idx}`}
                            status={step.status as any}
                            onClick={() => handleClick(step)}
                            style={{ cursor: step.status !== 'default' ? 'pointer' : 'not-allowed' }}
                            className='stepper-item'
                        >
                            <span className='nexus-ml-1'>{step.label}</span>
                        </NexusStepperItem>
                    );
                })}
            </NexusStepper>
        </NexusCard>
    );
};

export default Stepper;
