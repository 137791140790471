import { IBarChartData, IViewportSize } from 'constants/commonExportedInterfaces';
import React from 'react';
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, BarChart, ResponsiveContainer } from 'recharts';
import './BarChartComponent.scss';
import ChartLegends from './ChartLegends';
import useViewport from 'app/hooks/useViewport';

interface props {
    ChartData: IBarChartData[];
}

const BarChartComponent: React.FC<props> = ({ ChartData }) => {
    const size: IViewportSize = useViewport();

    return (
        <div data-testid='bar-chart-data' className='barchat-container'>
            <ChartLegends />
            <ResponsiveContainer>
                <BarChart
                    width={1000}
                    height={300}
                    data={ChartData}
                    margin={{ bottom: 15, left: 0, right: 15, top: 0 }}
                >
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis tickMargin={10} dataKey='name' tick={{ fontSize: 12 }} width={350} />
                    <YAxis />
                    <Tooltip />

                    {size && size.width === 'desktop' ? (
                        <Legend name='Legends' layout='vertical' verticalAlign='top' align='right' />
                    ) : (
                        <Legend name='Legends' layout='vertical' />
                    )}
                    <Bar dataKey='mitigated' stackId='a' fill='#59D159' />
                    <Bar dataKey='unmitigated' stackId='a' fill='#FF5833' />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default BarChartComponent;
