import './customChip.scss';

interface props {
    chipPalette: {};
    defaultColor: string;
    field: string;
    loadingLegend?: string;
}

const CustomChip: React.FC<props> = ({ chipPalette, defaultColor, field, loadingLegend }) => {
    return (
        <span
            className='custom-chip'
            style={{ backgroundColor: chipPalette[field as keyof typeof chipPalette] || defaultColor }}
        >
            {field || loadingLegend}
        </span>
    );
};

export default CustomChip;
