import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import './App.scss';
import store, { persistor } from '../store';
import ErrorBoundary from './components/errorboundry/errorBoundary';
import AppRoutes from './routes';

const App = () => {
    return (
        <React.StrictMode>
            <ErrorBoundary>
                <Provider store={store}>
                    <PersistGate persistor={persistor}>
                        <AppRoutes />
                    </PersistGate>
                </Provider>
            </ErrorBoundary>
        </React.StrictMode>
    );
};

export default App;
