import { REACT_APP_API_LOGGING_CONTEXT } from '../config/settings';

/**
 * Logger for pushing to logging API. Exports getLogger to create a single instance of the logger
 * with a default logHandler.
 *
 * While a default is provided, a custom logHandler can be set to format and log the data.
 */
class Logger {
    logUri: string | undefined;
    logHandler: any;
    constructor() {
        this.logUri = REACT_APP_API_LOGGING_CONTEXT;
    }

    static get TRACE() {
        return 'trace';
    }

    static get DEBUG() {
        return 'debug';
    }

    static get INFO() {
        return 'info';
    }

    static get WARN() {
        return 'warn';
    }

    static get ERROR() {
        return 'error';
    }

    static get CRITICAL() {
        return 'critical';
    }

    static get NONE() {
        return 'none';
    }

    getLogHandler() {
        return this.logHandler;
    }

    setLogHandler(handler: any) {
        this.logHandler = handler;
    }

    getLogUri() {
        return this.logUri;
    }

    setLogUri(uri: string | undefined) {
        this.logUri = uri;
    }

    /**
     * Clone and remove/alter request/response data for logging.
     */
    getLogDataFromRequestOrResponse = (data: any) => {
        const logData = { ...data };

        // Remove any transformers that are part of the request
        delete logData.transformRequest;
        delete logData.transformResponse;

        return logData;
    };

    /**
     * Log to configured transformer
     * @param {} logLevel
     * @param {*} logData contains data to be logged including url that caused the failure
     */
    log = (logLevel = Logger.ERROR, logData = { url: '', config: { url: '' } }) => {
        // If no logging endpoint specified or the endpoint is the actual logging endpoint, do not log
        // eslint-disable-next-line no-invalid-this
        const uri = this.getLogUri();

        if (!uri) {
            return;
        }

        if (logData.url === uri) {
            return;
        }

        if (logData.config && logData.config.url === uri) {
            return;
        }

        // eslint-disable-next-line no-invalid-this
        const handler = this.getLogHandler();

        if (!handler) {
            return;
        }

        handler(logLevel, logData, uri);
    };
}

const LoggerInstance = (() => {
    let instance: Logger;

    const createInstance = () => new Logger();

    return {
        getInstance: () => {
            if (!instance) {
                instance = createInstance();
            }

            return instance;
        },
    };
})();

export const getLogger = () => LoggerInstance.getInstance();
