import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NexusSelect } from '@nexus/react';

import { IRiskControl, IModalData } from 'constants/commonExportedInterfaces';
import EmptyTable from 'app/components/emptyTable/emptyTable';
import { truncate } from 'utils';
import { fields, columnDefs } from './controlTableConsts';
import CustomModal from 'app/components/customModal/customModal';
import ControlForm from './controlForm/controlForm';
import PaginationComponent from 'app/components/nds/pagination/pagination';
import ControlMappingBody from './controlMappingBody';
import ControlMappingHeaders from './controlMappingHeaders';
import './controlMappingTable.scss';

interface props {
    controls: IRiskControl[];
    riskCode: string;
    riskId: string;
    className?: string;
    currentPageSize?: number;
    pageSizeOptions?: number[];
    includeStatus?: boolean;
    enableEditControl?: boolean;
}

const formatControls = (controls: IRiskControl[], riskId: string) => {
    if (!!controls.length) {
        return controls.map((c) => ({
            ...c,
            controlDescription: truncate(c.controlDescription, 75),
            controlId: truncate(c.controlId, 6),
            controlTaxonomyLevel1: c.controlTaxonomyLevel1.name,
            controlTaxonomyLevel2: c.controlTaxonomyLevel2.name,
            controlTaxonomyLevel3: c.controlTaxonomyLevel3.name,
            riskId: truncate(riskId, 6),
        }));
    } else {
        return [];
    }
};

const ControlMappingTable: React.FC<props> = ({
    controls,
    riskId,
    currentPageSize,
    pageSizeOptions,
    riskCode,
    includeStatus,
    enableEditControl,
}) => {
    const [t] = useTranslation('lang');
    const buttons = JSON.parse(JSON.stringify(t('buttons')));
    const controlLabels = JSON.parse(JSON.stringify(t('control.labels')));

    const columns = !includeStatus ? columnDefs.filter((c) => c.field !== 'controlStatus') : [...columnDefs];

    const totalItems = controls.length;
    const [pageSize, setPageSize] = useState(currentPageSize || 10);
    const [sortColumn, setSortColumn] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    const [rows, setRows] = useState<any[]>(formatControls(controls.slice(0, pageSize), riskId));
    const [openControlDetailModal, setOpenControlDetailModal] = useState(false);
    const [openEditControlModal, setOpenEditControlModal] = useState(false);
    const [currentControl, setCurrentControl] = useState();
    const [controlForModal, setControlForModal] = useState<IModalData>({
        actionButton: '',
        doneButton: '',
        fields: [],
        title: '',
    });

    useEffect(() => {
        setRows(formatControls(controls.slice(0, pageSize), riskId));

        // If controls were updated and modal is being displayed, update the control displayed in the modal
        if (!controls.length || !controlForModal.fields.length) return;
        const newControlToFormat = controls.find((c) => c.controlId === controlForModal.fields[0].value);

        if (newControlToFormat === undefined) {
            setControlForModal(
                getFormattedControlForModal({ ...controls[0], controlId: truncate(controls[0].controlId, 6) }),
            );
        } else {
            setControlForModal(getFormattedControlForModal(newControlToFormat));
        }
    }, [controls]);

    useEffect(() => {
        let updatedRows: any[] = formatControls(
            controls.slice(pageSize * (currentPage - 1), pageSize * currentPage),
            riskId,
        );

        if (sortColumn) {
            const isDesc = sortDirection === 'des';
            const sortDesc = (a: any, b: any) => (a.toUpperCase() >= b.toUpperCase() ? -1 : 1);
            const sortAsc = (a: any, b: any) => (a.toUpperCase() >= b.toUpperCase() ? 1 : -1);
            setRows(
                updatedRows.sort((a, b) => {
                    return isDesc ? sortDesc(a[sortColumn], b[sortColumn]) : sortAsc(a[sortColumn], b[sortColumn]);
                }),
            );
        } else {
            setRows(updatedRows);
        }
    }, [sortColumn, sortDirection, pageSize, currentPage]);

    const getFormattedControlForModal = (controlRow: any) => {
        let control: any = controls.find((control) => truncate(control.controlId, 6) === controlRow.controlId);

        if (control === undefined) {
            control = controls.find((c) => c.controlId === controlForModal.fields[0].value);
        }

        setCurrentControl({ ...control, riskCode: riskCode });
        let formattedControl: IModalData = {
            actionButton: buttons.editControl,
            doneButton: buttons.done,
            fields: [
                { name: controlLabels.controlId, value: control.controlCode },
                { name: controlLabels.controlType, value: control.controlType },
                { name: controlLabels.controlOwner, value: control.controlOwner },
                { name: controlLabels.controlTaxonomyLevel1, value: control.controlTaxonomyLevel1.name },
                { name: controlLabels.controlTaxonomyLevel2, value: control.controlTaxonomyLevel2.name },
                { name: controlLabels.controlTaxonomyLevel3, value: control.controlTaxonomyLevel3.name },
                { name: controlLabels.controlOwnerAu, value: control.controlOwnerAu },
                { name: controlLabels.controlDescription, value: truncate(control.controlDescription, 400) },
            ],
            title: control.controlCode,
        };

        return formattedControl;
    };

    const onRowClick = (controlRow: IRiskControl) => {
        const formattedControlForModal = getFormattedControlForModal(controlRow);
        setControlForModal(formattedControlForModal);
        setOpenControlDetailModal(true);
    };

    const handleEditControl = () => {
        setOpenControlDetailModal(false);
        setOpenEditControlModal(true);
    };

    const handleCancelEditControl = () => {
        setOpenEditControlModal(false);
        setOpenControlDetailModal(true);
    };

    const handlePageSizeChange = (event: any) => {
        setPageSize(event.target.value);
        setCurrentPage(1);
    };

    if (!controls.length)
        return <EmptyTable columnTitles={fields} message='No controls to display for current risk.' />;

    return (
        <>
            {openControlDetailModal && controlForModal && (
                <CustomModal
                    handleAction={handleEditControl}
                    data={controlForModal}
                    openModal={openControlDetailModal}
                    setOpenModal={setOpenControlDetailModal}
                    enableEditControl={enableEditControl}
                />
            )}
            {openEditControlModal && (
                <ControlForm
                    isNewControl={false}
                    selectedControl={currentControl}
                    handleCloseControlForm={handleCancelEditControl}
                    showForm={openEditControlModal}
                />
            )}
            <div
                className='control-mapping-table-container'
                data-testid='control-mapping-table-container'
                style={{ overflowX: 'scroll' }}
            >
                <div style={{ width: '150%', minHeight: '270px' }}>
                    <table className='nexus-table control-mapping-table' style={{ marginBottom: 0 }}>
                        <ControlMappingHeaders
                            cols={columns}
                            sortColumn={sortColumn}
                            sortDirection={sortDirection}
                            setSortColumn={(col: string) => setSortColumn(col)}
                            setSortDirection={(dir: string) => setSortDirection(dir)}
                        />
                        <ControlMappingBody
                            rows={rows}
                            fields={columns.map((c) => c.field)}
                            handleRowSelect={onRowClick}
                        />
                    </table>
                </div>
            </div>
            <div className='pagination-container'>
                <div className='page-select-container'>
                    <span>Page:</span>
                    <NexusSelect
                        className='page-select'
                        data-testid='page-select'
                        value={pageSize.toString()}
                        onInput={handlePageSizeChange}
                    >
                        {pageSizeOptions?.map((o: number) => (
                            <option value={o} key={`page-select-option-${o}`}>
                                {o}
                            </option>
                        ))}
                    </NexusSelect>
                </div>
                <PaginationComponent
                    limit={pageSize}
                    size={totalItems}
                    offset={pageSize * (currentPage - 1)}
                    selectedPage={(page: any) => setCurrentPage(page)}
                />
            </div>
        </>
    );
};

export default ControlMappingTable;

ControlMappingTable.defaultProps = {
    currentPageSize: 5,
    enableEditControl: true,
    includeStatus: false,
    pageSizeOptions: [5, 10, 15],
};
