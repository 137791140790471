import { createSlice } from '@reduxjs/toolkit';

import * as ACTIONS from './addressTriggerActions';
import { IDismissTriggerBody, IRiskControl, ITriggerRisk } from 'constants/commonExportedInterfaces';
import { notifications, triggerStatusByRole } from 'constants/constants';

interface IAddressTriggerReducer {
    activeStep: string;
    addressedRisks: ITriggerRisk[] | [];
    assessmentUnitControls: IRiskControl[] | [];
    controlAssessmentResponse: IRiskControl | {};
    controlTaxonomies: Array<any>[];
    flaggedRisks: ITriggerRisk[] | [];
    lastActiveStep: string;
    loading: boolean;
    notification: string;
    outstandingRisks: ITriggerRisk[] | [];
    riskResponse: {
        risk: ITriggerRisk | null;
        error: string | null;
    };
    risksData: Array<ITriggerRisk>;
    riskImpacts: Array<any>[];
    riskLikelihoods: Array<any>[];
    riskTaxonomies: Array<any>[];
    riskProcesses: Array<any>[];
    riskRatingResponse: Array<any>[];
    triggerStatus: {
        Analyst: string;
        Reviewer: string;
    };
    userRole: string;
}

export const INITIAL_STATE_ADDRESS_TRIGGER: IAddressTriggerReducer = {
    activeStep: 'Flag risks',
    addressedRisks: [],
    assessmentUnitControls: [],
    controlAssessmentResponse: {},
    controlTaxonomies: [],
    flaggedRisks: [],
    lastActiveStep: '',
    loading: false,
    notification: '',
    outstandingRisks: [],
    riskImpacts: [],
    riskLikelihoods: [],
    riskProcesses: [],
    riskRatingResponse: [],
    riskResponse: {
        risk: null,
        error: null,
    },
    riskTaxonomies: [],
    risksData: [],
    triggerStatus: {
        Analyst: triggerStatusByRole.Analyst['Action Required'],
        Reviewer: triggerStatusByRole.Reviewer['Action Required'],
    },
    userRole: 'Analyst',
};

type AddressTriggerState = typeof INITIAL_STATE_ADDRESS_TRIGGER;

export const addressTriggerSlice = createSlice({
    extraReducers: (builder) =>
        builder
            .addCase(ACTIONS.updateActiveStep, (state, action) => {
                state.lastActiveStep = state.activeStep;
                state.activeStep = action.payload;
            })
            .addCase(ACTIONS.getRisksData.fulfilled, (state, action) => {
                state.risksData = action.payload?.data;
                state.loading = false;
            })
            .addCase(ACTIONS.clearRisksData, (state) => {
                state.risksData = [];
            })
            .addCase(ACTIONS.updateFlaggedRisks, (state, action) => {
                state.flaggedRisks = action.payload;
            })
            .addCase(ACTIONS.updateOutstandingRisks, (state, action) => {
                state.outstandingRisks = action.payload;
            })
            .addCase(ACTIONS.addNewRisk.fulfilled, (state, action) => {
                state.riskResponse = {
                    risk: action.payload?.data,
                    error: null,
                };
                state.addressedRisks = [...state.addressedRisks, action.payload?.data];
            })
            .addCase(ACTIONS.addNewRisk.rejected, (state, action) => {
                state.riskResponse = {
                    risk: null,
                    error: `Error: ${action.payload}`,
                };
            })
            .addCase(ACTIONS.editRisk.fulfilled, (state, action) => {
                let risk: ITriggerRisk = action.payload?.data;
                state.riskResponse = {
                    risk,
                    error: null,
                };
                state.outstandingRisks = state.outstandingRisks.filter((r) => r.riskId !== risk.riskId);
                state.addressedRisks = [...state.addressedRisks, risk];
                state.flaggedRisks = state.flaggedRisks.map((r) => (r.riskId === risk.riskId ? risk : r));
                state.risksData = state.risksData.map((r) => (r.riskId === risk.riskId ? risk : r));
            })
            .addCase(ACTIONS.editRisk.rejected, (state, action) => {
                state.riskResponse = {
                    risk: null,
                    error: `Error: ${action.payload}`,
                };
            })
            .addCase(ACTIONS.addressRisk, (state, action) => {
                let risk = action.payload;
                state.outstandingRisks = state.outstandingRisks.filter((r) => r.riskId !== risk.riskId);
                // Check if addressed risk is not in the array already
                const idx = state.addressedRisks.findIndex((r) => r.riskId === risk.riskId);
                state.addressedRisks = idx === -1 ? [...state.addressedRisks, risk] : state.addressedRisks;
            })
            .addCase(ACTIONS.clearAddressedRisks, (state) => {
                state.addressedRisks = [];
            })
            .addCase(ACTIONS.getRiskTaxonomies.fulfilled, (state, action) => {
                state.riskTaxonomies = action.payload?.data;
            })
            .addCase(ACTIONS.getRiskImpacts.fulfilled, (state, action) => {
                state.riskImpacts = action.payload?.data;
            })
            .addCase(ACTIONS.getRiskLikelihoods.fulfilled, (state, action) => {
                state.riskLikelihoods = action.payload?.data;
            })
            .addCase(ACTIONS.getProcesses.fulfilled, (state, action) => {
                state.riskProcesses = action.payload?.data;
            })
            .addCase(ACTIONS.getControlTaxonomies.fulfilled, (state, action) => {
                state.controlTaxonomies = action.payload?.data;
                state.loading = false;
            })
            .addCase(ACTIONS.getControlTaxonomies.pending, (state) => {
                state.loading = true;
            })
            .addCase(ACTIONS.getControlAssessment.fulfilled, (state, action) => {
                state.controlAssessmentResponse = action.payload?.data;
                state.loading = false;
            })
            .addCase(ACTIONS.getControlAssessment.pending, (state) => {
                state.loading = true;
            })
            .addCase(ACTIONS.getRiskRating.fulfilled, (state, action) => {
                state.riskRatingResponse = action.payload?.data;
            })
            .addCase(ACTIONS.getAssessmentUnitControls.fulfilled, (state, action) => {
                state.assessmentUnitControls = action.payload?.data;
            })
            .addCase(ACTIONS.mapControlsToRisk.fulfilled, (state, action) => {
                const risk: ITriggerRisk = action.payload?.data;
                state.outstandingRisks = state.outstandingRisks.map((r) => {
                    return r.riskId !== risk.riskId ? r : risk;
                });
            })
            .addCase(ACTIONS.editControl.fulfilled, (state, action) => {
                const updatedControl: IRiskControl = action.payload?.data;
                state.assessmentUnitControls = state.assessmentUnitControls.map((c) => {
                    return c.controlId !== updatedControl.controlId ? c : updatedControl;
                });

                const updatedOutstandingRisks: ITriggerRisk[] = state.outstandingRisks.map((r) => {
                    return {
                        ...r,
                        controls: r.controls.map((c) => {
                            return c.controlId !== updatedControl.controlId ? c : updatedControl;
                        }),
                    };
                });
                state.outstandingRisks = updatedOutstandingRisks;

                state.notification = notifications.controlEdited;
            })
            .addCase(ACTIONS.addNewControl.fulfilled, (state, action) => {
                state.assessmentUnitControls = [...state.assessmentUnitControls, action.payload?.data];
                state.notification = notifications.controlAdded;
            })
            .addCase(ACTIONS.clearNotification, (state) => {
                state.notification = '';
            })
            .addCase(ACTIONS.clearControlAssessmentData, (state) => {
                state.controlAssessmentResponse = {};
            })
            .addCase(ACTIONS.updateUserRole, (state, action) => {
                state.userRole = action.payload;
            })
            .addCase(ACTIONS.submitTrigger, (state) => {
                state.triggerStatus.Analyst = triggerStatusByRole.Analyst.Submitted;
            })
            .addCase(ACTIONS.updateTriggerStatus.fulfilled, (state, action) => {
                const trigger: IDismissTriggerBody = action.payload?.data;
                state.notification = notifications[trigger.triggerStatus as keyof typeof notifications];
                state.triggerStatus[state.userRole as keyof typeof state.triggerStatus] = trigger.triggerStatus;
            }),
    initialState: INITIAL_STATE_ADDRESS_TRIGGER as AddressTriggerState,
    name: 'addressTriggerData',
    reducers: {},
});

export default addressTriggerSlice.reducer;
