import './authContainer.scss';
import React from 'react';
import { AuthProvider } from '../../../auth';
import store from '../../../store';
import Header from '../../components/nds/header/header';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

type Props = {
    children?: React.ReactNode;
};

const AuthContainer = ({ children }: Props) => {
    const [t] = useTranslation('lang');
    const menu = JSON.parse(JSON.stringify(t('global.menu')));
    return (
        <AuthProvider store={store}>
            <Header home={'/dashboard'} menuItems={menu}></Header>
            <div className='background-display'>{children}</div>
        </AuthProvider>
    );
};

AuthContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AuthContainer;
