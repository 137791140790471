import { createAsyncThunk } from '@reduxjs/toolkit';

import actionConst from './controlsConst';
import { get } from 'utils';
import { assessmentUnitsUrl } from 'constants/apiConstants';

export const getControlsDataByAU = createAsyncThunk(
    actionConst.controlsData,
    async (assessmentUnitId: string, { rejectWithValue }) => {
        try {
            const response = await get({
                baseURL: `${assessmentUnitsUrl}/${assessmentUnitId}/controls`,
            });

            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    },
);
