import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NexusIcon, NexusModal, NexusModalBody, NexusModalHeader, NexusModalHeaderSubtitle } from '@nexus/react';
import IcExitToApp24px from '@nexus/core/dist/assets/icons/action/ic_exit_to_app_24px.svg';

import { useAppDispatch, useAppSelector } from 'store';
import riskRatingImg from '../../../../../assets/images/risk-rating.png';
import {
    emptyRiskFormData,
    formName,
    longInputSize,
    mediumInputs,
    mediumInputSize,
    riskLevelSelects,
    riskRatingSelects,
    shortInputs,
    shortInputSize,
    validateRisk,
} from './riskFormConsts';
import RiskFormSelect from './riskFormSelect';
import './riskForm.scss';
import { getRiskRating } from '../../redux/addressTriggerActions';
import FormTextarea from 'app/components/formTextarea/formTextarea';
import FormInput from 'app/components/formInput/formInput';
import CustomChip from 'app/components/customChip/customChip';

interface props {
    activeRisk: any;
    isActiveRiskAddressedRisk: boolean;
    isNewRisk: boolean;
    handleSubmitAction?: (args: any) => void;
    setModified?: (args: any) => void;
}

let riskCalObj: any = {
    riskImpact: {
        id: '',
        impactValue: '',
        name: '',
    },
    riskLikelihood: {
        id: '',
        likelihoodValue: '',
        name: '',
    },
};

const chipPalette = {
    'Low': '#59D159',
    'Moderate-High': '#19191A',
    'Moderate': '#FFD240',
    'Severe': '#DA3510',
    'Significant': '#FF8733',
};

const RiskForm: React.FC<props> = ({
    activeRisk,
    isNewRisk,
    isActiveRiskAddressedRisk,
    setModified,
    handleSubmitAction,
}) => {
    const [t] = useTranslation('lang');
    const buttons = JSON.parse(JSON.stringify(t('buttons')));
    const riskLabels = JSON.parse(JSON.stringify(t('risk.labels')));
    const [formData, setFormData] = useState(isNewRisk ? emptyRiskFormData : activeRisk);
    const [showRiskRatingModal, setShowRiskRatingModal] = useState(false);

    const dispatch = useAppDispatch();

    // Fetching taxanomies, impacts, likelihood and riskProcesses data.
    const { riskImpacts, riskLikelihoods, riskTaxonomies, riskProcesses, riskRatingResponse } = useAppSelector(
        (state) => state.addressTriggerData,
    );

    const handleSubmit = (e: any) => {
        e.preventDefault();
        handleSubmitAction && handleSubmitAction({ isNewRisk, risk: formData });
    };

    const handleInputChange = (target: any) => {
        setModified && setModified(true);
        // calculating risk rating calculation value
        if (target.name === 'riskImpact') {
            riskCalObj = {
                riskImpact: {
                    id: target.value.id,
                    impactValue: null,
                    name: null,
                },
                riskLikelihood: {
                    id: formData.riskLikelihood.id,
                    likelihoodValue: null,
                    name: null,
                },
            };
            dispatch(getRiskRating(riskCalObj));
        }
        if (target.name === 'riskLikelihood') {
            riskCalObj = {
                riskImpact: {
                    id: formData.riskImpact.id,
                    impactValue: null,
                    name: null,
                },
                riskLikelihood: {
                    id: target.value.id,
                    likelihoodValue: null,
                    name: null,
                },
            };
            dispatch(getRiskRating(riskCalObj));
        }
        setFormData({
            ...formData,
            [target.name]: target.value,
        });
    };

    useEffect(() => {
        setFormData(activeRisk);
    }, [activeRisk]);

    useEffect(() => {
        setFormData(isNewRisk ? emptyRiskFormData : activeRisk);
    }, [isNewRisk]);

    useEffect(() => {
        riskCalObj = {
            riskImpact: {
                id: formData.riskImpact.id,
                impactValue: null,
                name: null,
            },
            riskLikelihood: {
                id: formData.riskLikelihood.id,
                likelihoodValue: null,
                name: null,
            },
        };
        dispatch(getRiskRating(riskCalObj));
    }, [formData.riskImpact.id, formData.riskLikelihood.id]);

    return (
        <>
            <form
                className='risk-form-container'
                data-testid='risk-form-container'
                onSubmit={handleSubmit}
                autoComplete='false'
            >
                {!isNewRisk && (
                    <div data-testid='risk-id-container' className='nexus-row nexus-mb-2'>
                        <div className='nexus-col-lg-12 nexus-col-md-8 nexus-col-xs-4 risk-id'>
                            {`${riskLabels.riskId.toUpperCase()} - ${formData.riskCode}`}
                        </div>
                    </div>
                )}
                <div className='nexus-row'>
                    {isNewRisk && (
                        <FormInput
                            colLgSize={longInputSize}
                            formName={formName}
                            handleInputChange={handleInputChange}
                            testid={'name'}
                            inputName={'riskName'}
                            inputValue={formData.riskName}
                            label={riskLabels.riskName}
                        />
                    )}
                    {shortInputs.map((shortInput) => (
                        <FormInput
                            colLgSize={shortInputSize}
                            formName={formName}
                            handleInputChange={handleInputChange}
                            testid={shortInput}
                            inputName={shortInput}
                            inputValue={formData[shortInput]}
                            label={riskLabels[shortInput]}
                        />
                    ))}
                </div>
                <div className='risk-form-divider'></div>
                <div className='nexus-row'>
                    <div className='nexus-col-lg-4 nexus-col-md-8 nexus-col-xs-4'>
                        {riskLevelSelects.map((riskLevelSelect, riskIdx) => (
                            <RiskFormSelect
                                key={`risk-level-select-${riskLevelSelect}`}
                                fieldName={riskLevelSelect}
                                fieldValue={formData[riskLevelSelect]}
                                handleInputChange={handleInputChange}
                                valueId={'id'}
                                isNewRisk={isNewRisk}
                                label={riskLabels[riskLevelSelect]}
                                options={riskTaxonomies.filter((item: any) => item.level === riskIdx + 1)}
                            />
                        ))}
                    </div>
                    <div className='nexus-col-lg-8 nexus-col-md-8 nexus-col-xs-4 risk-form-textarea-container'>
                        <FormTextarea
                            cols={72}
                            fieldName={'riskStatement'}
                            fieldValue={formData.riskStatement}
                            formName={'risk'}
                            handleInputChange={handleInputChange}
                            label={riskLabels.riskStatement}
                            rows={6}
                        />
                    </div>
                </div>
                <div className='risk-form-divider'></div>
                <div className='nexus-row'>
                    <div className='nexus-col-lg-12 nexus-col-md-8 nexus-col-xs-4'>
                        <p className='risk-form-section'>{riskLabels.riskProcesses}</p>
                    </div>
                    <div className='nexus-col-lg-6 nexus-col-md-8 nexus-col-xs-4'>
                        <RiskFormSelect
                            fieldName={'riskProcess'}
                            fieldValue={formData.riskProcess}
                            handleInputChange={handleInputChange}
                            valueId={'processId'}
                            isNewRisk={isNewRisk}
                            label={riskLabels.riskProcessName}
                            options={riskProcesses}
                        />
                    </div>
                    {mediumInputs.map((mediumInput) => (
                        <FormInput
                            colLgSize={mediumInputSize}
                            formName={formName}
                            handleInputChange={handleInputChange}
                            inputName={mediumInput}
                            inputValue={formData[mediumInput]}
                            label={riskLabels[mediumInput]}
                            required={false}
                            testid={mediumInput}
                        />
                    ))}
                </div>
                <div className='risk-form-divider'></div>
                <div className='nexus-row'>
                    <div className='nexus-col-lg-12 nexus-col-md-8 nexus-col-xs-4'>
                        <p className='risk-form-section'>{riskLabels.riskRating}</p>
                    </div>
                    <div className='nexus-col-lg-6 nexus-col-md-8 nexus-col-xs-4'>
                        <div className='nexus-row'>
                            {riskRatingSelects.map((riskRatingSelect) => (
                                <div
                                    className='nexus-col-lg-6 nexus-col-md-8 nexus-col-xs-4'
                                    key={`risk-rating-select-${riskRatingSelect}`}
                                >
                                    <RiskFormSelect
                                        fieldName={riskRatingSelect}
                                        fieldValue={formData[riskRatingSelect]}
                                        handleInputChange={handleInputChange}
                                        valueId={'id'}
                                        isNewRisk={isNewRisk}
                                        label={riskLabels[riskRatingSelect]}
                                        options={riskRatingSelect === 'riskImpact' ? riskImpacts : riskLikelihoods}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='nexus-col-lg-6 nexus-col-md-8 nexus-col-xs-4'>
                        <div className='nexus-row'>
                            <div className='nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-4'>
                                <div className='risk-rating-chip-container'>
                                    <label className='risk-rating-label'>{riskLabels.riskRating}</label>
                                    <CustomChip
                                        chipPalette={chipPalette}
                                        defaultColor={'#19191A'}
                                        field={riskRatingResponse && riskRatingResponse.name}
                                        loadingLegend={'calculating'}
                                    />
                                </div>
                            </div>
                            <div className='nexus-col-lg-6 nexus-col-md-4 nexus-col-xs-4'>
                                <div className='risk-rating-view-modal-action'>
                                    <span onClick={() => setShowRiskRatingModal(true)}>
                                        <NexusIcon data-testid='icon-size-md' src={IcExitToApp24px} size='md' />
                                        {riskLabels.viewRiskRatingMatrix}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='risk-form-divider'></div>
                <div className='nexus-column'>
                    <div className='nexus-col-lg-12 nexus-col-md-8 nexus-col-xs-4 risk-form-textarea-container'>
                        <FormTextarea
                            cols={112}
                            fieldName={'riskRationale'}
                            fieldValue={formData.riskRationale}
                            formName={'risk'}
                            handleInputChange={handleInputChange}
                            label={riskLabels.riskRationale}
                            rows={4}
                        />
                    </div>
                    <div className='risk-form-actions'>
                        <button
                            data-testid='cancel-button'
                            type='button'
                            className='nexus-btn'
                            disabled={isActiveRiskAddressedRisk && !isNewRisk}
                        >
                            {buttons.cancel}
                        </button>
                        <button
                            data-testid='save-button'
                            type='submit'
                            className='nexus-btn-primary'
                            disabled={(isActiveRiskAddressedRisk && !isNewRisk) || validateRisk(formData)}
                        >
                            {buttons.save}
                        </button>
                    </div>
                </div>
            </form>
            <NexusModal data-testid='overlay' id='modal' show={showRiskRatingModal} size='lg'>
                <NexusModalHeader data-testid='modal-header' onCloseEvent={() => setShowRiskRatingModal(false)}>
                    <NexusModalHeaderSubtitle data-testid='modal-placeholder'>
                        {riskLabels.viewRiskRatingMatrix}
                    </NexusModalHeaderSubtitle>
                </NexusModalHeader>
                <NexusModalBody>
                    <img src={riskRatingImg} alt='' />
                </NexusModalBody>
            </NexusModal>
        </>
    );
};

export default RiskForm;
