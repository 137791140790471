import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { NexusModal } from '@nexus/react';
import ButtonComponent from 'app/components/nds/button/button';
import { truncate } from 'utils';
import './fullTriggerModal.scss';

interface props {
    openModal: boolean;
    setOpenModal: Dispatch<SetStateAction<boolean>>;
    trigger: any;
}

const FullTriggerModal: React.FC<props> = ({ trigger, setOpenModal, openModal }) => {
    const [t] = useTranslation('lang');
    const buttons = JSON.parse(JSON.stringify(t('buttons')));
    const triggerLabels = JSON.parse(JSON.stringify(t('trigger.labels')));

    const fieldHandler = (text: string, label: string) => {
        return text || `No ${label} found.`;
    };

    return (
        <NexusModal show={openModal} size={'md'} data-testid='trigger-details-modal'>
            <div className='trigger-details-container' data-testid='trigger-details-container'>
                <div className='trigger-header'>
                    <div className='trigger-title-status'>
                        <div className='trigger-title'>{trigger.triggerName}</div>
                        <div className='trigger-status-chip' style={{ padding: '1em' }}>
                            {trigger.triggerStatus}
                        </div>
                    </div>
                    <div className='trigger-date'>
                        <strong>{triggerLabels.due}</strong>
                        {new Date(trigger.triggerDueDate).toLocaleDateString() || `${triggerLabels.unknown}`}
                    </div>
                </div>
                <br />
                <div className='trigger-description-container'>
                    <div className='trigger-description-title'>{triggerLabels.description}</div>
                    <div className='trigger-description'>
                        {fieldHandler(trigger.triggerDescription, `${triggerLabels.description}`)}
                    </div>
                </div>
                <br />
                <div className='trigger-source-container'>
                    <div className='trigger-source-title'>{triggerLabels.source}</div>
                    <div className='trigger-source'>
                        {fieldHandler(trigger.triggerSource, `${triggerLabels.source}`)}
                    </div>
                </div>
                <br />
                <div className='trigger-obligation-details-container'>
                    <div className='trigger-obligation-id-container'>
                        <div className='trigger-obligation-id-title'>{triggerLabels.obligationId}</div>
                        <div className='trigger-obligation-id'>
                            {fieldHandler(
                                trigger.triggerSubjects[0].subjectIndex.toString(),
                                `${triggerLabels.obligationId}`,
                            )}
                        </div>
                    </div>
                    <div className='trigger-obligation-description-container'>
                        <div className='trigger-obligation-description-title'>
                            {triggerLabels.obligationDescriptionPreview}
                        </div>
                        <div className='trigger-obligation-description'>
                            {fieldHandler(
                                truncate(trigger.triggerSubjects[0].subjectDescription, 400),
                                `${triggerLabels.obligationDescriptionPreview}`,
                            )}
                        </div>
                    </div>
                </div>
                <div className='trigger-action'>
                    <ButtonComponent label={buttons.done} type={['nexus-btn']} click={() => setOpenModal(false)} />
                </div>
            </div>
        </NexusModal>
    );
};

export default FullTriggerModal;
