import React from 'react';
import { NexusHeader, NexusHeaderMenu, NexusMenuItem, NexusHeaderLogo, NexusIcon } from '@nexus/react';
import Logo from '@nexus/core/dist/assets/images/nexus-logo.svg';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './header.scss';
import { retrieveUserName } from '../../../../auth';
import DropdownComponent from './dropdown/dropdown';

interface menuItem {
    icon?: string;
    link: string;
    title: string;
}
interface Props {
    home: string;
    menuItems: menuItem[];
}
const HeaderComponent: React.FC<Props> = ({ home, menuItems }) => {
    const path = useLocation();
    const fullName = useSelector(retrieveUserName);

    const closeMenu = () => {
        const closeBtn = document.getElementsByTagName('nexus-menu')[0]?.getElementsByTagName('nexus-icon');
        return closeBtn && closeBtn.length > 0 ? closeBtn[0].click() : null;
    };

    return (
        <NexusHeader class='nexus-header-sticky nexus-header hydrated' data-testid='nexus-header'>
            <NexusHeaderLogo className='display-inline'>
                <Link to={home}>
                    <NexusIcon src={Logo} />
                    <span className='nexus-visually-hidden'></span>
                </Link>
                <div className='nexus-padding-2 separator'>Trigger Based Risk Assessment</div>
            </NexusHeaderLogo>
            <NexusHeaderMenu data-testid='nexus-header-menu'>
                {menuItems.map((menuItem, idx) =>
                    menuItem.icon ? (
                        <NexusMenuItem key={idx} data-testid={menuItem.title} onClick={closeMenu}>
                            <Link to={menuItem.link}>
                                <NexusIcon src={menuItem.icon}>{menuItem.title}</NexusIcon>
                            </Link>
                        </NexusMenuItem>
                    ) : (
                        <NexusMenuItem
                            className={`${menuItem.link}` === path.pathname ? 'menu-item-highlighter' : ''}
                            key={idx}
                            data-testid={menuItem.title}
                            onClick={closeMenu}
                        >
                            <Link to={menuItem.link}>{menuItem.title}</Link>
                        </NexusMenuItem>
                    ),
                )}
                <NexusMenuItem>
                    <div className='nexus-mt-2 user-profile-flyout'>
                        <DropdownComponent dropDownLabel={fullName} />
                    </div>
                </NexusMenuItem>
            </NexusHeaderMenu>
        </NexusHeader>
    );
};

export default HeaderComponent;
