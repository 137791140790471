import React from 'react';
import { NexusIcon } from '@nexus/react';
import './404.scss';
import Badmood from '@nexus/core/dist/assets/icons/social/ic_mood_bad_24px.svg';

const PageNotFound = () => (
    <div data-testid='test-id-404' className='page-not-found nexus-center-md'>
        <NexusIcon src={Badmood} className='icon-color'></NexusIcon>
        <h1 className='nexus-h1 font-color'>404</h1>
        <h3 className='nexus-h3 message'>Page not found.</h3>
        <h3 className='nexus-h3 message'>The page you are looking for doesn't exists or some other error occured.</h3>
    </div>
);

export default PageNotFound;
