import { createAsyncThunk } from '@reduxjs/toolkit';

import actionConst from './triggersConst';
import { get, patch } from 'utils/fetch';
import { assessmentUnitsUrl, triggersUrl } from 'constants/apiConstants';
import { IDismissTriggerBody } from 'constants/commonExportedInterfaces';

export const getTriggersData = createAsyncThunk(
    actionConst.triggersData,
    async (assessmentUnitId: string, { rejectWithValue }) => {
        try {
            const response = await get({
                baseURL: `${assessmentUnitsUrl}/${assessmentUnitId}/triggers`,
            });

            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    },
);

export const getTriggerById = createAsyncThunk(
    actionConst.triggerById,
    async (triggerId: string, { rejectWithValue }) => {
        try {
            const response = await get({
                baseURL: `${triggersUrl}/${triggerId}`,
            });

            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    },
);

export const dismissTrigger = createAsyncThunk(
    actionConst.dismissTrigger,
    async (trigger: IDismissTriggerBody, { rejectWithValue }) => {
        try {
            const response = await patch({
                baseURL: `${triggersUrl}/${trigger.triggerId}`,
                data: trigger,
            });

            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    },
);
