import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { NexusCard } from '@nexus/react';

import ButtonComponent from 'app/components/nds/button/button';
import { IAssessmentUnitTrigger } from 'constants/commonExportedInterfaces';
import FullTriggerModal from './fullTriggerModal';
import './triggerSummary.scss';
import CustomChip from '../customChip/customChip';

interface props {
    activeStep: string;
    trigger: IAssessmentUnitTrigger;
    triggerStatus: string;
}

const chipPalette = {
    'Action Required': '#ed6500',
    'Approved': '#59D159',
    'Reject': '#DA3510',
    'Submitted': '#59D159',
};

const TriggerSummary: React.FC<props> = ({ activeStep, trigger, triggerStatus }) => {
    const [t] = useTranslation('lang');
    const buttons = JSON.parse(JSON.stringify(t('buttons')));
    const triggerLabels = JSON.parse(JSON.stringify(t('trigger.labels')));
    const [openModal, setOpenModal] = useState(false);

    return (
        <>
            {openModal && trigger && (
                <FullTriggerModal trigger={trigger} setOpenModal={setOpenModal} openModal={openModal} />
            )}
            {activeStep === 'Confirmation' && (
                <div className='trigger-name-status-container'>
                    <div className='trigger-name'>{trigger.triggerName}</div>
                    <CustomChip chipPalette={chipPalette} defaultColor={'#444'} field={triggerStatus} />
                </div>
            )}
            <NexusCard data-testid='trigger-summary' className='trigger-summary-container'>
                <div className='nexus-row'>
                    {activeStep !== 'Confirmation' && (
                        <div className='nexus-col-xs-4 nexus-col-lg-3'>
                            <h4>{triggerLabels.triggerName}</h4>
                            <p>{trigger.triggerName}</p>
                        </div>
                    )}
                    <div className='nexus-col-xs-4 nexus-col-lg-5'>
                        <h4>{triggerLabels.triggerDescription}</h4>
                        <p>{trigger.triggerDescription}</p>
                    </div>
                    <div className={`nexus-col-xs-4 nexus-col-lg-${activeStep === 'Confirmation' ? 5 : 2}`}>
                        <h4>{triggerLabels.source}</h4>
                        <p>{trigger.triggerSource}</p>
                    </div>
                    <div className='nexus-col-xs-4 nexus-col-md-4 nexus-col-lg-2 nexus-end-lg nexus-start-md nexus-start-xs action-container'>
                        <ButtonComponent
                            label={buttons.viewFullTrigger}
                            type={['nexus-btn-primary nexus-btn-large action-button']}
                            click={() => setOpenModal(true)}
                        />
                    </div>
                </div>
            </NexusCard>
        </>
    );
};

export default TriggerSummary;
