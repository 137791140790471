import { NexusModal, NexusToast } from '@nexus/react';
import ButtonComponent from 'app/components/nds/button/button';
import { IField, IModalData } from 'constants/commonExportedInterfaces';
import { Dispatch, SetStateAction, useEffect } from 'react';

import './customModal.scss';
import ModalRowContainer from './modalRowContainer';
import { useAppDispatch, useAppSelector } from 'store';
import { clearNotification } from 'app/views/assessmentUnit/addressTrigger/redux/addressTriggerActions';

interface props {
    handleAction?: () => void;
    openModal: boolean;
    data: IModalData;
    setOpenModal: Dispatch<SetStateAction<boolean>>;
    enableEditControl?: boolean;
}

const CustomModal: React.FC<props> = ({ handleAction, openModal, data, setOpenModal, enableEditControl = true }) => {
    const allowedCharactersForFieldName = 20;
    function getFieldsLines(dataFields: any) {
        const entireRowsAllowed = 2;
        const fieldsPerRow = 3;
        let modalRows = [];
        let fieldCount = 0;
        for (let i = 0; i < entireRowsAllowed; i++) {
            let modalRow = [];
            for (let j = 0; j < fieldsPerRow; j++) {
                modalRow.push({ ...dataFields[fieldCount], className: 'short-field' });
                fieldCount += 1;
            }
            modalRows.push(modalRow);
        }

        return modalRows;
    }

    const { notification } = useAppSelector((state) => state.addressTriggerData);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (!!notification.length) {
            setTimeout(() => {
                dispatch(clearNotification());
            }, 3000);
        }
    }, [notification]);

    const modalRows = getFieldsLines(data.fields);
    const isLongField =
        data.fields[6].name.length > allowedCharactersForFieldName ||
        data.fields[7].name.length > allowedCharactersForFieldName;
    const lastLines: IField[] = [
        { ...data.fields[6], className: isLongField ? 'long-field' : 'short-field' },
        {
            ...data.fields[7],
            className: isLongField ? 'last-long-field' : 'last-short-field',
        },
    ];

    return (
        <NexusModal show={openModal} size={'md'} data-testid='data-modal-container'>
            <div className='data-detail-container' data-testid='data-detail-container'>
                {!!notification.length && (
                    <NexusToast variant='success' autoClose={3000} position='top' class='toast-notification'>
                        {notification}
                    </NexusToast>
                )}
                <div className='data-title'>{data.title}</div>
                <br />
                {modalRows.map((modalRow, rowidx) => (
                    <ModalRowContainer key={`row-${rowidx}`} modalRow={modalRow} />
                ))}
                <ModalRowContainer modalRow={lastLines} isLongField={isLongField} />
                <div className='modal-actions'>
                    {enableEditControl && data.actionButton && handleAction && (
                        <ButtonComponent label={data.actionButton} type={['nexus-btn']} click={() => handleAction()} />
                    )}
                    <ButtonComponent
                        label={data.doneButton}
                        type={['nexus-btn nexus-btn-primary']}
                        click={() => setOpenModal(false)}
                    />
                </div>
            </div>
        </NexusModal>
    );
};

export default CustomModal;
