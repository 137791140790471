import Flag from '@nexus/core/dist/assets/icons/content/ic_flag_24px.svg';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NexusToast } from '@nexus/react';

import CustomModal from 'app/components/customModal/customModal';
import LoaderComponent from 'app/components/nds/loader/loader';
import { IModalData, ITriggerRisk } from 'constants/commonExportedInterfaces';
import { useAppDispatch, useAppSelector } from 'store';
import ControlMappingTable from '../controlMapping/controlMappingTable';
import AddressTriggerStepProcess from '../addressTriggerStepProcess/addressTriggerStepProcess';
import { clearNotification, submitTrigger, updateTriggerStatus } from '../redux/addressTriggerActions';
import { triggerStatusByRole } from 'constants/constants';
import './confirmation.scss';
import ReasonModal from 'app/components/reasonModal/reasonModal';

interface ITriggerStatus {
    Analyst: string;
    Reviewer: string;
}

const chipPalette = {
    Low: '#59D159',
    Moderate: '#E6B000',
};

const Confirmation: React.FC<{ handleStepClick: (step: string) => void; triggerId: string }> = ({ triggerId }) => {
    const [t] = useTranslation('lang');
    const buttons = JSON.parse(JSON.stringify(t('buttons')));
    const menuLabels = JSON.parse(JSON.stringify(t('verticalMenu.labels')));
    const riskLabels = JSON.parse(JSON.stringify(t('risk.labels')));
    const triggerLabels = JSON.parse(JSON.stringify(t('trigger.labels')));
    const dispatch = useAppDispatch();

    const outstandingRisks: ITriggerRisk[] = useAppSelector((state) => state.addressTriggerData.outstandingRisks);
    const userRole: string = useAppSelector((state) => state.addressTriggerData.userRole);
    const notification: string = useAppSelector((state) => state.addressTriggerData.notification);
    const triggerStatus: ITriggerStatus = useAppSelector((state) => state.addressTriggerData.triggerStatus);
    const isAnalyst = userRole === 'Analyst';

    const [activeRisk, setActiveRisk] = useState<ITriggerRisk>({ ...outstandingRisks[0] });
    const [openModal, setOpenModal] = useState(false);
    const [openRejectionModal, setOpenRejectionModal] = useState(false);

    const updateActiveRisk = (riskId: string) => {
        const newActive = outstandingRisks.find((r: ITriggerRisk) => r.riskId === riskId);
        setActiveRisk(newActive!);
    };

    useEffect(() => {
        if (!!notification.length) {
            setTimeout(() => {
                dispatch(clearNotification());
            }, 3000);
        }
    }, [notification]);

    if (!outstandingRisks) return <LoaderComponent show={!outstandingRisks} />;

    const getFormattedRiskForModal = (risk: ITriggerRisk) => {
        let formattedRisk: IModalData = {
            doneButton: buttons.done,
            fields: [
                { name: riskLabels.riskOwner, value: risk.riskOwner },
                { name: riskLabels.riskOwnerDivision, value: risk.riskOwnerDivision },
                { name: riskLabels.riskRegion, value: risk.riskRegion },
                { name: riskLabels.riskLevel1, value: risk.riskLevel1.name },
                { name: riskLabels.riskLevel2, value: risk.riskLevel2.name },
                { name: riskLabels.riskLevel3, value: risk.riskLevel3.name },
                { name: riskLabels.riskProcessName, value: risk.riskProcess.processName },
                { name: riskLabels.riskStatement, value: risk.riskStatement },
            ],
            title: risk.riskName,
        };

        return formattedRisk;
    };

    const formatMenuItems = (risks: ITriggerRisk[]) => {
        return risks.map((risk: ITriggerRisk) => {
            return {
                chipText: risk.riskStatus,
                id: risk.riskId,
                name: risk.riskName,
            };
        });
    };

    const verticalMenuItems = [
        { isOpen: true, item: menuLabels.flaggedRisks, subItems: formatMenuItems(outstandingRisks) },
    ];

    const handleRejectionModalAction = (submit: boolean, reason?: string) => {
        if (submit) {
            dispatch(
                updateTriggerStatus({
                    triggerId,
                    triggerRationale: reason || '',
                    triggerStatus: triggerStatusByRole.Reviewer.Reject,
                }),
            );
        }
        setOpenRejectionModal(false);
    };

    const getUserActions = () => {
        if (isAnalyst) {
            return {
                firstAction: () => {},
                secondAction: () => dispatch(submitTrigger()),
            };
        } else {
            return {
                firstAction: () => setOpenRejectionModal(true),
                secondAction: () =>
                    dispatch(
                        updateTriggerStatus({
                            triggerId,
                            triggerRationale: '',
                            triggerStatus: triggerStatusByRole.Reviewer.Approved,
                        }),
                    ),
            };
        }
    };

    const analystActionRequired = triggerStatus.Analyst === triggerStatusByRole.Analyst['Action Required'];
    const reviewerActionRequired =
        triggerStatus.Reviewer === triggerStatusByRole.Reviewer['Action Required'] && !analystActionRequired;

    const stepActionsProps = {
        actionButton1Action: getUserActions().firstAction,
        actionButton1Disabled: isAnalyst ? false : !reviewerActionRequired,
        actionButton1Label: isAnalyst ? buttons.save : buttons.reject,
        actionButton2Action: getUserActions().secondAction,
        actionButton2Disabled: isAnalyst ? !analystActionRequired : !reviewerActionRequired,
        actionButton2Label: isAnalyst ? buttons.submit : buttons.approve,
        nextStep: 'Submit confirmation',
    };

    const stepDetail = (
        <ControlMappingTable
            controls={activeRisk.controls}
            riskId={activeRisk.riskId}
            riskCode={activeRisk.riskCode}
            includeStatus
        />
    );

    const stepSummary = {
        activeRisk: activeRisk,
        setOpenModal: setOpenModal,
        showRiskStatus: true,
    };

    const verticalMenuProps = {
        activeSubItem: activeRisk.riskId,
        chipPalette: chipPalette,
        items: verticalMenuItems,
        setIsNewSubItem: () => {},
        setSelectedSubItem: updateActiveRisk as any,
        title: menuLabels.flaggedRisks,
        titleIcon: Flag,
    };

    return (
        <div className='confirmation-step' data-testid='confirmation-step'>
            {!!notification.length && (
                <NexusToast variant='success' autoClose={3000} position='top' class='toast-notification'>
                    {notification}
                </NexusToast>
            )}
            {openModal && (
                <CustomModal
                    data={getFormattedRiskForModal(activeRisk)}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                />
            )}
            {openRejectionModal && (
                <ReasonModal
                    actionButton1={buttons.cancel}
                    actionButton2={buttons.continue}
                    description={triggerLabels.rejectingTriggerDesc}
                    handleReasonModalAction={handleRejectionModalAction}
                    openReasonModal={openRejectionModal}
                    reasonName={triggerLabels.triggerReason}
                    title={triggerLabels.rejectingTrigger}
                />
            )}
            <AddressTriggerStepProcess
                stepActions={stepActionsProps}
                stepDetail={stepDetail}
                stepSummary={stepSummary}
                verticalMenu={verticalMenuProps}
            />
        </div>
    );
};

export default Confirmation;
