import { AuthContainer, UnAuthContainer } from '../containers';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import LoginPage from '../views/login/loginPage';
import Logout from '../views/logout/logout';
import PageNotFound from '../views/404/404';
import ProfilePage from '../views/profile/profile';
import React from 'react';
import AssessmentUnitDashboard from '../views/assessmentUnit/assessmentUnitDashboard';
import AssessmentUnitTriggers from '../views/assessmentUnit/triggersAndControls/triggersAndControls';
import AssessmentUnitRisksControlsInventory from '../views/assessmentUnit/risksAndControlsInventory/risksAndControlsInventory';
import AddressTrigger from 'app/views/assessmentUnit/addressTrigger/addressTrigger';
import ResetTestData from 'app/views/assessmentUnit/reset/resetTestData';

const AppRoutes: React.FC = () => (
    <Router>
        <Routes>
            <Route
                path='/profile'
                element={
                    <AuthContainer>
                        <ProfilePage />
                    </AuthContainer>
                }
            />
            <Route
                path='/dashboard'
                element={
                    <AuthContainer>
                        <AssessmentUnitDashboard />
                    </AuthContainer>
                }
            />
            <Route
                path='/dashboard/:id/triggers'
                element={
                    <AuthContainer>
                        <AssessmentUnitTriggers />
                    </AuthContainer>
                }
            />
            <Route
                path='/dashboard/:id/triggers/:triggerId/address-trigger'
                element={
                    <AuthContainer>
                        <AddressTrigger />
                    </AuthContainer>
                }
            />
            <Route
                path='/dashboard/:id/risks-controls-inventory'
                element={
                    <AuthContainer>
                        <AssessmentUnitRisksControlsInventory />
                    </AuthContainer>
                }
            />
            <Route
                path='/reset-test-data'
                element={
                    <AuthContainer>
                        <ResetTestData />
                    </AuthContainer>
                }
            />
            <Route
                path='/'
                element={
                    <UnAuthContainer>
                        <LoginPage />
                    </UnAuthContainer>
                }
            />
            <Route path='/logout' element={<Logout />} />
            <Route
                path='*'
                element={
                    <AuthContainer>
                        <PageNotFound />
                    </AuthContainer>
                }
            />
        </Routes>
    </Router>
);

export default AppRoutes;
