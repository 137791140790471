import { IField } from 'constants/commonExportedInterfaces';
import './customModal.scss';

interface props {
    field: IField;
}

const FieldContainer: React.FC<props> = ({ field }) => {
    const fieldHandler = (text: string, label: string) => {
        return text || `No ${label} found.`;
    };

    return (
        <div className={`modal-field-container ${field.className}`} data-testid='modal-field-container'>
            <div className='field-name' data-testid='field-name'>
                {field.name}
            </div>
            <div className='field-value' data-testid='field-value'>
                {fieldHandler(field.value, field.name)}
            </div>
        </div>
    );
};

export default FieldContainer;
