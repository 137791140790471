export const emptyRiskFormData: any = {
    controls: [],
    publishedInherentRiskScore: '',
    riskCode: '',
    riskEffective: true,
    riskImpact: {
        id: '',
        impactValue: '',
        name: '',
    },
    riskLevel1: {
        id: '',
        level: '',
        name: '',
    },
    riskLevel2: {
        id: '',
        level: '',
        name: '',
    },
    riskLevel3: {
        id: '',
        level: '',
        name: '',
    },
    riskLikelihood: {
        id: '',
        likelihoodValue: '',
        name: '',
    },
    riskModel: '',
    riskName: '',
    riskOwner: '',
    riskOwnerDivision: '',
    riskProcess: {
        processCode: '',
        processDescription: '',
        processId: '',
        processName: '',
        publishedInherentRiskDescription: '',
    },
    riskProduct: '',
    riskRating: '',
    riskRationale: '',
    riskRegion: '',
    riskRegulation: '',
    riskState: 'NEW',
    riskStatement: '',
    riskStatus: null,
    riskSystemApplication: '',
    riskThirdParty: '',
    riskType: '',
};

export const formName = 'risk';

export const longInputSize = '12';
export const mediumInputSize = '6';
export const shortInputSize = '4';

export const shortInputs = ['riskOwner', 'riskOwnerDivision', 'riskRegion'];
export const mediumInputs = ['riskSystemApplication', 'riskThirdParty', 'riskModel', 'riskProduct', 'riskRegulation'];

export const riskLevelSelects = ['riskLevel1', 'riskLevel2', 'riskLevel3'];
export const riskRatingSelects = ['riskImpact', 'riskLikelihood'];

export const validateRisk = (formData: any) => {
    return !(
        formData.riskLevel1.id.length > 0 &&
        formData.riskLevel2.id.length > 0 &&
        formData.riskLevel3.id.length > 0 &&
        formData.riskProcess.processId.length > 0 &&
        formData.riskLikelihood.id.length > 0 &&
        formData.riskImpact.id.length > 0
    );
};
