import { useParams } from 'react-router';
import { useEffect, useState } from 'react';

import BreadcrumbComponent from 'app/components/nds/breadcrumb/breadcrumb';

interface IBreadcrumbItem {
    isEnabled: boolean;
    name: string;
    path?: string;
}

const AssessmentUnitsBreadcrumb: React.FC<{ auName: string }> = ({ auName }) => {
    const params = useParams();
    const path = `/dashboard`;
    const [breadcrumbData, setBreadcrumbData] = useState<IBreadcrumbItem[]>([]);

    useEffect(() => {
        const items: IBreadcrumbItem[] = [
            { isEnabled: false, name: 'Dashboard', path: path },
            { isEnabled: false, name: `${auName} Triggers`, path: `${path}/${params.id}/triggers` },
        ];

        if (params.triggerId) {
            items.push({ isEnabled: false, name: 'Address Trigger', path: `${path}/triggers` });
        }
        setBreadcrumbData(items);
    }, [params]);

    return (
        <div data-testid='assessmentUnitsBreadcrumb'>
            {breadcrumbData.length > 0 && <BreadcrumbComponent separator='>' data={breadcrumbData} />}
        </div>
    );
};

export default AssessmentUnitsBreadcrumb;
