import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAccessToken } from './authService';
import jwtDecode from 'jwt-decode';
import { RootState } from '../store';

interface props {
    accessToken: string | null;
    tokenExpiration: number | null;
    isAuthenticated: boolean;
    tokenRefreshPending: boolean;
    logoutPending: boolean;
    email: string | null;
    fullName: string | null;
}
export const INITIAL_STATE: props = {
    accessToken: null,
    email: null,
    fullName: null,
    isAuthenticated: false,
    logoutPending: false,
    tokenExpiration: null,
    tokenRefreshPending: false,
};

export interface JWTToken {
    exp?: number;
    given_name: string;
    family_name: string;
    name: string;
    unique_name: string;
}

// Use async thunk to wait for access token
export const fetchToken = createAsyncThunk('auth/fetchToken', () => getAccessToken());

// Selectors for quick access to specific data

// Expose access to the access token through selector
export const selectAuthToken = createSelector(
    (state: RootState) => state.auth,
    (auth) => auth.accessToken,
);

// Expose access to the access token's expiration in millis
export const selectAuthTokenExpiration = createSelector(
    (state: RootState) => state.auth,
    (auth) => auth.tokenExpiration,
);

// Expose access to the access token's expiration indicator
export const selectIsAuthTokenExpired = createSelector(
    (state: RootState) => state.auth,
    (auth) => auth.tokenRefreshPending,
);

// Expose access to the flag indicating user is authenticated
export const selectIsLogoutRequested = createSelector(
    (state: RootState) => state.auth,
    (auth) => auth.logoutPending,
);

// Expose access to the authenticated user by email through selector
export const selectAuthEmail = createSelector(
    (state: RootState) => state.auth,
    (auth) => (auth ? auth.email : ''),
);

// Expose access to the flag indicating user is authenticated
export const selectIsAuthenticated = createSelector(
    (state: RootState) => state.auth,
    (auth) => auth.isAuthenticated,
);

export const retrieveUserName = createSelector(
    (state: RootState) => state.auth,
    (auth) => auth.fullName,
);

export const authSlice: {
    [id: string]: any;
} = createSlice({
    extraReducers: (builder) =>
        builder.addCase(fetchToken.fulfilled, (state, action) => ({
            ...state,
            accessToken: action.payload,
            isAuthenticated: Boolean(action.payload),
            logoutPending: false,
            tokenRefreshPending: false,
        })),
    initialState: INITIAL_STATE,
    name: 'auth',
    reducers: {
        logout: (state) => {
            state.logoutPending = true;
            state.tokenRefreshPending = true;
        },
        refreshAuthToken: (state) => {
            state.tokenRefreshPending = true;
        },
        // An action types is generated per reducer
        setAuthToken: {
            reducer: (state, action: PayloadAction<props>) => {
                try {
                    // validate token with a decode attempt
                    const decodedToken: JWTToken = jwtDecode(
                        action.payload.accessToken ? action.payload.accessToken : '',
                    );
                    jwtDecode(action.payload.accessToken ? action.payload.accessToken : '');
                    state.accessToken = action.payload.accessToken;
                    state.tokenExpiration = action.payload.tokenExpiration;
                    state.isAuthenticated = true;
                    state.tokenRefreshPending = false;
                    state.logoutPending = false;

                    state.email = decodedToken.unique_name;
                    state.fullName = decodedToken.given_name;
                } catch (error) {
                    state.accessToken = ''; //'INVALID_TOKEN';
                    state.tokenExpiration = null;
                    state.isAuthenticated = false;
                    state.tokenRefreshPending = false;
                    state.logoutPending = false;
                }
            },
            prepare: (token) => {
                return { payload: token };
            },
        },
        setAuthUser: {
            reducer: (state, action: PayloadAction<{ email: string; name: string }>) => {
                state.email = action.payload.email;
                state.fullName = action.payload.name;
            },
            prepare: (user) => ({ payload: user }),
        },
        setInitialState: () => INITIAL_STATE,
    },
});

export const { logout, refreshAuthToken, setAuthToken, setAuthUser, setInitialState } = authSlice.actions;
export default authSlice.reducer;
