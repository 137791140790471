import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import ButtonComponent from 'app/components/nds/button/button';
import './flagRisks.scss';
import { useAppDispatch, useAppSelector } from 'store';
import { getRisksData, updateFlaggedRisks, updateOutstandingRisks } from '../redux/addressTriggerActions';
import LoaderComponent from 'app/components/nds/loader/loader';
import { ITriggerRisk } from 'constants/commonExportedInterfaces';
import { stepperLabels } from '../stepperConstants';
import RisksTable from './risksTable';
import EmptyTable from 'app/components/emptyTable/emptyTable';
import Filter from 'app/components/filter/filter';

const FlagRisks: React.FC<{ handleStepClick: (step: string) => void }> = ({ handleStepClick }) => {
    const [t] = useTranslation('lang');
    const riskLabels = JSON.parse(JSON.stringify(t('risk.labels')));
    const { triggerId } = useParams();
    const dispatch = useAppDispatch();
    const { risksData, loading, flaggedRisks } = useAppSelector((state) => state.addressTriggerData);

    useEffect(() => {
        dispatch(getRisksData(triggerId as string));
    }, []);

    // When storing flagged risks in redux, store the ones from API so they keep the ITriggerRisk
    //  structure, which is required in further steps
    const handleRiskSelect = (rows: any[]) => {
        const flaggedRisksIds = rows.map((r) => r.riskId);
        dispatch(updateFlaggedRisks(risksData.filter((r: any) => flaggedRisksIds.includes(r.riskId))));
        dispatch(updateOutstandingRisks(risksData.filter((r: any) => flaggedRisksIds.includes(r.riskId))));
    };

    // Here we are showing riskId lebel value as riskCode, we need to change in MVP
    const fields = [
        'riskProcess',
        'riskStatement',
        'riskCode',
        'riskLevel1',
        'riskLevel2',
        'riskLevel3',
        'publishedInherentRiskDescription',
    ];
    const labels = [
        riskLabels.process,
        riskLabels.riskStatement,
        riskLabels.riskId,
        riskLabels.riskLevel1,
        riskLabels.riskLevel2,
        riskLabels.riskLevel3,
        riskLabels.riskRating,
        riskLabels.riskId,
    ];
    const columnDefs = fields.map((field: string, idx) => {
        return {
            field,
            isSortable: true,
            label: labels[idx],
        };
    });

    const risks =
        risksData &&
        risksData.length > 0 &&
        risksData.map((r: ITriggerRisk) => {
            return {
                ...r,
                riskLevel1: r.riskLevel1.name,
                riskLevel2: r.riskLevel2.name,
                riskLevel3: r.riskLevel3.name,
                riskProcess: r.riskProcess.processName,
            };
        });

    if (loading) <LoaderComponent show={loading} />;

    return (
        <div className='flag-risks-screen' data-testid='flag-risks'>
            <section className='nexus-container'>
                <div className='header-container'>
                    <div className='title'>
                        <p className='nexus-h6'>{riskLabels.riskLibrary}</p>
                        <p className='nexus-subtitle'>{riskLabels.riskLibaryDescription}</p>
                    </div>
                    <Filter />
                </div>

                {risks && (
                    <RisksTable
                        columnDefs={columnDefs}
                        fields={fields}
                        rows={risks}
                        handleRowSelection={handleRiskSelect}
                        rowSelection={flaggedRisks}
                        data-testid='risks-table'
                    />
                )}
                {!risks.length && !loading && !risksData && <EmptyTable columnTitles={fields} />}
                <div className='actions'>
                    <ButtonComponent
                        label='Next'
                        type={['nexus-btn-primary']}
                        click={() => handleStepClick(stepperLabels.RiskReview)}
                        disabled={!(flaggedRisks.length > 0)}
                    />
                </div>
            </section>
        </div>
    );
};

export default FlagRisks;
