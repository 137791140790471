export const fields = [
    'controlCode',
    'controlType',
    'controlStatus',
    'controlDescription',
    'controlOwner',
    'controlTaxonomyLevel1',
    'controlTaxonomyLevel2',
    'controlTaxonomyLevel3',
    'controlOwnerAu',
    'controlPrePostTrigger',
];

export const labels = [
    'CONTROL ID',
    'CONTROL TYPE',
    'STATUS',
    'CONTROL DESCRIPTION',
    'CONTROL OWNER',
    'Control Taxonomy Level 1',
    'Control Taxonomy Level 2',
    'Control Taxonomy Level 3',
    'Control Owner Assessment Unit',
    'Pre/Post Trigger',
];

export const columnDefs = fields.map((f, idx) => {
    return {
        field: f,
        isSortable: true,
        label: labels[idx],
    };
});

export const formatControlsSelected = (count: number) => {
    return count.toString().length > 1 ? count : `0${count}`;
};
