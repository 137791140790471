import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import CardHeaderImage from '@nexus/core/dist/assets/images/card-header-image.png';

import AssessmentUnit from 'app/components/assessmentUnits/assessmentUnit';
import LoaderComponent from 'app/components/nds/loader/loader';
import { IAssessmentUnit, IBarChartData, IChartData, ICustomChatData } from 'constants/commonExportedInterfaces';
import './assessmentUnitDashboard.scss';
import { getAssessmentUnitData } from '../assessmentUnit/redux/assessmentUnits/assessmentUnitActions';
import { useAppDispatch, useAppSelector } from 'store';
import CustomContainer from 'app/components/customContainer/customContainer';
import RecentActivityCard from 'app/components/recentActivityCard/recentActivityCard';
import LineChartComponent from 'app/components/charts/LineChartComponent';
import BarChartComponent from '../../components/charts/BarChartComponent';
import CustomChartComponent from 'app/components/charts/CustomChartComponent';
import FormatChartDate from 'utils/formatChartDate';
import { assessmentUnitsUrl } from 'constants/apiConstants';

// Custom Chart Data
const customchartData: ICustomChatData[] = [
    {
        effective: 6,
        ineffective: 8,
        name: 'Preventative',
    },
    {
        effective: 6,
        ineffective: 0,
        name: 'Detective',
    },
];
// Barchart data
const barChartData: IBarChartData[] = [
    {
        mitigated: 10,
        name: 'Model risk',
        unmitigated: 5,
    },
    {
        mitigated: 5,
        name: 'Operations risk',
        unmitigated: 7,
    },
    {
        mitigated: 15,
        name: 'Data risk',
        unmitigated: 3,
    },
    {
        mitigated: 10,
        name: 'Compliance risk',
        unmitigated: 2,
    },
    {
        mitigated: 5,
        name: 'Regulatory Risk',
        unmitigated: 9,
    },
];

const recentActivityData: Array<any> = [
    { activity: 'New trigger on assessment unit', avatar: CardHeaderImage, time: '4 min ago', user: 'Michael Bay' },
    { activity: 'New trigger on assessment unit', avatar: CardHeaderImage, time: '4 min ago', user: 'Michael Bay' },
    { activity: 'New trigger on assessment unit', avatar: CardHeaderImage, time: '4 min ago', user: 'Michael Bay' },
    { activity: 'New trigger on assessment unit', avatar: CardHeaderImage, time: '4 min ago', user: 'Michael Bay' },
];

// abstracts the use of authprovider, may render content eventually
const AssessmentUnitDashboard: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    let ChartData: IChartData[] = [];

    const { assessmentUnitData: assessmentUnits, loading } = useAppSelector((state) => state.assessmentUnitData);

    const [currentAssesmentUnit, setCurrentAssesmentUnit] = useState<IAssessmentUnit>(
        assessmentUnits && assessmentUnits[0],
    );

    // Chart Data from API
    ChartData = FormatChartDate(currentAssesmentUnit && (currentAssesmentUnit as any).triggerHistories);

    const handleNavigation = (path: string) => {
        assessmentUnits && navigate(`/dashboard/${currentAssesmentUnit.auId}/${path}`);
    };

    useEffect(() => {
        dispatch(getAssessmentUnitData(`${assessmentUnitsUrl}`));
    }, []);

    useEffect(() => {
        assessmentUnits && setCurrentAssesmentUnit(assessmentUnits[0]);
    }, [assessmentUnits]);

    const recentActivityList = (
        <div className='recent-activity-list'>
            {recentActivityData.map((item, idx) => (
                <RecentActivityCard
                    key={idx}
                    activity={item.activity}
                    avatar={item.avatar}
                    time={item.time}
                    user={item.user}
                />
            ))}
        </div>
    );

    return (
        <div data-testid='dashboard-page' className='assessment-unit-dashboard'>
            {loading && <LoaderComponent show={loading} />}
            <section className='nexus-container header-container'>
                <div className='nexus-row header-content'>
                    <div className='nexus-col-xs-12 nexus-col-md-12 nexus-col-xl-12'>
                        {currentAssesmentUnit && assessmentUnits && !loading && (
                            <AssessmentUnit
                                assessmentUnitData={currentAssesmentUnit}
                                assessmentUnits={assessmentUnits}
                                setCurrentAssesmentUnit={setCurrentAssesmentUnit!}
                            />
                        )}
                    </div>
                </div>
            </section>
            <section className='nexus-container content-container'>
                <div className='nexus-row nexus-mt-4'>
                    <div className='nexus-col-xs-3 nexus-col-md-4'>
                        <h2 className='process'>Triggers</h2>
                    </div>
                    <div className='nexus-col-xs-3 nexus-col-md-4 nexus-end-lg nexus-end-sm'>
                        <button
                            data-testid='viewAllTriggersButton'
                            className='nexus-btn nexus-btn-large'
                            onClick={() => handleNavigation('triggers')}
                        >
                            View All Triggers
                        </button>
                    </div>
                </div>
                <div className='nexus-row nexus-mt-2 '>
                    <div className='nexus-col-xs-4 nexus-col-md-4 nexus-col-lg-4 left-container'>
                        <CustomContainer children={recentActivityList} title='Recent activity' />
                    </div>
                    <div className='nexus-col-xs-4 nexus-col-md-4 nexus-col-lg-8'>
                        <CustomContainer
                            children={
                                !loading && ChartData && !ChartData.length ? (
                                    <div className='nexus-h6 empty-data-chart-message'>
                                        No triggers history data available to display.
                                    </div>
                                ) : (
                                    <LineChartComponent ChartData={ChartData} />
                                )
                            }
                            title='Triggers History'
                        />
                    </div>
                </div>
            </section>
            <section className='nexus-container charts-container'>
                <div className='nexus-row nexus-mt-4'>
                    <div className='nexus-col-xs-6 nexus-col-sm-2'>
                        <h2 className='process'>Risks and Controls</h2>
                    </div>
                    <div className='nexus-col-xs-6 nexus-col-sm-2 nexus-end-xs'>
                        <button
                            data-testid='viewRisksControlsInventoryButton'
                            className='nexus-btn nexus-btn-auto'
                            onClick={() => handleNavigation('risks-controls-inventory')}
                        >
                            View Risks and Controls Inventory
                        </button>
                    </div>
                </div>
                <div className='nexus-row nexus-mt-2 nexus-mb-6'>
                    <div className='nexus-col-xs-4 nexus-col-md-4 nexus-col-lg-6 left-container'>
                        <CustomContainer
                            children={
                                !loading && barChartData && !barChartData.length ? (
                                    <div className='nexus-h6 empty-data-chart-message'>
                                        No risks inventory data available to display.
                                    </div>
                                ) : (
                                    <BarChartComponent ChartData={barChartData} />
                                )
                            }
                            title='Risks Inventory'
                        />
                    </div>
                    <div className='nexus-col-xs-4 nexus-col-md-4 nexus-col-lg-6'>
                        <CustomContainer
                            children={<CustomChartComponent customchartData={customchartData} />}
                            title='Controls Inventory'
                        />
                    </div>
                </div>
            </section>
        </div>
    );
};

export default AssessmentUnitDashboard;
