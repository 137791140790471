import './errorBoundary.scss';

import { Component, ErrorInfo, ReactNode } from 'react';

import ModalComponent from '../nds/modal/modal';
import React from 'react';
import ReactPortal from '../portals/portals';

interface Props {
    children?: ReactNode;
}

interface errorState {
    hasError?: boolean;
    isActive?: boolean;
}

class ErrorBoundary extends Component<Props, errorState> {
    constructor(props: Props | Readonly<Props>) {
        super(props);
        this.state = {
            isActive: true,
        };
        this.hideModal = this.hideModal.bind(this);
    }

    hideModal = () => {
        this.setState({ isActive: false });
    };

    public state: errorState = {
        hasError: false,
    };

    public static getDerivedStateFromError(): errorState {
        return { hasError: true };
    }

    /* eslint-disable no-console */
    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('Uncaught error:', error, errorInfo);
    }

    portal = document.getElementById('portal-root') as HTMLElement;

    closeModal = () => {
        this.setState({
            isActive: false,
        });
    };

    translationErrorMessage = 'errorMessage';

    public render() {
        if (this.state.hasError) {
            return (
                <ReactPortal wrapperId='portal-root'>
                    <ModalComponent show={this.state.isActive!} size={'lg'} header='' closeModal={this.closeModal}>
                        <h3 className='error-boundary-text' data-testid='error-boundary-text'>
                            Something went wrong
                        </h3>
                        <p>Please try again later</p>
                    </ModalComponent>
                    ,
                </ReactPortal>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
