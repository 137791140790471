/* eslint-disable camelcase */
import { stringify, parse } from 'query-string';
import { nanoid } from 'nanoid';
import {
    REACT_APP_CLIENT_ID,
    REACT_APP_OAUTH_BASE_URL,
    REACT_APP_OAUTH_AUTH_CONTEXT,
    REACT_APP_OAUTH_LOGOUT_CONTEXT,
} from '../../config/settings';

// Configuration params for AD azure requests
const authority = REACT_APP_OAUTH_BASE_URL;
const authContext = REACT_APP_OAUTH_AUTH_CONTEXT;
const logoutContext = REACT_APP_OAUTH_LOGOUT_CONTEXT;
const client_id = REACT_APP_CLIENT_ID;
const resource = REACT_APP_CLIENT_ID;
const redirect_uri = window.location.origin;
const post_logout_redirect_uri = window.location.origin;
const silentRedirectUri = `${redirect_uri}/tokenRefresh.html`;
const response_type = 'id_token token';
const scope = 'openid profile';

/**
 * Authenticate by hitting an authorize endpoint. Will redirect to the url and return the request back
 * to the calling/redirect uri.
 */
export const authenticate = (search: string | undefined = ''): void => {
    const nonce = nanoid(32);
    const state =
        typeof search === 'string' && search.length
            ? btoa(
                  stringify({
                      n: `${Date.now()}-${nonce.slice(0, -2)}`,
                      ...parse(search),
                  }),
              )
            : nanoid(32);
    // nonce can be used to verify the returned token..

    // Generate authentication URL
    const params = stringify({
        client_id,
        nonce,
        redirect_uri,
        resource,
        response_type,
        scope,
        state,
    });
    const authUrl = `${authority}${authContext}?${params}`;
    localStorage.setItem('authUrlauthenticate', authUrl);
    // Attempt login by navigating to authUrl
    window.location.assign(authUrl);
};

/**
 * Authenticate silently by setting provided iframe's src to an authorize endpoint and providing an
 * authentication hint by way of email address.
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-oauth2-implicit-grant-flow
 *
 * Uses publicly accessible static html page for the token redirect.
 */
export const authenticateSilently = (email: string, iframe: any) => {
    const prompt = 'none';
    const login_hint = email;
    const state = nanoid(32);
    const nonce = nanoid(32);
    // Generate authentication URL
    const params = stringify({
        client_id,
        login_hint,
        nonce,
        prompt,
        redirect_uri: silentRedirectUri,
        resource,
        response_mode: 'fragment',
        response_type: 'token',
        scope,
        state,
    });
    const authUrl = `${authority}${authContext}?${params}`;
    // Attempt login by navigating to authUrl
    if (iframe) iframe.src = authUrl;
};

/**
 * Logout session by hitting logout endpoint. Will redirect to the url and return the request back
 * to the calling/redirect uri.
 */
export const logout = () => {
    const params = stringify({
        client_id,
        post_logout_redirect_uri,
        resource,
        response_type,
        scope,
    });
    const authUrl = `${authority}${logoutContext}?${params}`;
    // Attempt logout by navigating to authUrl with logout params
    window.location.assign(authUrl);
};
