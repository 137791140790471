import * as Msal from 'msal';
import {
    REACT_APP_CLIENT_ID,
    // REACT_APP_TENANT_ID,
    REACT_APP_TENANT_NAME,
    REACT_APP_ADB2C_LOGOUT_REDIRECT_URI,
    REACT_APP_ADB2C_USER_FLOW,
} from '../../config/settings';
import { providerTypes, isConfiguredProviderType } from '../authProviderTypes';

const SCOPE_PATH = `https://${REACT_APP_TENANT_NAME}.onmicrosoft.com/${REACT_APP_CLIENT_ID}`;
const CACHE_LOCATION = 'sessionStorage';
const adb2cEnabled = isConfiguredProviderType(providerTypes.ADB2C);

// This provider is based on the MSAL 1.x implementation and assumes an Implicit Flow Authentication flow for
// Single Page Apps (SPA) as outlined here: https://docs.microsoft.com/en-us/azure/active-directory/develop/authentication-flows-app-scenarios
// MSAL v1.x reference:
// https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-js-initializing-client-applications
// Based on a configured ADB2C instance in Azure with User Flows to manage authentication and accounts.

// Create the main msalObj singleton instance with configuration or an empty object if not properly configured
const MSALProvider = (() => {
    let instance: {};

    const createInstance = () => {
        const provider = adb2cEnabled
            ? new Msal.UserAgentApplication({
                  auth: {
                      authority: `https://${REACT_APP_TENANT_NAME}.b2clogin.com/${REACT_APP_TENANT_NAME}.onmicrosoft.com/${REACT_APP_ADB2C_USER_FLOW}`,
                      clientId: REACT_APP_CLIENT_ID!,
                      navigateToLoginRequestUrl: false,
                      redirectUri: window.location.origin,
                      validateAuthority: false,
                  },
                  cache: {
                      // This configures where your cache will be stored
                      cacheLocation: CACHE_LOCATION,
                      // Set this to "true" if you are having issues on IE11 or Edge
                      storeAuthStateInCookie: false,
                  },
              })
            : {};

        return provider;
    };

    return {
        getInstance: () => {
            if (!instance) {
                instance = createInstance();
            }

            return instance;
        },
    };
})();

// ID Token Scopes
export const loginRequest = {
    scopes: ['openid', 'profile'],
};

// Scopes defined with Azure ADB2C that are then provided within the accessToken requests/responses
// URL Example - 'https://nexustestb2c.onmicrosoft.com/e409052c-3607-4007-b9af-3cbffb0236b1/api.read'
export const tokenRequest = {
    scopes: [`${SCOPE_PATH}/api.read`],
};

/**
 * Logs the user and optionally redirects to a URI specfied in configuration: REACT_APP_ADB2C_LOGOUT_REDIRECT_URI
 */
export const logout = () => {
    // This doesn't account for cookie storage which is turned off by default
    CACHE_LOCATION === 'sessionStorage' ? sessionStorage.clear() : localStorage.clear();

    // Determine why this isn't doing what it's supposed to...
    // MSALProvider.getInstance().logout();

    // NOTE: Since logout isn't reliably logging out when using the msal api, leverage the uri directly
    const redirectUri = REACT_APP_ADB2C_LOGOUT_REDIRECT_URI || window.location.origin;
    window.location.href = `https://${REACT_APP_TENANT_NAME}.b2clogin.com/${REACT_APP_TENANT_NAME}.onmicrosoft.com/${REACT_APP_ADB2C_USER_FLOW}/oauth2/v2.0/logout?post_logout_redirect_uri=${redirectUri}`;
};

export const getProvider = () => MSALProvider.getInstance();
