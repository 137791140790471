import { NexusIcon } from '@nexus/react';
import done from '@nexus/core/dist/assets/icons/action/ic_done_24px.svg';
import close from '@nexus/core/dist/assets/icons/navigation/ic_close_24px.svg';
import './controlForm.scss';

interface props {
    controlFlag: boolean;
    controlFlagLabel: string;
}

const ControlFlagIcon: React.FC<props> = ({ controlFlag, controlFlagLabel }) => {
    const getIconColor = (flag: boolean) => {
        return { color: flag ? '#59d159' : '#DA3510' };
    };

    return (
        <div className='nexus-col-lg-2 icons'>
            <div className='texts'>{controlFlagLabel}</div>
            <NexusIcon
                data-testid='control-flag-indicator'
                src={controlFlag ? done : close}
                size={'md'}
                style={getIconColor(controlFlag)}
            />
        </div>
    );
};

export default ControlFlagIcon;
