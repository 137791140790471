import { NexusFormField, NexusLabel, NexusSelect } from '@nexus/react';
import { useTranslation } from 'react-i18next';
import { formName, getControlObjectProp } from './controlFormConsts';

interface props {
    fieldName: string;
    fieldValue: any;
    handleInputChange: (args: any) => void;
    isNewControl: boolean;
    isTaxonomy?: boolean;
    label: string;
    level?: any;
    options: any[];
    valueId: string;
}

const ControlFormSelect: React.FC<props> = ({
    fieldName,
    fieldValue,
    handleInputChange,
    isNewControl,
    isTaxonomy,
    label,
    level,
    options,
    valueId,
}) => {
    const [t] = useTranslation('lang');
    const defaultValue = JSON.parse(JSON.stringify(t('warnings.pleaseSelect')));
    const testid = fieldName.slice(formName.length).toLowerCase();

    return (
        <NexusFormField>
            <NexusLabel data-testid={`control-${testid}-label`}>{label}</NexusLabel>
            <NexusSelect
                data-testid={`control-${testid}-select`}
                value={
                    isNewControl
                        ? defaultValue
                        : isTaxonomy
                        ? fieldValue[valueId]
                        : getControlObjectProp(options, fieldValue[valueId] || fieldValue)
                }
                defaultValue={
                    isNewControl
                        ? defaultValue
                        : isTaxonomy
                        ? fieldValue[valueId]
                        : getControlObjectProp(options, fieldValue[valueId] || fieldValue)
                }
                required
                onInput={(event: any) =>
                    handleInputChange({
                        name: fieldName,
                        value: isTaxonomy
                            ? {
                                  id: event.target.value,
                                  level: level,
                                  name: getControlObjectProp(options, undefined, event.target.value),
                              }
                            : getControlObjectProp(options, undefined, event.target.value),
                    })
                }
            >
                <option data-testid={`control-${testid}-opt`} defaultValue={defaultValue} disabled>
                    {defaultValue}
                </option>
                {options.map((item: any) => (
                    <option key={item.id} value={item.id}>
                        {item.name}
                    </option>
                ))}
            </NexusSelect>
        </NexusFormField>
    );
};

ControlFormSelect.defaultProps = {
    isTaxonomy: false,
    level: undefined,
};

export default ControlFormSelect;
