import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import actionConst from './assessmentUnitConst';
import { IAssessmentUnit } from 'constants/commonExportedInterfaces';
import { get, post } from 'utils';
import { assessmentUnitsUrl, resetUrl } from 'constants/apiConstants';

export const getAssessmentUnitData = createAsyncThunk(
    actionConst.assessmentUnitData,
    async (apiUrl: string, { rejectWithValue }) => {
        try {
            const response = await get({
                baseURL: apiUrl,
            });

            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    },
);

export const getAssessmentUnitById = createAsyncThunk(
    actionConst.assessmentUnitById,
    async (assessmentUnitId: string, { rejectWithValue }) => {
        try {
            const response = await get({
                baseURL: `${assessmentUnitsUrl}/${assessmentUnitId}`,
            });

            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    },
);

export const resetTestData = createAsyncThunk(actionConst.resetTestData, async (_, { rejectWithValue }) => {
    try {
        const response = await post({
            baseURL: resetUrl,
        });

        return response;
    } catch (error: any) {
        return rejectWithValue(error.message);
    }
});

export const updateCurrentAssessmentUnit = createAction<IAssessmentUnit | null>(actionConst.updateAssessmentUnit);
