// This allows for changing or disabling authentication providers
import React from 'react';
import ADB2CAuth from './adb2c/adb2cAuth';
import TokenAuth from './token/tokenAuth';
import { providerTypes, getConfiguredProviderType } from './authProviderTypes';
import ADAuth from './ad/adAuth';
import { RootState } from '../store';

interface InputProps {
    children: React.ReactNode;
    store?: RootState;
    doLogout?: boolean;
}

const AuthProvider = (props: InputProps) => {
    const type = getConfiguredProviderType();
    // by default AD Auth is enabled but can be disabled via .env config if necessary.
    // Could choose a different provider or none as with the fragment
    switch (type) {
        case providerTypes.ADB2C:
            return <ADB2CAuth {...props} />;
        case providerTypes.TOKEN:
            return <TokenAuth {...props} />;
        default:
            return <ADAuth {...props} />;
    }
};

export default AuthProvider;
