import { useTranslation } from 'react-i18next';
import React from 'react';
import './ChartLegends.scss';

const ChartLegends: React.FC = () => {
    const [t] = useTranslation('lang');
    return (
        <div className='chart-legends' data-testid='chart-legends'>
            {t('chartLegend')}
        </div>
    );
};

export default ChartLegends;
