import React from 'react';
import './profile.scss';
import { useSelector } from 'react-redux';
import { selectAuthEmail, retrieveUserName } from '../../../auth';
import img from './../../assets/images/male-user-profile.png';

const ProfilePage = () => {
    const email = useSelector(selectAuthEmail);
    const fullName = useSelector(retrieveUserName);

    return (
        <div className='nexus-container nexus-body'>
            <div className='nexus-row'>
                <div className='nexus-col-sm-1 nexus-col-sm-offset-1 nexus-col-md-offset-2 nexus-col-md-2'>
                    <img className='profile-profile-pic' src={img} alt={fullName} />
                </div>
                <div className='nexus-col-sm-4 nexus-col-md-4 table-bg'>
                    <div className='nexus-h3'>{fullName}</div>

                    <table className='nexus-table'>
                        <thead></thead>
                        <tbody>
                            <tr data-testid='row-1'>
                                <td>Name</td>
                                <td>{fullName}</td>
                            </tr>
                            <tr data-testid='row-1'>
                                <td>E-mail</td>
                                <td>{email}</td>
                            </tr>
                            <tr data-testid='row-1'>
                                <td>Phone</td>
                                <td> </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default ProfilePage;
