const NAME = 'triggersData';

const actionConst = {
    dismissTrigger: `${NAME}/dismissTrigger`,
    summaryData: `${NAME}/summaryData`,
    triggerById: `${NAME}/triggerById`,
    triggersData: `${NAME}/triggersData`,
};

export default actionConst;
