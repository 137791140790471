import './assessmentUnitBox.scss';

interface props {
    label: string;
    value: number;
    change?: number;
}

const AssessmentUnitBox: React.FC<props> = ({ label, value, change }) => {
    return (
        <div className='nexus-col-xs-4 nexus-col-sm-2 nexus-col-md-3 nexus-col-lg-3 ract-box-item'>
            <div className='title'>{label}</div>
            <div className='value'>{value}</div>
            {change !== undefined ? (
                <div
                    data-testid='assessmentUnitBoxSign'
                    className='hit-map-item'
                    style={{ color: change >= 0 ? '#59D159' : '#FF5833' }}
                >
                    {change >= 0 ? '+' : '-'}
                    {change}
                    <span className='dot' style={{ backgroundColor: change >= 0 ? '#59D159' : '#FF5833' }}></span>
                </div>
            ) : null}
        </div>
    );
};

export default AssessmentUnitBox;
