import Flag from '@nexus/core/dist/assets/icons/content/ic_flag_24px.svg';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { stepperLabels } from '../stepperConstants';
import LoaderComponent from 'app/components/nds/loader/loader';
import { ITriggerRisk } from 'constants/commonExportedInterfaces';
import RiskForm from './riskForm/riskForm';
import { useAppDispatch, useAppSelector } from 'store';
import {
    addNewRisk,
    addressRisk,
    clearAddressedRisks,
    editRisk,
    updateActiveStep,
    updateOutstandingRisks,
} from '../redux/addressTriggerActions';
import AddressTriggerStepProcess from '../addressTriggerStepProcess/addressTriggerStepProcess';

const RiskReview: React.FC<{ handleStepClick: (step: string) => void }> = () => {
    const [t] = useTranslation('lang');
    const buttons = JSON.parse(JSON.stringify(t('buttons')));
    const menuLabels = JSON.parse(JSON.stringify(t('verticalMenu.labels')));
    const warnings = JSON.parse(JSON.stringify(t('warnings')));

    const { loading, flaggedRisks, outstandingRisks, activeStep, addressedRisks } = useAppSelector(
        (state) => state.addressTriggerData,
    );
    const [activeOutstandingRisk, setActiveOutstandingRisk] = useState<ITriggerRisk>({ ...flaggedRisks[0] });
    const [outstandingRiskId, setOutstandingRiskId] = useState(flaggedRisks[0].riskId);
    const [isNewRisk, setIsNewRisk] = useState<boolean>(false);
    const [isActiveRiskAddressedRisk, setIsActiveRiskAddressedRisk] = useState<boolean>(false);
    const [modified, setModified] = useState<boolean>(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const dispatch = useAppDispatch();

    const handleAddRisk = ({ risk, isNewRisk }: { risk: ITriggerRisk; isNewRisk: boolean }) => {
        let { riskId, ...riskBody } = risk;
        // If risk is new, create it, and add it directly to addressed risks
        // If not new and risk was edited, send the API request
        // If not new, in any case, address the risk
        if (isNewRisk) dispatch(addNewRisk(riskBody));
        else if (modified) dispatch(editRisk({ ...riskBody, riskId }));
        else dispatch(addressRisk(risk));
    };

    // Handling new risk action, show popup to confirm the action
    const handleNewRiskAction = () => {
        if (isNewRisk === true || modified === true) {
            setShowConfirmationModal(true);
        } else {
            setIsNewRisk(true);
        }
    };

    // Handling confirmation modal action
    const handleConfirmation = (confirm: boolean) => {
        if (confirm) {
            setIsNewRisk(true);
        }
        setShowConfirmationModal(false);
    };

    // Vertical Menu items
    const formatMenuItems = (risks: ITriggerRisk[]) => {
        return risks.map((risk: ITriggerRisk) => {
            return {
                id: risk.riskId,
                name: risk.riskName,
            };
        });
    };

    const items = [
        { isOpen: true, item: menuLabels.outstandingRisks, subItems: formatMenuItems(outstandingRisks) },
        { isOpen: true, item: menuLabels.addressedRisks, subItems: formatMenuItems(addressedRisks) },
    ];

    useEffect(() => {
        let outstandingRiskData = outstandingRisks.filter((risk: ITriggerRisk) => risk.riskId === outstandingRiskId);

        // If new active risk is not outstanding, then find it in addressed
        if (!outstandingRiskData.length) {
            outstandingRiskData = addressedRisks.filter((risk: ITriggerRisk) => risk.riskId === outstandingRiskId);
        }

        setActiveOutstandingRisk({ ...outstandingRiskData[0] });
    }, [outstandingRiskId, outstandingRisks, addressedRisks]);

    // Set whether is the active risk an addressed risk or not
    useEffect(() => {
        const isAddressedRisk = addressedRisks.filter(
            (addressedRisk: ITriggerRisk) => addressedRisk.riskId === activeOutstandingRisk.riskId,
        );
        setIsActiveRiskAddressedRisk(isAddressedRisk.length > 0);
    }, [activeOutstandingRisk, addressedRisks]);

    useEffect(() => {
        dispatch(updateOutstandingRisks([...outstandingRisks, ...addressedRisks]));
        dispatch(clearAddressedRisks());
    }, [activeStep]);

    const confirmationModalProps = {
        actionMessage: warnings.newRiskConfirmationMessage,
        cancelActionButton: buttons.continueEditing,
        confirmActionButton: buttons.newRisk,
        handleConfirmation,
        setShowConfirmationModal: setShowConfirmationModal,
        showConfirmationModal: showConfirmationModal,
        title: warnings.workInProgress,
    };

    const stepActionsProps = {
        actionButton2Action: () => dispatch(updateActiveStep(stepperLabels.ControlMapping)),
        actionButton2Label: buttons.next,
        nextStep: stepperLabels.ControlMapping,
    };

    const stepDetail = (
        <RiskForm
            activeRisk={activeOutstandingRisk}
            isActiveRiskAddressedRisk={isActiveRiskAddressedRisk}
            isNewRisk={isNewRisk}
            handleSubmitAction={handleAddRisk}
            setModified={setModified}
        />
    );

    const verticalMenuProps = {
        activeSubItem: activeOutstandingRisk.riskId,
        button: buttons.newRisk,
        handleMenuAction: handleNewRiskAction,
        items: items,
        modified: modified,
        setIsNewSubItem: setIsNewRisk,
        setSelectedSubItem: setOutstandingRiskId,
        title: menuLabels.flaggedRisks,
        titleIcon: Flag,
    };

    if (loading) return <LoaderComponent show={loading} />;

    return (
        <div className='risk-review-step' data-testid='risk-review-step' style={{ margin: '0' }}>
            {loading && <LoaderComponent show={loading} />}
            <AddressTriggerStepProcess
                confirmationModal={confirmationModalProps}
                currentStep={'risk-review-step'}
                stepActions={stepActionsProps}
                stepDetail={stepDetail}
                verticalMenu={verticalMenuProps}
            />
        </div>
    );
};

export default RiskReview;
