import { NexusIcon } from '@nexus/react';
import DropdownArrow from '@nexus/core/dist/assets/icons/hardware/ic_keyboard_arrow_down_24px.svg';
import { useState } from 'react';

import './menuItem.scss';
import { IMenuItemComponentProps } from 'constants/commonExportedInterfaces';
import { truncate } from '../../../../utils/dataHandler';
import CustomChip from 'app/components/customChip/customChip';

const MenuItem: React.FC<IMenuItemComponentProps> = ({
    activeSubItem,
    chipPalette,
    item,
    subItems,
    isOpen = false,
    setIsNewSubItem,
    setSelectedSubItem,
}) => {
    const [open, setOpen] = useState(isOpen);
    const itemAbbreviation = getUpperCases(item);

    function getUpperCases(item: string): string {
        let itemAbbreviation = '';
        for (let i = 0; i < item.length; i++) {
            const isUpperCase = item[i] === item[i].toUpperCase();
            if (isUpperCase) itemAbbreviation = itemAbbreviation + item[i].trim();
        }

        return itemAbbreviation;
    }

    function formatString(text: string): string {
        return text[0].toLocaleUpperCase() + text.slice(1, text.length).toLocaleLowerCase();
    }

    return (
        <div data-testid='menu-item-container' className='menu-item-container'>
            <div className={`menu-item ${open ? 'active-menu-item' : ''}`}>
                <div data-testid='item-abbreviation' className='item-abbreviation'>
                    {itemAbbreviation}
                </div>
                {`${item} (${subItems.length})`}
                <NexusIcon
                    data-testid='dropdown-icon'
                    className={`dropdown-icon ${open ? 'rotate' : ''}`}
                    src={DropdownArrow}
                    onClick={() => setOpen(!open)}
                ></NexusIcon>
            </div>
            <div
                data-testid='sub-menu-item-container'
                className={`sub-menu-item-container ${subItems.length > 2 ? 'over-flow' : ''}`}
            >
                {open
                    ? subItems.map((subItem, subItemidx) => {
                          return (
                              <button
                                  className={`sub-menu-item ${activeSubItem === subItem.id ? 'active-sub-item' : ''}`}
                                  data-testid={`sub-menu-item-${subItemidx}`}
                                  key={`sub-menu-item-${subItem.id}-${subItemidx}`}
                                  onClick={() => {
                                      setSelectedSubItem(subItem.id);
                                      setIsNewSubItem(false);
                                  }}
                              >
                                  {`${truncate(subItem.name, 18)} `}
                                  {subItem.chipText && chipPalette && (
                                      <CustomChip
                                          chipPalette={chipPalette}
                                          defaultColor={'#E6B000'}
                                          field={formatString(subItem.chipText)}
                                      />
                                  )}
                              </button>
                          );
                      })
                    : null}
            </div>
        </div>
    );
};

export default MenuItem;
