import { setupRequestAuthorizationMiddleware } from '../utils';
import { AppDispatch, RootState } from '../store/index';

let store: {
    dispatch: (arg0: (dispatch: AppDispatch, getState: RootState) => void) => void;
};
export const setStore = (theStore: any) => {
    if (!store) {
        store = theStore;
        const authData = setupRequestAuthorizationMiddleware();
        store.dispatch(authData);
    }
};

export const dispatch = (action: { (dispatch: any, getState: any): void; payload?: any; type?: string }) =>
    store.dispatch(action);
