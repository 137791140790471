import { NexusCard, NexusCardBody } from '@nexus/react';

import './assessmentUnit.scss';
import { IAssessmentUnit } from 'constants/commonExportedInterfaces';
import AssessmentUnitBox from './assessmentUnitBox/assessmentUnitBox';
import AssessmentUnitSelect from './assessmentUnitSelect/assessmentUnitSelect';
import { Dispatch, SetStateAction } from 'react';

interface props {
    assessmentUnits: IAssessmentUnit[];
    assessmentUnitData: IAssessmentUnit;
    options?: {
        wrap?: boolean;
        onClickAction?: (args: any) => void;
        includeBackgroundStyles?: boolean;
        titleAlignment?: 'center' | '';
    };
    setCurrentAssesmentUnit: Dispatch<SetStateAction<IAssessmentUnit>>;
}

const AssessmentUnit: React.FC<props> = ({ assessmentUnits, assessmentUnitData, options, setCurrentAssesmentUnit }) => {
    const { auName, processCount, riskCount, controlsCount, triggersCount, triggersChange } = assessmentUnitData;

    const cardStyles = {
        backgroundColor: !options?.includeBackgroundStyles && 'transparent',
        border: !options?.includeBackgroundStyles && 'none',
        cursor: options?.onClickAction && 'Pointer',
    };

    const assessmentUnitBoxData = [
        { label: 'Processes', value: processCount },
        { label: 'Risks', value: riskCount },
        { label: 'Controls', value: controlsCount },
        { label: 'Triggers', triggersChange, value: triggersCount },
    ];

    const handleSelectChange = (assessmentUnitId: any) => {
        const currentAssessmentUnit = assessmentUnits.find(
            (assessmentUnit) => assessmentUnitId === assessmentUnit.auId,
        );
        currentAssessmentUnit && setCurrentAssesmentUnit(currentAssessmentUnit);
    };

    const content = (
        <NexusCardBody data-testid='assessmentUnitCardBody' className='assessment-unit'>
            <div className='nexus-row assessment-unit__name' style={{ alignItems: options?.titleAlignment }}>
                <div className='nexus-col-xs-4 nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-4 assessment-unit-header'>
                    <div className='title'>{auName}</div>
                    <div className='assessment-unit-select'>
                        <AssessmentUnitSelect
                            assessmentUnit={assessmentUnitData}
                            assessmentUnits={assessmentUnits}
                            handleSelectChange={handleSelectChange}
                        />
                    </div>
                </div>
                <div className='nexus-col-xs-4 nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-8'>
                    <div className='nexus-row ract-box-container'>
                        {assessmentUnitBoxData.map((item, idx) => (
                            <AssessmentUnitBox
                                key={idx}
                                label={item.label}
                                value={item.value}
                                change={item.triggersChange}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </NexusCardBody>
    );

    return options?.wrap ? (
        <NexusCard
            data-testid='assessmentUnitCard'
            className='nexus-card'
            onClick={options?.onClickAction}
            style={cardStyles}
        >
            {content}
        </NexusCard>
    ) : (
        content
    );
};

export default AssessmentUnit;
