import { useState, useEffect } from 'react';
import { useParams } from 'react-router';

import './addressTrigger.scss';
import TriggerSummary from 'app/components/triggerSummary/triggerSummary';
import { stepperData, stepperLabels } from './stepperConstants';
import RiskReview from './riskReview/riskReview';
import ControlMapping from './controlMapping/controlMapping';
import FlagRisks from './flagRisks/flagRisks';
import Confirmation from './confirmation/confirmation';
import {
    updateActiveStep,
    updateFlaggedRisks,
    getRiskTaxonomies,
    getRiskImpacts,
    getRiskLikelihoods,
    clearRisksData,
    getProcesses,
    getControlTaxonomies,
    getAssessmentUnitControls,
    updateUserRole,
} from './redux/addressTriggerActions';
import { useAppDispatch, useAppSelector } from 'store';
import BackNavigation from 'app/components/backNavigation/backNavigation';
import { getAssessmentUnitById } from '../redux/assessmentUnits/assessmentUnitActions';
import { getTriggerById } from '../redux/triggers/triggersActions';
import AssessmentUnitsBreadcrumb from '../assessmentUnitsBreadcrumb/assessmentUnitsBreadcrumb';
import LoaderComponent from 'app/components/nds/loader/loader';
import Stepper from 'app/components/stepper/stepper';
import { NexusSelect } from '@nexus/react';

const confirmationOptions = [
    { id: '1', option: 'Analyst' },
    { id: '2', option: 'Reviewer' },
];

const steps = ['Flag risks', 'Risk review', 'Control mapping', 'Confirmation'];

const AddressTrigger: React.FC = () => {
    const params = useParams();
    const link = `/dashboard/${params.id}/triggers`;
    const { activeStep, userRole, triggerStatus } = useAppSelector((state) => state.addressTriggerData);
    const currentTrigger = useAppSelector((state) => state.triggersData.currentTrigger);
    const { currentAssessmentUnit } = useAppSelector((state) => state.assessmentUnitData);

    const [component, setComponent] = useState<JSX.Element>(<></>);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getAssessmentUnitById(params.id as string));
        dispatch(getTriggerById(params.triggerId as string));
        dispatch(getRiskTaxonomies());
        dispatch(getRiskImpacts());
        dispatch(getRiskLikelihoods());
        dispatch(getProcesses());
        dispatch(getControlTaxonomies());
        dispatch(getAssessmentUnitControls(params.id as string));

        // Cleanup, reset active step and clear flagged risks
        return () => {
            dispatch(updateActiveStep('Flag risks'));
            dispatch(updateFlaggedRisks([]));
            dispatch(clearRisksData());
            dispatch(updateUserRole('Analyst'));
        };
    }, []);

    const handleStepClick = (step: string) => {
        dispatch(updateActiveStep(step));
    };

    const stepperItems = stepperData.map((i: any) => {
        return {
            ...i,
            onClickAction: handleStepClick,
        };
    });

    useEffect(() => {
        switch (activeStep) {
            case stepperLabels.FlagRisks:
                setComponent(<FlagRisks handleStepClick={handleStepClick} />);
                break;
            case stepperLabels.RiskReview:
                setComponent(<RiskReview handleStepClick={handleStepClick} />);
                break;
            case stepperLabels.ControlMapping:
                setComponent(<ControlMapping handleStepClick={handleStepClick} />);
                break;
            case stepperLabels.Confirmation:
                setComponent(<Confirmation handleStepClick={handleStepClick} triggerId={params.triggerId as string} />);
                break;
        }
    }, [activeStep]);

    const handleRoleChange = (role: string) => {
        dispatch(updateUserRole(confirmationOptions.find((o) => o.id === role)!.option));
    };

    if (!currentAssessmentUnit) return <LoaderComponent show={!currentAssessmentUnit} />;

    const clearStore = () => dispatch(clearRisksData());

    const getPrevStep = (activeStep: string) => {
        return steps[steps.indexOf(activeStep) - 1];
    };

    return (
        <div className='address-trigger-screen' data-testid='address-trigger'>
            <section className='nexus-container address-trigger-container'>
                {activeStep !== 'Confirmation' && (
                    <div className='breadcrumb-container'>
                        <AssessmentUnitsBreadcrumb auName={currentAssessmentUnit.auName} />
                    </div>
                )}
                <div
                    className={`nexus-row back-navigation-section ${
                        activeStep === 'Confirmation' ? 'confirmation-step' : ''
                    }`}
                >
                    {activeStep === 'Flag risks' ? (
                        <div onClick={clearStore}>
                            <BackNavigation goToLink={link} />
                        </div>
                    ) : (
                        <div>
                            <BackNavigation goToStep={getPrevStep(activeStep)} handlePreviousStep={handleStepClick} />
                        </div>
                    )}

                    {activeStep === 'Confirmation' && (
                        <NexusSelect
                            className='confirmation-options'
                            value={confirmationOptions[0].id}
                            onInput={(e: any) => handleRoleChange(e.target.value)}
                        >
                            {confirmationOptions.map((confirmationOption: any) => (
                                <option key={confirmationOption.id} value={confirmationOption.id}>
                                    {confirmationOption.option}
                                </option>
                            ))}
                        </NexusSelect>
                    )}
                </div>
                <Stepper steps={stepperItems} activeStep={activeStep} />
                {currentTrigger && (
                    <TriggerSummary
                        activeStep={activeStep}
                        trigger={currentTrigger}
                        triggerStatus={triggerStatus[userRole]}
                    />
                )}
            </section>
            {component}
        </div>
    );
};

export default AddressTrigger;
