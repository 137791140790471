const NAME = 'assessmentUnitData';

const actionConst = {
    assessmentUnitById: `${NAME}/assessmentUnitById`,
    assessmentUnitData: `${NAME}/assessmentUnitData`,
    resetTestData: `${NAME}/resetTestData`,
    updateAssessmentUnit: `${NAME}/updateAssessmentUnit`,
};

export default actionConst;
