import { NexusModal } from '@nexus/react';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'store';
import ControlAssessment from './controlAssessment';
import { addNewControl, clearControlAssessmentData, editControl } from '../../redux/addressTriggerActions';
import './controlForm.scss';
import {
    controlPrePostTriggers,
    controlTypes,
    emptyControlFormData,
    formName,
    shortInputSize,
    validateForControlAssessment,
} from './controlFormConsts';
import { getControlAssessment } from '../../redux/addressTriggerActions';
import FormTextarea from 'app/components/formTextarea/formTextarea';
import FormInput from 'app/components/formInput/formInput';
import ControlFormSelect from './controlFormSelect';

interface props {
    handleCloseControlForm: () => void;
    isNewControl: boolean;
    selectedControl?: any;
    showForm: boolean;
}

const ControlForm: React.FC<props> = ({
    handleCloseControlForm,
    isNewControl,
    selectedControl = emptyControlFormData,
    showForm,
}) => {
    const [t] = useTranslation('lang');
    const buttons = JSON.parse(JSON.stringify(t('buttons')));
    const controlLabels = JSON.parse(JSON.stringify(t('control.labels')));
    const [formData, setFormData] = useState({ ...selectedControl });
    const [openControlAssessment, setOpenControlAssessment] = useState(false);
    const [modified, setModified] = useState(!isNewControl);
    const [controlAssessmentIsValid, setControlAssessmentIsValid] = useState(false);
    const dispatch = useAppDispatch();

    // Fetching control taxanomies and control assessment.
    const { controlAssessmentResponse, controlTaxonomies, loading } = useAppSelector(
        (state) => state.addressTriggerData,
    );

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const { ...control } = { ...formData };
        if (!isNewControl) {
            dispatch(editControl({ ...control }));
        } else {
            dispatch(addNewControl(control));
        }
        handleCloseControlForm();
    };

    const handleInputChange = (target: any) => {
        setFormData({
            ...formData,
            [target.name]: target.value,
        });
        setModified(true);
    };

    const handleControlAssessmentCalculation = () => {
        dispatch(getControlAssessment(formData));
        setOpenControlAssessment(true);
        setModified(false);
    };

    useEffect(() => {
        // Cleanup, reset control assessment result data
        return () => {
            dispatch(clearControlAssessmentData());
        };
    }, []);

    useEffect(() => {
        setFormData({
            ...formData,
            controlWhat: controlAssessmentResponse.controlWhat,
            controlWhen: controlAssessmentResponse.controlWhen,
            controlWhere: controlAssessmentResponse.controlWhere,
            controlWho: controlAssessmentResponse.controlWho,
            controlWhy: controlAssessmentResponse.controlWhy,
        });
        setControlAssessmentIsValid(
            controlAssessmentResponse.controlWhat &&
                controlAssessmentResponse.controlWhen &&
                controlAssessmentResponse.controlWho &&
                controlAssessmentResponse.controlWhere &&
                controlAssessmentResponse.controlWhy,
        );
    }, [controlAssessmentResponse]);

    return (
        <NexusModal show={showForm} size={'lg'} data-testid='edit-control-modal'>
            <div className='control-form-container' data-testid='control-form-container'>
                <form className='control-form' autoComplete='false' onSubmit={handleSubmit}>
                    <div className='nexus-row nexus-mb-2'>
                        <div className='nexus-col-lg-12 nexus-col-md-8 nexus-col-xs-4 form-title'>
                            {isNewControl ? controlLabels.addNewControl : controlLabels.editControl}
                        </div>
                    </div>
                    <div className='nexus-row'>
                        <div className='nexus-col-lg-4 nexus-col-md-8 nexus-col-xs-4'>
                            <ControlFormSelect
                                fieldName={'controlType'}
                                fieldValue={formData.controlType}
                                handleInputChange={handleInputChange}
                                isNewControl={isNewControl}
                                label={controlLabels.controlType}
                                options={controlTypes}
                                valueId={'name'}
                            />
                        </div>
                        <FormInput
                            colLgSize={shortInputSize}
                            disabled={true}
                            formName={formName}
                            testid={'code'}
                            inputName={'controlCode'}
                            inputValue={formData.controlCode}
                            label={controlLabels.controlId}
                        />
                        <FormInput
                            colLgSize={shortInputSize}
                            formName={formName}
                            handleInputChange={handleInputChange}
                            testid={'owner'}
                            inputName={'controlOwner'}
                            inputValue={formData.controlOwner}
                            label={controlLabels.controlOwner}
                        />
                    </div>
                    <div className='nexus-row'>
                        <FormInput
                            colLgSize={shortInputSize}
                            formName={formName}
                            handleInputChange={handleInputChange}
                            testid={'owner-asseement-unit'}
                            inputName={'controlOwnerAu'}
                            inputValue={formData.controlOwnerAu}
                            label={controlLabels.controlOwnerAu}
                        />
                        <FormInput
                            colLgSize={shortInputSize}
                            disabled={true}
                            formName={formName}
                            handleInputChange={handleInputChange}
                            testid={'id-associated'}
                            inputName={'riskCode'}
                            inputValue={formData.riskCode}
                            label={controlLabels.riskIdAssociated}
                        />
                        <div className='nexus-col-lg-4 nexus-col-md-8 nexus-col-xs-4'>
                            <ControlFormSelect
                                fieldName={'controlPrePostTrigger'}
                                fieldValue={formData.controlPrePostTrigger}
                                handleInputChange={handleInputChange}
                                isNewControl={isNewControl}
                                label={controlLabels.controlPrePostTrigger}
                                options={controlPrePostTriggers}
                                valueId={'name'}
                            />
                        </div>
                    </div>
                    <div className='nexus-row'>
                        <div className='nexus-col-lg-4 nexus-col-md-8 nexus-col-xs-4'>
                            <ControlFormSelect
                                fieldName={'controlTaxonomyLevel1'}
                                fieldValue={formData.controlTaxonomyLevel1}
                                handleInputChange={handleInputChange}
                                isNewControl={isNewControl}
                                isTaxonomy={true}
                                label={controlLabels.controlTaxonomyLevel1}
                                level={1}
                                options={controlTaxonomies.filter((item: any) => item.level === 1)}
                                valueId={'id'}
                            />
                            <ControlFormSelect
                                fieldName={'controlTaxonomyLevel2'}
                                fieldValue={formData.controlTaxonomyLevel2}
                                handleInputChange={handleInputChange}
                                isNewControl={isNewControl}
                                isTaxonomy={true}
                                label={controlLabels.controlTaxonomyLevel2}
                                level={2}
                                options={controlTaxonomies.filter((item: any) => item.level === 2)}
                                valueId={'id'}
                            />
                            <ControlFormSelect
                                fieldName={'controlTaxonomyLevel3'}
                                fieldValue={formData.controlTaxonomyLevel3}
                                handleInputChange={handleInputChange}
                                isNewControl={isNewControl}
                                isTaxonomy={true}
                                label={controlLabels.controlTaxonomyLevel3}
                                level={3}
                                options={controlTaxonomies.filter((item: any) => item.level === 3)}
                                valueId={'id'}
                            />
                        </div>
                        <div className='nexus-col-lg-8 nexus-col-md-8 nexus-col-xs-4'>
                            <FormTextarea
                                cols={82}
                                fieldName={'controlDescription'}
                                fieldValue={formData.controlDescription}
                                formName={'control'}
                                handleInputChange={handleInputChange}
                                label={controlLabels.controlDescription}
                                rows={7}
                            />
                        </div>
                    </div>
                    <div className='nexus-row'>
                        <div className='nexus-col-lg-12'>
                            <div className='actions'>
                                <button
                                    data-testid='control-assessment-button'
                                    disabled={validateForControlAssessment(formData) || !modified}
                                    type='submit'
                                    className='nexus-btn-primary nexus-btn-large'
                                    onClick={handleControlAssessmentCalculation}
                                >
                                    {buttons.controlAssessment}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='horizontal-divider'></div>
                    {openControlAssessment && controlAssessmentResponse && !loading && (
                        <>
                            <ControlAssessment
                                controlAssessment={controlAssessmentResponse}
                                controlAssessmentIsValid={controlAssessmentIsValid}
                                overrideReason={formData.overrideReason}
                                handleInputChange={handleInputChange}
                            />
                            <div className='horizontal-divider'></div>
                        </>
                    )}
                    <div className='nexus-row'>
                        <div className='nexus-col-lg-12 control-form-actions'>
                            <div className='actions'>
                                <button
                                    className='nexus-btn'
                                    data-testid='cancel-button'
                                    onClick={handleCloseControlForm}
                                    type='button'
                                >
                                    {buttons.cancel}
                                </button>
                                <button
                                    className='nexus-btn-primary nexus-btn-large'
                                    data-testid='save-button'
                                    disabled={!controlAssessmentIsValid && formData.length === 0}
                                    type='submit'
                                >
                                    {isNewControl ? buttons.addNewControl : buttons.confirmEdit}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </NexusModal>
    );
};

export default ControlForm;
