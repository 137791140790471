import './modal.scss';

import { NexusModal, NexusModalBody, NexusModalFooter, NexusModalHeader, NexusModalHeaderSubtitle } from '@nexus/react';
import { PleaseSelect, RiskControlHeaderPage } from 'constants/constants';
import { useEffect, useState } from 'react';

import React from 'react';

interface InputProps {
    fullscreen?: boolean;
    show: boolean;
    size?: 'full' | 'lg' | 'md' | 'xl' | 'xs';
    header: string;
    children?: React.ReactNode;
    closeModal: () => void;
    type?: string;
    handleSubmitButton?: () => void;
    disableSubmitButton?: string;
}

const ModalComponent: React.FC<InputProps> = ({
    children,
    fullscreen,
    show,
    size,
    header,
    closeModal,
    type,
    handleSubmitButton,
    disableSubmitButton,
}) => {
    const [isshow, setShow] = useState<boolean>(show);

    useEffect(() => {
        setShow(show);
    }, [show]);

    const onCloseModal = () => {
        setShow(false);
        closeModal();
    };

    return (
        <>
            <div className='modal-styles'>
                <NexusModal
                    data-testid='overlay'
                    id='modal'
                    className='nexus-modal-styles'
                    show={isshow}
                    fullscreen={fullscreen}
                    size={size}
                >
                    <NexusModalHeader data-testid='modal-header' onCloseEvent={onCloseModal}>
                        {header}
                        <NexusModalHeaderSubtitle data-testid='modal-Placeholder'></NexusModalHeaderSubtitle>
                    </NexusModalHeader>
                    <NexusModalBody>
                        {children}
                        {type === RiskControlHeaderPage && (
                            <div className='nexus-flex-row-reverse nexus-my-2'>
                                <div className='nexus-mx-3'>
                                    <button
                                        onClick={handleSubmitButton}
                                        data-testid='btn-confirm'
                                        className='nexus-btn nexus-btn-primary'
                                        disabled={disableSubmitButton === PleaseSelect}
                                    >
                                        Submit
                                    </button>
                                </div>
                                <div className='nexus-mx-2'>
                                    <button onClick={onCloseModal} data-testid='btn-confirm' className='nexus-btn'>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        )}
                    </NexusModalBody>

                    {type !== RiskControlHeaderPage && (
                        <NexusModalFooter>
                            <button onClick={onCloseModal} data-testid='btn-confirm' className='nexus-btn-primary'>
                                Apply
                            </button>
                        </NexusModalFooter>
                    )}
                </NexusModal>
            </div>
        </>
    );
};

export default ModalComponent;
