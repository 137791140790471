import { NexusFormField, NexusLabel, NexusSelect } from '@nexus/react';
import { useTranslation } from 'react-i18next';
import { formName } from './riskFormConsts';

interface props {
    fieldName: string;
    fieldValue: any;
    handleInputChange: (args: any) => void;
    isNewRisk: boolean;
    label: string;
    options: any[];
    valueId: string;
}

const RiskFormSelect: React.FC<props> = ({
    fieldName,
    fieldValue,
    handleInputChange,
    isNewRisk,
    label,
    options,
    valueId,
}) => {
    const [t] = useTranslation('lang');
    const defaultValue = JSON.parse(JSON.stringify(t('warnings.pleaseSelect')));
    const testid = fieldName.slice(formName.length).toLowerCase();

    return (
        <NexusFormField>
            <NexusLabel data-testid={`risk-${testid}-label`}>{label}</NexusLabel>
            <NexusSelect
                data-testid={`risk-${testid}-select`}
                value={isNewRisk ? defaultValue : fieldValue[valueId]}
                defaultValue={isNewRisk ? defaultValue : fieldValue[valueId]}
                required
                onInput={(event: any) =>
                    handleInputChange({
                        name: fieldName,
                        value: valueId === 'id' ? { id: event.target.value } : { processId: event.target.value },
                    })
                }
            >
                <option data-testid={`risk-${testid}-opt`} defaultValue={defaultValue} disabled>
                    {defaultValue}
                </option>
                {options.map((option: any) => (
                    <option data-testid={`risk-${testid}-opt`} value={option[valueId]} key={option[valueId]}>
                        {option.name || option.processName}
                    </option>
                ))}
            </NexusSelect>
        </NexusFormField>
    );
};

export default RiskFormSelect;
