import Default24px from '@nexus/core/dist/assets/icons/content/ic_sort_24px.svg';
import ContentSortDes24px from '@nexus/core/dist/assets/icons/navigation/ic_arrow_downward_24px.svg';
import ContentSortAsc24px from '@nexus/core/dist/assets/icons/navigation/ic_arrow_upward_24px.svg';
import { NexusCheckbox, NexusIcon } from '@nexus/react';

interface props {
    cols: {
        label: string;
        isSortable: boolean;
        field: string;
    }[];
    sortColumn: string;
    sortDirection: string;
    setSortColumn: (args: any) => void;
    setSortDirection: (args: any) => void;
    handleHeaderCheck: () => void;
    selectedRowsLength: number;
    rowsLength: number;
}

const TableHeaders: React.FC<props> = ({
    cols,
    sortColumn,
    setSortColumn,
    sortDirection,
    setSortDirection,
    selectedRowsLength,
    rowsLength,
    handleHeaderCheck,
}) => {
    const updateSort = (col: string, dir: string) => {
        setSortColumn(col);
        setSortDirection(dir);
    };

    const handleSort = (field: string) => {
        if (!sortColumn) return updateSort(field, 'des');
        if (field === sortColumn) {
            return sortDirection === 'des' ? setSortDirection('asc') : updateSort('', '');
        } else {
            setSortColumn(field);
        }
    };

    const getSortIcon = (field: string) => {
        if (sortColumn === field) {
            return sortDirection === 'des' ? ContentSortDes24px : ContentSortAsc24px;
        }
        return Default24px;
    };

    const isChecked = selectedRowsLength > 0 || selectedRowsLength === rowsLength;
    const indeterminate = selectedRowsLength > 0 && selectedRowsLength !== rowsLength;

    return (
        <thead>
            <tr>
                <th>
                    <NexusCheckbox checked={isChecked} onInput={handleHeaderCheck} indeterminate={indeterminate} />
                </th>
                {cols.map((col, idx) => (
                    <th key={`custom-table-col-header-${idx}`}>
                        {col.label}
                        <NexusIcon
                            src={getSortIcon(col.field)}
                            onClick={() => handleSort(col.field)}
                            data-testid={`table-icon-icon-${idx}`}
                        />
                    </th>
                ))}
            </tr>
        </thead>
    );
};

export default TableHeaders;
