import { NexusModal, NexusModalBody } from '@nexus/react';
import { Dispatch, SetStateAction } from 'react';
import './confirmationModal.scss';

interface props {
    actionMessage: string;
    cancelActionButton: string;
    confirmActionButton: string;
    handleConfirmation: (confirm: boolean) => void;
    setShowConfirmationModal: Dispatch<SetStateAction<boolean>>;
    showConfirmationModal: boolean;
    title: string;
}

const ConfirmationModal: React.FC<props> = ({
    actionMessage,
    cancelActionButton,
    confirmActionButton,
    handleConfirmation,
    showConfirmationModal,
    title,
}) => {
    return (
        <NexusModal
            className='confirmation-modal-container'
            data-testid='confirmation-modal'
            id='modal'
            show={showConfirmationModal}
            fullscreen={false}
            size='sm'
        >
            <div className='confirmation-modal-header' data-testid='confirmation-modal-header'>
                {title}
            </div>
            <NexusModalBody className='confirmation-modal-body'>{actionMessage}</NexusModalBody>
            <div className='confirmation-modal-actions'>
                <button
                    className='nexus-btn nexus-btn-primary'
                    data-testid='btn-confirm'
                    onClick={() => handleConfirmation(true)}
                >
                    {confirmActionButton}
                </button>
                <button
                    className='nexus-btn nexus-btn-large'
                    data-testid='btn-cancel'
                    onClick={() => handleConfirmation(false)}
                >
                    {cancelActionButton}
                </button>
            </div>
        </NexusModal>
    );
};

export default ConfirmationModal;
