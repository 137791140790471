import { createSlice } from '@reduxjs/toolkit';

import { IRiskControl } from 'constants/commonExportedInterfaces';
import * as ACTIONS from './controlsActions';

interface IControlsSliceReducer {
    controlsData: Array<IRiskControl>;
    loading: boolean;
}

export const INITIAL_STATE_CONTROLS: IControlsSliceReducer = {
    controlsData: [],
    loading: false,
};

type ControlsState = Readonly<typeof INITIAL_STATE_CONTROLS>;

export const controlsSliceReducer = createSlice({
    extraReducers: (builder) =>
        builder
            .addCase(ACTIONS.getControlsDataByAU.fulfilled, (state, action) => {
                state.controlsData = action.payload?.data;
                state.loading = false;
            })
            .addCase(ACTIONS.getControlsDataByAU.pending, (state) => {
                state.loading = true;
            }),
    initialState: INITIAL_STATE_CONTROLS as ControlsState,
    name: 'controlsData',
    reducers: {},
});

export default controlsSliceReducer.reducer;
