import React from 'react';
import { NexusTable } from '@nexus/react';
import ContentSortAsc24px from '@nexus/core/dist/assets/icons/navigation/ic_arrow_upward_24px.svg';
import Default24px from '@nexus/core/dist/assets/icons/content/ic_sort_24px.svg';
import ContentSortDes24px from '@nexus/core/dist/assets/icons/navigation/ic_arrow_downward_24px.svg';

import { IBasicTableProps } from 'constants/commonExportedInterfaces';
import useTableFeatures from 'app/hooks/useTableFeatures';
import CustomChip from '../customChip/customChip';

const BasicTable: React.FC<IBasicTableProps> = (props) => {
    const {
        chipFields,
        chipPalette,
        columnDefs,
        fields,
        maxHeight,
        pageSizeLabel,
        pagination,
        rowData,
        rowSelection,
        sortAscIcon,
        sortDefaultIcon,
        sortDescIcon,
    } = {
        ...props,
    };

    const { rowListData, setCustomChangeEvent } = useTableFeatures(rowData);

    const totalItems = rowData.length;

    return (
        <NexusTable
            columns={columnDefs}
            data-testid='nexus-table-basic'
            maxHeight={maxHeight}
            onTableChangeEvent={(eventData: any) => setCustomChangeEvent(eventData)}
            pageSizeLabel={pageSizeLabel}
            pageSizeOpt={[5, 10, 20]}
            pagination={pagination}
            rowSelection={rowSelection}
            sortAscIcon={sortAscIcon}
            sortDefaultIcon={sortDefaultIcon}
            sortDescIcon={sortDescIcon}
            totalItems={totalItems}
            type='custom'
        >
            <div slot='tbody' className='custom-table-rows'>
                {rowListData.length > 0 &&
                    rowListData.map((row: any, rowidx: any) => (
                        <>
                            <tr data-testid={`basic-row-${rowidx}`} className='tr-basic' key={`row-${rowidx}`}>
                                {fields.map((field: string, colidx: number) => (
                                    <td key={`cell-${rowidx}-${colidx}`}>
                                        {chipFields?.includes(field)
                                            ? chipPalette && (
                                                  <CustomChip
                                                      chipPalette={chipPalette}
                                                      defaultColor={'#19191A'}
                                                      field={row[field]}
                                                  />
                                              )
                                            : row[field]}
                                    </td>
                                ))}
                            </tr>
                        </>
                    ))}
            </div>
        </NexusTable>
    );
};

BasicTable.defaultProps = {
    sortAscIcon: ContentSortAsc24px,
    sortDefaultIcon: Default24px,
    sortDescIcon: ContentSortDes24px,
};

export default BasicTable;
